import { Path } from "../../../web/core/routing/path";
import { Amount } from "../../core/amount/amount";
import {
  AccountBlockedAfterThreePincodeAttemptsError,
  DefaultAccountBlockedError,
  ErrorType,
  isAccountBlocked,
} from "../pincode/pincode-error";

export enum AccountStatus {
  Opened = "OPENED",
  Closed = "CLOSED",
  Blocked = "BLOCKED",
  DebitBlocked = "DEBIT_BLOCKED",
  CreditBlocked = "CREDIT_BLOCKED",
}

export enum AccountType {
  Current = "CURRENT",
  Savings = "SAVINGS",
  Dedicated = "DEDICATED",
  MoneyPot = "MONEYPOT",
  Technical = "TECHNICAL",
}

export type AccountIBAN = string | undefined;

export interface Account {
  id: string;
  balance: Amount;
  availableBalance: Amount;
  type: AccountType;
  status: AccountStatus;
  label: string;
  createdAt: string;
  externalId?: string;
  iban?: string;
  bic?: string;
  externalData?: { [key: string]: string };
  externalAccount?: string;
  lastUsedAt?: string;
  deletedAt?: string;
  merchant?: string;
}

export function isSupportedAccountType(account: Account): boolean {
  return (
    account.type === AccountType.Current ||
    account.type === AccountType.Dedicated ||
    account.type === AccountType.MoneyPot ||
    account.type === AccountType.Savings ||
    account.type === AccountType.Technical
  );
}

export function redirectionHandledOnAccountBlocked(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  redirect: (path: string) => void,
): error is AccountBlockedAfterThreePincodeAttemptsError | DefaultAccountBlockedError {
  if (isAccountBlocked(error)) {
    redirect(
      error.type === ErrorType.DefaultAccountBlocked ? Path.AccountBlocked : Path.AccountBlockedAfterPincodeError,
    );
    return true;
  }
  return false;
}
