import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { useIntl } from "../../../shared/core/i18n/use-intl";
import { Account, AccountStatus } from "../../../shared/domains/account/account";
import { statusColors, statusLabels } from "../../../shared/domains/account/account-status-labels-and-colors";
import { colorWithOpacity } from "../../../shared/utils/colors";
import { theme } from "../styles/theme";

export interface AccountStatusViewProps extends HTMLAttributes<HTMLDivElement> {
  account: Account;
}

export const AccountStatusView: React.FC<AccountStatusViewProps> = ({ account, ...rest }) => {
  const { formatMessage } = useIntl();
  if (account.status === AccountStatus.Opened) {
    return null;
  }
  const statusColor = statusColors[account.status];
  return (
    <StatusContainer color={statusColor} {...rest}>
      <StatusDot color={statusColor} />
      <StatusLabel color={statusColor}>{formatMessage(`${statusLabels[account.status]}`)}</StatusLabel>
    </StatusContainer>
  );
};

const StatusContainer = styled.div<{ color: string }>`
  background-color: ${(props) => colorWithOpacity(props.color, 0.2)};
  border-radius: 13px;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusDot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${(props) => props.color};
  margin-right: 4px;
`;

const StatusLabel = styled.span<{ color: string }>`
  ${theme.boldText}
  font-size: 0.625rem;
  color: ${(props) => props.color};
`;
