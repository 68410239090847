import React, { useCallback } from "react";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../shared/utils/phone-number";
import { RecipientState, useRecipientMachine } from "../../../machine/recipient-machine";

import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { ADD_RECIPIENT_MODAL_ID } from "../../../core/modal/modal-id";
import { useClient } from "../../../domain/authentication/use-client";
import { PincodeState } from "../../../machine/keyboard-machine-type";
import { PincodeKeyboard } from "../../common/keyboard/pincode-keyboard";
import { Modal } from "../../common/modal/modal";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { MainColorSpinner } from "../../common/spinner";
import { TransferTransaction } from "../transfer/components/transfer-modal";
import { AddBeneficiaryDetail } from "./add-recipient-detail";
import { SearchBank } from "./components/search-bank";
import { OtpConfirm } from "./otp-confirm";
import { featuresManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";

const notCancellableState = [
  RecipientState.RequestingRecipient,
  RecipientState.OTPConfirming,
  RecipientState.OTPRefreshing,
];

export interface AddBeneficiaryModalProps {
  initialName?: string;
  forExternalAccount?: boolean;
}

export const AddBeneficiaryModal = (props: AddBeneficiaryModalProps) => {
  const { initialName, forExternalAccount } = props;
  const features = useObservable(featuresManager.features);

  const {
    state,
    context,
    createPhoneRecipient,
    confirmOtp,
    cancelStrongAuthentication,
    submitPincode,
    createIbanRecipient,
    createAccountRecipient,
    searchBank,
    updateBankName,
    sendOtpAgain,
  } = useRecipientMachine();

  const { formatMessage } = useIntl();
  const { client } = useClient();
  const handleCloseModal = useCallback(() => Modal.dismiss(ADD_RECIPIENT_MODAL_ID), []);

  const handleCloseAndCancelStrongAuth = useCallback(() => {
    const recipient = context.recipientToAdd;
    if (recipient?.strongAuthentication?.strongAuthenticationToken) {
      cancelStrongAuthentication();
    }
    handleCloseModal();
  }, [context.recipientToAdd]);

  const renderInnerStep = () => {
    const errorMessage = context.errorMessage;
    switch (state) {
      case RecipientState.RequestingRecipient:
      case RecipientState.CreatingRecipient:
        return (
          <AddBeneficiaryDetail
            createPhoneRecipient={createPhoneRecipient}
            createIbanRecipient={createIbanRecipient}
            createAccountRecipient={createAccountRecipient}
            searchBank={searchBank}
            loading={state === RecipientState.RequestingRecipient}
            errorMessage={errorMessage}
            initialName={initialName}
            forExternalAccount={forExternalAccount}
            bankName={context.bankName}
            bicCode={context.bicCode}
          />
        );
      case RecipientState.SearchBank:
        return (
          <SearchBank
            title={formatMessage("addRecipient.searchBank.title")}
            onSelect={(bankName, bic) => updateBankName(bankName, bic)}
          />
        );
      case RecipientState.OTPRefreshing:
      case RecipientState.OTPSubmission:
      case RecipientState.OTPConfirming:
        const additionalText = context.recipientToAdd?.bankName
          ? formatMessage("addRecipient.otpAdditionalTextWithBank", {
              bankName: context.recipientToAdd?.bankName,
              name: context.recipientToAdd.name,
            })
          : formatMessage("addRecipient.otpAdditionalText", { name: context.recipientToAdd?.name });
        return (
          <OtpConfirm
            submitOtp={confirmOtp}
            sendOtpAgain={sendOtpAgain}
            cancelOtp={handleCloseAndCancelStrongAuth}
            onOtpBlocked={handleCloseAndCancelStrongAuth}
            additionalText={additionalText}
            errorMessage={errorMessage}
            refreshingErrorMessage={context.refreshingErrorMessage}
            phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
            loading={state === RecipientState.OTPConfirming}
            refreshingOtp={state === RecipientState.OTPRefreshing}
          />
        );
      case PincodeState.PincodeConfirmation:
      case PincodeState.FetchKeyboardAfterError:
      case PincodeState.PromptingKeyboard:
        const keyboard = context.keyboard!;
        return (
          <PincodeKeyboard
            keyboard={keyboard}
            onSubmit={submitPincode}
            title={formatMessage("pincodeConfirmation.label")}
            errorMessage={errorMessage}
            loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
          />
        );
      case RecipientState.Done:
        const recipient = context.recipientToAdd!;
        return !features.customerInstructionInitiation ? (
          <TransferTransaction showAvatar showTitle closeModal={handleCloseModal} recipient={recipient} />
        ) : (
          Modal.dismiss(ADD_RECIPIENT_MODAL_ID)
        );
      default: {
        return (
          <LoadingContainer>
            <MainColorSpinner size="30px" />
          </LoadingContainer>
        );
      }
    }
  };
  return (
    <RoundedModalContainer
      closeModal={handleCloseAndCancelStrongAuth}
      closeButton={!notCancellableState.includes(state)}
      id={ADD_RECIPIENT_MODAL_ID}>
      {renderInnerStep()}
    </RoundedModalContainer>
  );
};

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
`;
