import React, { useCallback, useEffect, useRef, useState } from "react";
import { OtpInput, OtpInputRef } from "../../common/forms/otp-input";

import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../shared/core/service/services";
import { isDefined } from "../../../../shared/utils/assert";
import { useObservable } from "../../../../shared/utils/observable";
import { PrimaryButton, WhitePrimaryButton } from "../../common/buttons/primary-button";
import { ErrorMessage } from "../../common/error-message";
import { MainColorSpinner } from "../../common/spinner";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";

interface OtpConfirmProps {
  submitOtp: (otp: string) => void;
  sendOtpAgain?: () => void;
  cancelOtp?: () => void;
  onOtpBlocked?: () => void;
  additionalText?: string;
  loading?: boolean;
  phoneNumber?: string;
  refreshingOtp?: boolean;
  refreshingErrorMessage?: string;
  errorMessage?: string;
  explanationColor?: string;
}

export const OtpConfirm = (props: OtpConfirmProps) => {
  const {
    submitOtp,
    sendOtpAgain,
    cancelOtp,
    onOtpBlocked,
    additionalText,
    loading,
    phoneNumber,
    refreshingOtp,
    errorMessage,
    refreshingErrorMessage,
    explanationColor,
  } = props;
  const [otp, setOTP] = useState("");
  const { formatMessage } = useIntl();
  const configuration = useObservable(configurationManager.configuration);
  const otpInputRef = useRef<OtpInputRef>(null);
  const hasOtpBeenReset = useRef(false);
  const resetOtp = useCallback(() => {
    otpInputRef.current?.reset();
    hasOtpBeenReset.current = true;
  }, []);
  const OTP_MAX_TRIES = 5;
  const [otpTriesLeft, setOtpTriesLeft] = useState<number>(OTP_MAX_TRIES);

  useEffect(() => {
    if (isDefined(errorMessage) && !hasOtpBeenReset.current) {
      resetOtp();
    } else if (otp.length >= configuration.otp.maxLength) {
      hasOtpBeenReset.current = false;
      if (onOtpBlocked) {
        setOtpTriesLeft(otpTriesLeft - 1);
      }
      submitOtp(otp);
    }
  }, [otp]);

  return (
    <Container>
      {isDefined(additionalText) && <AdditionalText>{additionalText}</AdditionalText>}
      <Explanation textColor={explanationColor}>{formatMessage("otp.explanation")}</Explanation>
      <PhoneNumber dir={"ltr"}>{phoneNumber ?? "** ** ** ** **"}</PhoneNumber>
      {refreshingErrorMessage && <StyledErrorMessage>{formatMessage(refreshingErrorMessage)}</StyledErrorMessage>}
      {otpTriesLeft > 0 ? (
        <>
          {isDefined(errorMessage) && (
            <StyledErrorMessage>
              {errorMessage}
              &nbsp;
              {onOtpBlocked ? `\n${otpTriesLeft} tentative(s) restante(s)` : null}
            </StyledErrorMessage>
          )}
          <StyledOtpInput
            ref={otpInputRef}
            length={configuration.otp.maxLength}
            onOtpChange={(otpCode) => setOTP(otpCode)}
          />
        </>
      ) : (
        <StyledErrorMessage>{formatMessage("otp.otpBlockedErrorMessage")}</StyledErrorMessage>
      )}
      <Footer>
        {loading ? (
          <MainColorSpinner />
        ) : (
          <>
            {sendOtpAgain ? (
              <SendOtpAgainButton
                size="S"
                onClick={() => {
                  if (otpTriesLeft <= 0 && onOtpBlocked) {
                    onOtpBlocked();
                  } else {
                    resetOtp();
                    setOtpTriesLeft(OTP_MAX_TRIES);
                    sendOtpAgain();
                  }
                }}
                disabled={refreshingOtp}>
                {formatMessage(otpTriesLeft <= 0 && onOtpBlocked ? "otp.backToRecipients" : "otp.sendBackCodeButton")}
              </SendOtpAgainButton>
            ) : null}
            {cancelOtp ? (
              <CancelOtpButton
                size="S"
                onClick={() => {
                  cancelOtp();
                }}
                disabled={refreshingOtp}>
                {formatMessage("otp.cancelOtpButton")}
              </CancelOtpButton>
            ) : null}
          </>
        )}
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
`;

const SendOtpAgainButton = styled(PrimaryButton)``;
const CancelOtpButton = styled(WhitePrimaryButton)``;
const Explanation = styled.div<{ textColor?: string }>`
  color: ${(props) => props.textColor ?? "black"};
  margin-left: 50px;
  margin-right: 50px;
  text-align: center;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const AdditionalText = styled(Explanation)`
  margin-bottom: 20px;
`;

const PhoneNumber = styled(Explanation)`
  ${theme.boldText}
  color: black;
`;

const StyledOtpInput = styled(OtpInput)`
  justify-content: center;
  margin-top: 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
  min-height: 46px;
  width: 100%;
  align-items: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0px;
  text-align: center;
`;
