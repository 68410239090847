import React, { useCallback } from "react";

import { Acceptor } from "../../../../../shared/domains/acceptors/acceptor";
import { AcceptorRow } from "./acceptor-row";
import { ErrorMessage } from "../../../common/error-message";
import { InfiniteScroll } from "../../../common/infinite-scroll";
import { MainColorSpinner } from "../../../common/spinner";
import { acceptorCategoriesManager } from "../../../../../shared/core/service/services";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { useAcceptors } from "../../../../../shared/domains/acceptors/use-acceptors";
import { useAsyncEffect } from "../../../../../shared/utils/utils";
import { useLoadMoreDebounce } from "../../../../../shared/utils/list";
import { useObservable } from "../../../../../shared/utils/observable";

interface AcceptorSelectionProps {
  onSubmit: (acceptor: Acceptor) => void;
  title?: string;
  errorMessage?: string;
  className?: string;
}

const GRID_COLUMNS = 4;
const GRID_ROWS = 4;
const PADDING = 10;
const SPACING = 10;
const TOUCH_SIZE = 45;

export const AcceptorSelection = (props: AcceptorSelectionProps) => {
  const { onSubmit, className, title, errorMessage } = props;

  const categories = useObservable(acceptorCategoriesManager.acceptorCategories);
  const acceptors = useAcceptors();

  const loadMoreDebounce = useLoadMoreDebounce();
  const loadMore = useCallback(
    () => loadMoreDebounce(acceptors.loadMore, acceptors.loadingMore, acceptors.canLoadMore),
    [acceptors.canLoadMore, acceptors.loadMore, acceptors.loadingMore, loadMoreDebounce],
  );

  useAsyncEffect(async () => {
    if (categories.length === 0) {
      await acceptorCategoriesManager.refresh();
    }
  }, [categories]);

  useAsyncEffect(async () => {
    await acceptors.load(0, 0, true);
  }, []);

  return (
    <Container className={className}>
      <Header>
        <Title>{title}</Title>
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </Header>
      {acceptors.loading ? (
        <SpinnerContainer>
          <MainColorSpinner />
        </SpinnerContainer>
      ) : (
        <InfiniteScroll fetchMore={loadMore} hasMore={acceptors.canLoadMore} loading={acceptors.loadingMore}>
          <StyledInsideInfiniteScroll>
            {acceptors.data.map((acceptor, index) => (
              <AcceptorRow key={index} acceptor={acceptor} onClick={() => onSubmit(acceptor)} />
            ))}
          </StyledInsideInfiniteScroll>
        </InfiniteScroll>
      )}
    </Container>
  );
};

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${GRID_ROWS * (TOUCH_SIZE + SPACING) - SPACING}px;
  width: ${GRID_COLUMNS * (TOUCH_SIZE + SPACING) - SPACING + 2 * PADDING}px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  width: 300px;
  text-align: center;
  position: relative;
`;
const Title = styled.span`
  font-size: 1.125rem;
  ${theme.boldText};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: ${PADDING}px;
  padding-right: ${PADDING}px;
  padding-bottom: ${PADDING}px;
`;

const StyledInsideInfiniteScroll = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: scroll;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0px 10px 0px;
  text-align: center;
`;
