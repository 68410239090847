import { throttle } from "lodash";
import React, { Fragment, useCallback, useEffect, useMemo, useRef } from "react";

interface InfiniteScrollProps {
  fetchMore: () => void;
  loading: boolean;
  hasMore: boolean;
  scrollingElement?: HTMLDivElement | null;
  children?: React.ReactNode | JSX.Element;
}

export function InfiniteScroll({ fetchMore, loading, hasMore, scrollingElement, children }: InfiniteScrollProps) {
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const checkScroll = useCallback((loading: boolean, hasMore: boolean, fetchMore: () => void) => {
    if (loading) {
      return;
    }
    const sentinel = sentinelRef.current;
    if (
      hasMore &&
      sentinel &&
      (sentinel.getBoundingClientRect().top <= window.innerHeight ||
        sentinel.getBoundingClientRect().top - window.innerHeight < 1200)
    ) {
      fetchMore();
    }
  }, []);

  const throttleCheckScroll = useMemo(
    () => throttle(checkScroll, 200, { leading: true, trailing: false }),
    [checkScroll],
  );

  useEffect(() => {
    if (
      !loading &&
      hasMore &&
      sentinelRef.current &&
      sentinelRef.current.getBoundingClientRect().top <= window.innerHeight
    ) {
      checkScroll(loading, hasMore, fetchMore);
    }
  }, [checkScroll, fetchMore, hasMore, loading]);

  useEffect(() => {
    const windowHandler = () => throttleCheckScroll(loading, hasMore, fetchMore);
    (scrollingElement ?? window).addEventListener("scroll", windowHandler, true);
    (scrollingElement ?? window).addEventListener("resize", windowHandler);
    return () => {
      (scrollingElement ?? window).removeEventListener("scroll", windowHandler, true);
      (scrollingElement ?? window).removeEventListener("resize", windowHandler);
    };
  }, [scrollingElement, checkScroll, fetchMore, loading, hasMore, throttleCheckScroll]);

  return (
    <Fragment>
      {children}
      <div id="sentinel" ref={sentinelRef} />
    </Fragment>
  );
}
