type Locale = Readonly<{
  languageCode: string;
  scriptCode?: string;
  countryCode: string;
  languageTag: string;
  isRTL: boolean;
}>;

const USES_RTL_LAYOUT = ["ar", "ckb", "fa", "he", "ks", "lrc", "mzn", "ps", "ug", "ur", "yi"];

export const isRTLLayout = (locale: string) => {
  const [languageCode] = locale.replace("_", "-").split("-");
  return USES_RTL_LAYOUT.includes(languageCode);
};

function ensureCountryCode(countryCode: string): string {
  return countryCode === "419" ? "UN" : countryCode.toUpperCase();
}

function splitLanguageTag(languageTag: string): {
  languageCode: string;
  countryCode?: string;
} {
  const [languageCode, countryCode] = languageTag.split("-");
  return { languageCode, countryCode };
}

function getDefaultCountry(): string {
  const { languages } = navigator;

  for (let i = 0; i < languages.length; i++) {
    const { countryCode } = splitLanguageTag(languages[i]);

    if (countryCode) {
      return ensureCountryCode(countryCode);
    }
  }

  return "US";
}

const defaultMainCountries = {
  ar: "MA",
  cs: "CZ",
  da: "DK",
  de: "DE",
  el: "GR",
  en: "US",
  es: "ES",
  et: "EE",
  fa: "IR",
  he: "IL",
  hi: "IN",
  hr: "HR",
  hu: "HU",
  hy: "AM",
  id: "ID",
  is: "IS",
  it: "IT",
  ja: "JP",
  ka: "GE",
  nl: "NL",
  no: "NO",
  pl: "PL",
  pt: "PT",
  ro: "RO",
  ru: "RU",
  sk: "SK",
  sl: "SI",
  sv: "SE",
  tr: "TR",
  uk: "UA",
  vi: "VN",
  zh: "CN",
};

function getCountry(languageCode: string): string | undefined {
  return defaultMainCountries[languageCode];
}

function convertLanguageTagToLocale(languageTag: string, countryCodeFallback: string): Locale {
  let { languageCode, countryCode } = splitLanguageTag(languageTag);
  languageCode = languageCode.toLowerCase();
  countryCode = ensureCountryCode(countryCode || getCountry(languageCode) || countryCodeFallback);

  return {
    languageCode,
    countryCode,
    languageTag: `${languageCode}-${countryCode}`,
    isRTL: USES_RTL_LAYOUT.includes(languageCode),
  };
}

function getLocales(): Locale[] {
  const { languages } = navigator;
  const countryCode = getDefaultCountry();
  const cache: string[] = [];
  const locales: Locale[] = [];

  languages.forEach((language) => {
    const locale = convertLanguageTagToLocale(language, countryCode);

    if (cache.indexOf(locale.languageTag) === -1) {
      locales.push(locale);
      cache.push(locale.languageTag);
    }
  });

  return locales;
}

function getPartialTag({ languageCode, scriptCode }: Locale) {
  return languageCode + (scriptCode ? "-" + scriptCode : "");
}

export function findBestAvailableLanguage(languageTags: string[]) {
  const locales = getLocales();

  for (let i = 0; i < locales.length; i++) {
    const currentLocale = locales[i];
    const { languageTag, languageCode, isRTL } = currentLocale;

    if (languageTags.includes(languageTag)) {
      return { languageTag: languageTag, isRTL };
    }

    const partial = getPartialTag(currentLocale);
    const next = locales[i + 1];

    if ((!next || partial !== getPartialTag(next)) && languageTags.includes(partial)) {
      return { languageTag: partial, isRTL };
    }

    if ((!next || languageCode !== next.languageCode) && languageTags.includes(languageCode)) {
      return { languageTag: languageCode, isRTL };
    }
  }
}
