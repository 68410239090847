import { GeoPosition } from "react-native-geolocation-service";
import { Amount } from "../../../core/amount/amount";
import { logger } from "../../../core/logging/logger";
import { ConnectedApiService } from "../../../core/net/connected-api-service";
import { PincodeSubmission } from "../../pincode/pincode";
import { AccountBlockedErrorFromErrorResponse, isAccountBlockedErrorResponse } from "../../pincode/pincode-error";
import { ConfirmationMode, TransactionCallType, TransactionRequest } from "../transaction-request";
import { DedicatedTransfer } from "./dedicated-transfer";

export class DedicatedTransferService {
  public constructor(private apiService: ConnectedApiService) {}

  public async startTransfer(
    recipientId: string,
    acceptorId: string,
    amount: Amount,
    label?: string,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-dedicated-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { amount, recipientId, acceptorId, label },
      });
      return response.data;
    } catch (e) {
      logger.debug("DedicatedTransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmSendDedicatedTransfer(
    confirmationMode: ConfirmationMode,
    dedicatedTransfer: DedicatedTransfer,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    const { recipientId, amount, acceptorId, label } = dedicatedTransfer;
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-dedicated-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location
            ? {
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
              }
            : undefined,
        },
        data: {
          amount,
          acceptorId,
          recipientId,
          label,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("DedicatedTransferService", "Confirm dedicated transfer failed", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }
}
