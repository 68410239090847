import { logger } from "../../core/logging/logger";
import { ApiService } from "../../core/net/api-service";
import { PincodeSubmission } from "./pincode";
import {
  AccountBlockedErrorFromErrorResponse,
  PINCODE_MUST_BE_CHANGED_ERROR_CODE,
  PincodeMustBeChangedError,
  isAccountBlockedErrorResponse,
} from "./pincode-error";

export class CheckPincodeService {
  public constructor(private apiService: ApiService) {}

  public async checkPincode(pincode: PincodeSubmission): Promise<void> {
    try {
      await this.apiService.instance.post<void>("/client/pincode/verify", {
        pincode: pincode,
      });
    } catch (e) {
      logger.debug("PincodeService", "Check pincode failed", e);
      if (e?.response?.data?.error?.code === PINCODE_MUST_BE_CHANGED_ERROR_CODE) {
        throw new PincodeMustBeChangedError(e?.response?.data?.error?.message);
      } else if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }
}
