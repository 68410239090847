import React, { AnchorHTMLAttributes } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { useRTL } from "../../../domain/language/use-rtl";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { PenIcon } from "../svg/pen-icon";
import { PhoneIcon } from "../svg/phone-icon";
import { PinIcon } from "../svg/pin-icon";
import { QuestionMarkIcon } from "../svg/question-mark-icon";

const ContactLinkType = {
  email: { icon: <PenIcon />, label: "bankContactScreen.emailContact" },
  website: { icon: <QuestionMarkIcon />, label: "bankContactScreen.urlContact" },
  phone: { icon: <PhoneIcon />, label: "bankContactScreen.phoneContact" },
  address: { icon: <PinIcon />, label: "bankContactScreen.addressContact" },
};

interface ContactLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  type: "email" | "website" | "phone" | "address";
  href: string;
}

export function ContactLink(props: ContactLinkProps) {
  const { type, ...rest } = props;
  const linkAttributes = type === "website" && { target: "_blank", rel: "noopener noreferrer nofollow" };
  const { formatMessage } = useIntl();
  return (
    <OutsideLink {...linkAttributes} {...rest}>
      <IconSquare>{ContactLinkType[type].icon}</IconSquare>
      <Label>{formatMessage(ContactLinkType[type].label)}</Label>
    </OutsideLink>
  );
}

interface ContactCardProps extends ContactLinkProps {
  value: string;
}
export function ContactCard(props: ContactCardProps) {
  const { type, value, ...rest } = props;
  const linkAttributes = type === "website" && { target: "_blank", rel: "noopener noreferrer nofollow" };
  const { formatMessage } = useIntl();
  const { isRTL } = useRTL();

  return (
    <OutsideLink {...linkAttributes} {...rest}>
      <CardContainer>
        <IconSquare>{ContactLinkType[type].icon}</IconSquare>
        <Column $isRTL={isRTL}>
          <CardContactLabel>{formatMessage(ContactLinkType[type].label)}</CardContactLabel>
          <CardContactValue>{value}</CardContactValue>
        </Column>
      </CardContainer>
    </OutsideLink>
  );
}

const OutsideLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconSquare = styled.div`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 18px;
  ${shadows.medium};
  background-color: ${theme.mainColor};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 26px;
    height: 26px;
    object-fit: contain;
    fill: #ffffff;
  }
`;

const Label = styled.span`
  font-size: 0.9375rem;
  ${theme.mediumText};
  color: #000000;
  text-align: center;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px;
  ${shadows.medium};
  border-radius: 18px;
  height: 120px;
  width: ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px;
  min-height: 120px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px;
  }
  background-color: #ffffff;
`;

const Column = styled.div<{ $isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${(props) => (props.$isRTL ? 0 : 15)}px;
  margin-right: ${(props) => (props.$isRTL ? 15 : 0)}px;
`;

const CardContactLabel = styled.div`
  ${theme.mediumText};
  color: #000000;
  margin-bottom: 10px;
  font-size: 0.9375rem;
`;
const CardContactValue = styled.span`
  font-size: 0.9375rem;
  word-break: break-word;
  color: #b1b1b1;
  direction: ltr;
`;
