import React, { useMemo, useState } from "react";
import { authenticationsSessionsService, productManager } from "../../../../../../shared/core/service/services";
import { CardActivationStep, useCardActivation } from "../../../../../../shared/domains/cards/use-card-activation";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../../../shared/utils/phone-number";

import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { VerifyAuthenticationStatus } from "../../../../../../shared/domains/authentication/authentications-sessions";
import { Card } from "../../../../../../shared/domains/cards/card";
import { useObservable } from "../../../../../../shared/utils/observable";
import { useAsyncFunction } from "../../../../../../shared/utils/utils";
import { CARD_ACTIVATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { useClient } from "../../../../../domain/authentication/use-client";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { TextInput } from "../../../../common/forms/text-input";
import { Modal } from "../../../../common/modal/modal";
import { RoundedModalContainer } from "../../../../common/modal/rounded-modal-container";
import { MainColorSpinner } from "../../../../common/spinner";
import { theme } from "../../../../styles/theme";
import { OtpConfirm } from "../../../recipient/otp-confirm";
import { ResultView } from "../../../result/result-view";
import { SetCardPincode } from "../pincode/set-card-pincode";

export interface ActivationCardProps {
  card: Card;
  disabled?: boolean;
  className?: string;
}

export const CardActivationModal = (props: ActivationCardProps) => (
  <RoundedModalContainer closeButton id={CARD_ACTIVATION_MODAL_ID}>
    <CardActivationView {...props} />
  </RoundedModalContainer>
);

const CardActivationView = (props: ActivationCardProps) => {
  const { card: selectedCard, className } = props;

  const {
    startActivationFlow,
    step,
    activationCode,
    setActivationCode,
    activateCard,
    validatePincode,
    activationError,
    scaActivationToken,
    scaPincodeToken,
  } = useCardActivation();

  const { client } = useClient();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [scaIsLoading, setScaIsLoading] = useState<boolean>(false);
  const [pincode, setPincode] = useState("");

  const { formatMessage } = useIntl();
  const [inputError, setInputError] = useState<string | null>(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const { loading } = useAsyncFunction(async () => {
    await Promise.all([productManager.loadDebitCardProducts()]);
    await startActivationFlow(selectedCard);
  }, []);

  const products = useObservable(productManager.debitCardProducts);
  const activationMessage = useMemo(() => {
    const cardProduct = products.find((p) => p.id === selectedCard.productId);
    return cardProduct?.cardActivationProcess || formatMessage("cardOptions.activationCard.defaultActivationMessage");
  }, [formatMessage, products, selectedCard.productId]);

  const pattern = useMemo(() => {
    const cardProduct = products.find((p) => p.id === selectedCard.productId);
    return cardProduct?.cardActivationCodeValidationRegex;
  }, [selectedCard, products]);

  const handleCodeChangeText = (text: string) => {
    setActivationCode(text);
    if (pattern) {
      setInputError(null);
    }
    if (text.length == 0) {
      setButtonEnabled(false);
    }
  };

  const handleEndEditing = (text: string) => {
    if (pattern) {
      let normalizedPattern = pattern;
      let options: string | undefined;
      const re = new RegExp("/(.+)/([i|g|m|u|x|s]*)");
      const matches = pattern.match(re);
      if (matches) {
        normalizedPattern = matches[1];
        if (matches.length > 2) {
          options = matches[2];
        }
      }
      const regex = new RegExp(normalizedPattern, options);
      const isValid = text.length > 0 && text.match(regex) != null;
      setInputError(isValid ? null : formatMessage("cardOptions.activationCard.inputError"));
      setButtonEnabled(isValid);
    } else {
      setButtonEnabled(text.length > 0);
    }
  };

  const handleOTPValidation = async (otp, scaToken, mode) => {
    if (scaToken) {
      try {
        setScaIsLoading(true);
        const response = await authenticationsSessionsService.verifyAuthentication(scaToken, otp);
        if (response.status === VerifyAuthenticationStatus.DONE) {
          if (mode === "ACTIVATION") {
            activateCard(selectedCard, activationCode, scaToken);
          } else {
            validatePincode(selectedCard, pincode, scaToken);
          }
        }
      } catch (e) {
        setErrorMessage(e);
      } finally {
        setScaIsLoading(false);
      }
    } else {
      throw new Error("Missing SCA Token");
    }
  };

  const innerStepComponent = () => {
    switch (step) {
      case CardActivationStep.ActivationCode:
        return (
          <Container className={className}>
            <Title>{formatMessage("cardOptions.activationCard.title")}</Title>
            <MessageLabel>{activationMessage}</MessageLabel>
            <IdentifierLabel>{formatMessage("cardOptions.activationCard.identifier")}</IdentifierLabel>
            <StyledTextInput
              autoFocus
              type="text"
              value={activationCode}
              onChange={(event) => handleCodeChangeText(event.target.value)}
              autoCapitalize="none"
              onBlur={() => handleEndEditing(activationCode)}
              hasError={!!inputError}
            />
            {inputError && <ErrorMessage>{inputError}</ErrorMessage>}
            <ActivateCardButton
              disabled={!buttonEnabled}
              showSpinner={loading}
              size="S"
              onClick={() => !loading && activateCard(selectedCard, activationCode)}>
              {formatMessage("cardOptions.activationCard.next")}
            </ActivateCardButton>
          </Container>
        );

      case CardActivationStep.DefinePincode:
        return (
          <SetCardPincode
            updatePincode={setPincode}
            validatePincode={(pincode: string) => validatePincode(selectedCard, pincode)}
            title={formatMessage("cardOptions.activationCard.stepPincode.title")}
            instructionsText={formatMessage("cardOptions.activationCard.stepPincode.definePincodeMessage")}
            confirmationText={formatMessage("cardOptions.activationCard.stepPincode.confirmPincodeMessage")}
            validateButtonTitle={formatMessage("cardOptions.activationCard.confirm")}
          />
        );
      case CardActivationStep.SCAActivationCheck:
        return (
          <OtpConfirm
            submitOtp={(otp: string) => handleOTPValidation(otp, scaActivationToken, "ACTIVATION")}
            errorMessage={errorMessage}
            phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
            loading={scaIsLoading}
          />
        );
      case CardActivationStep.SCAPincodeCheck:
        return (
          <OtpConfirm
            submitOtp={(otp: string) => handleOTPValidation(otp, scaPincodeToken, "PINCODE")}
            errorMessage={errorMessage}
            phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
            loading={scaIsLoading}
          />
        );
      case CardActivationStep.Error:
        return (
          <ResultView
            type={"error"}
            title={formatMessage("resultScreen.activateCard.failureTitle")}
            subtitle={formatMessage("resultScreen.activateCard.failureSubtitle")}
            description={formatMessage("resultScreen.activateCard.failureDescription")}
            error={activationError}
            onConfirm={() => Modal.dismiss(CARD_ACTIVATION_MODAL_ID)}
          />
        );

      case CardActivationStep.Success:
        return (
          <ResultView
            type={"success"}
            title={formatMessage("resultScreen.activateCard.successTitle")}
            subtitle={formatMessage("resultScreen.activateCard.successSubtitle")}
            onConfirm={() => Modal.dismiss(CARD_ACTIVATION_MODAL_ID)}
          />
        );
    }
  };

  return <ModalContainer>{loading ? <StyledSpinner /> : innerStepComponent()}</ModalContainer>;
};

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  height: 400px;
`;

const StyledSpinner = styled(MainColorSpinner)`
  margin: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  background-color: #fafafa;
  padding: 20px;
`;

const MessageLabel = styled.span`
  ${theme.mediumText}
  text-align: center;
  font-size: 0.875rem;
  color: #b1b1b1;
  margin-bottom: 56px;
`;

const IdentifierLabel = styled.span`
  ${theme.mediumText}
  margin-bottom: 8px;
  font-size: 0.9375rem;
  color: #000000;
`;

const ActivateCardButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 40px;
  align-self: center;
`;

const Title = styled.span`
  ${theme.boldText}
  text-align: center;
  font-size: 1.375rem;
  color: black;
  margin-top: 10px;
  margin-bottom: 12px;
`;

const StyledTextInput = styled(TextInput)<{ hasError?: boolean }>`
  margin-bottom: ${(props) => (props.hasError ? 8 : 32)}px;
`;

const ErrorMessage = styled.span`
  ${theme.mediumText}
  font-size: 0.813rem;
  color: #ff4646;
`;
