export class CashTransferCodeNotFound extends Error {
  public type = "CashTransferCodeNotFoundError";

  public constructor(message?: string) {
    super(message);
  }
}

export function isCashTransferCodeNotFoundError(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
): error is CashTransferCodeNotFound {
  return error?.type === "CashTransferCodeNotFoundError";
}
