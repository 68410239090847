import { Amount } from "../../core/amount/amount";

export interface AccountingEntry {
  id: string;
  date: string;
  accountId: string;
  description: string;
  amount: Amount;
  balance: Amount;
  type: AccountingEntryType;
}

export enum AccountingEntryType {
  Transfer = "TRANSFER",
  Fee = "FEE",
  Commission = "COMMISSION",
  Tax = "TAX",
  Discount = "DISCOUNT",
  Interest = "INTEREST",
}
