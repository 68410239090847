import React, { useState } from "react";
import styled from "styled-components";
import { useMountAnimation } from "../../utils/use-mount-animation";
import { RoundedSquare } from "./shape/rounded-square";
import { Spinner } from "./spinner";
import { BinIcon } from "./svg/bin-icon";
import { PenIcon } from "./svg/pen-icon";
import { EyeIcon } from "./svg/eye-icon";
import { DotsIcon } from "./svg/dots-icon";

interface MoreButtonProps {
  active: boolean;
  onClick: () => void;
}
const BUTTON_RIGHT_MARGIN = 12;
const BUTTON_SIZE = 34;

export const MoreButton = (props: MoreButtonProps) => {
  const { onClick } = props;
  return (
    <StyledRoundedSquare
      size={BUTTON_SIZE}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}>
      <StyledDotsIcon />
    </StyledRoundedSquare>
  );
};

const StyledRoundedSquare = styled(RoundedSquare)`
  background-color: white;
  transition: background-color 0.5s ease;

  :hover {
    background-color: #f4f4f4;
    cursor: pointer;
  }
`;

const StyledDotsIcon = styled(DotsIcon)`
  fill: black;
  transform: rotate(90deg);
`;

const BlackRoundedSquare = styled(RoundedSquare)<{ $show: boolean }>`
  background-color: #000000;
  margin-right: ${BUTTON_RIGHT_MARGIN}px;
  transform: ${(props) => (props.$show ? "translateX(0}px)" : `translateX(${BUTTON_SIZE + BUTTON_RIGHT_MARGIN}px)`)};
  opacity: ${(props) => (props.$show ? "1" : "0")};
  transition:
    transform 0.2s,
    opacity 0.2s;
`;

export const DeleteButton = (props: Omit<MenuButtonProps, "children">) => {
  const { onClick, ...other } = props;
  const [loading, setLoading] = useState(false);
  const deleteOperation = async () => {
    try {
      setLoading(true);
      await onClick();
    } finally {
      setLoading(false);
    }
  };
  return (
    <MenuButton onClick={deleteOperation} {...other}>
      {loading ? <Spinner color="#ffffff" size="13px" /> : <BinIcon fill="#ffffff" />}
    </MenuButton>
  );
};

export const EditButton = (props: Omit<MenuButtonProps, "children">) => {
  const { onClick, ...other } = props;
  return (
    <MenuButton onClick={onClick} {...other}>
      <PenIcon width={20} fill="#ffffff" />
    </MenuButton>
  );
};

export const OpenButton = (props: Omit<MenuButtonProps, "children">) => {
  const { onClick, ...other } = props;
  return (
    <MenuButton onClick={onClick} {...other}>
      <EyeIcon width={20} fill="#ffffff" />
    </MenuButton>
  );
};

interface MenuButtonProps {
  show: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}
const MenuButton = (props: MenuButtonProps) => {
  const { show, onClick, children } = props;
  const [translateAnimation, setTranslateAnimation] = useState(false);
  const { shouldRender, onTransitionEnd } = useMountAnimation(
    show,
    () => setTranslateAnimation(true),
    () => setTranslateAnimation(false),
  );

  return shouldRender ? (
    <BlackRoundedSquare
      size={BUTTON_SIZE}
      $show={translateAnimation}
      onTransitionEnd={onTransitionEnd}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}>
      {children}
    </BlackRoundedSquare>
  ) : null;
};
