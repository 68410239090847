import { Amount } from "../../../core/amount/amount";
import { TransactionGeolocationManager } from "../../../core/geolocalisation/transaction-geolocalisation-manager";
import { runAfterInteractions } from "../../../core/interaction/interaction-manager";
import { Observable } from "../../../utils/observable";
import { Account } from "../../account/account";
import { AccountManager } from "../../account/account-manager";
import { accountsAffectedByTransaction } from "../../accounting-transaction/transaction/accounts-affected-by-transaction";
import { Transaction } from "../../accounting-transaction/transaction/transaction";
import { TransactionsManager } from "../../accounting-transaction/transaction/transactions-manager";
import { FeaturesManager } from "../../features/features-manager";
import { PincodeSubmission } from "../../pincode/pincode";
import { AccountOrRecipient, PaymentAddress, PaymentNetwork, ServiceLevel } from "../customer-instruction";
import { ConfirmationMode } from "../transaction-request";
import { TransferService } from "./transfer-service";

export class TransferManager {
  public constructor(
    private transferService: TransferService,
    private geolocationManager: TransactionGeolocationManager,
    private accountManager: AccountManager,
    private transactionsManager: TransactionsManager,
    private featuresManager: FeaturesManager,
  ) {}
  public paymentNetworks = new Observable<PaymentNetwork[]>([]);

  public async startTransfer(recipientId: string, amount: Amount, label: string | undefined = undefined) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startTransfer(recipientId, amount, label, location);
  }

  public async confirmTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmTransfer(
      confirmationMode,
      recipientId,
      amount,
      label,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  public async startPayout(
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
  ) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startPayout(recipientId, amount, srcAccountId, purpose, location);
  }

  public async confirmPayout(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmPayout(
      confirmationMode,
      recipientId,
      amount,
      srcAccountId,
      purpose,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  public async startCashTransfer(recipientId: string, amount: Amount, label: string | undefined = undefined) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startCashTransfer(recipientId, amount, label, location);
  }

  public async confirmCashTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmCashTransfer(
      confirmationMode,
      recipientId,
      amount,
      label,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  private refreshAccountAndTransactions(transaction: Transaction) {
    runAfterInteractions(async () => {
      const accountIds = accountsAffectedByTransaction(transaction);
      await this.accountManager.refresh();
      await this.transactionsManager.refresh(accountIds);
    });
  }

  // SIMPLE TRANSFER

  public async startSimpleTransfer(amount: Amount, phoneNumber: string, label: string | undefined = undefined) {
    const location = await this.geolocationManager.updatePosition();
    return await this.transferService.startSimpleTransfer(amount, phoneNumber, label, location);
  }

  public async confirmSimpleTransfer(
    confirmationMode: ConfirmationMode,
    amount: Amount,
    phoneNumber: string,
    label?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.transferService.confirmSimpleTransfer(
      confirmationMode,
      amount,
      phoneNumber,
      label,
      pincode,
      location,
    );
    this.refreshAccountAndTransactions(result.metadata.transaction);
    return result;
  }

  // CUSTOMER INSTRUCTIONS
  public async getPaymentNetworks(): Promise<PaymentNetwork[]> {
    if (!this.featuresManager.features.get().paymentNetwork || !this.featuresManager.features.get().paymentContract) {
      return [];
    }
    try {
      const response = await this.transferService.getPaymentNetworks();
      // TODO: remove this filter when the backend is fixed
      const paymentNetworks = response.filter((item) => item.activated && item.serviceLevel !== ServiceLevel.ON_US);
      this.paymentNetworks.set(paymentNetworks);
      return paymentNetworks;
    } catch (error) {
      this.paymentNetworks.set([]);
      throw error;
    }
  }
  public async startCustomerInstruction(
    paymentNetwork: PaymentNetwork,
    amount: Amount,
    sourceAccount: Account,
    destinationAccountOrRecipient: AccountOrRecipient,
    pincode?: PincodeSubmission,
    label?: string,
    creditorAddress?: PaymentAddress,
  ) {
    if (!this.featuresManager.features.get().customerInstructionInitiation) {
      throw "Customer instruction initiation is not enabled";
    }
    return await this.transferService.startCustomerInstruction(
      paymentNetwork,
      amount,
      sourceAccount,
      destinationAccountOrRecipient,
      pincode,
      label,
      creditorAddress,
    );
  }

  public async submitCustomerInstruction(strongAuthenticationReference: string, id: string | number) {
    try {
      return await this.transferService.submitCustomerInstruction(id, strongAuthenticationReference);
    } catch (error) {
      throw error;
    }
  }

  // private async getCustomerInstruction(id: string | number) {
  // 	if (!this.featuresManager.features.get().customerInstructionView) {
  // 		throw "Cannot get customer instruction. Feature not enabled";
  // 	}
  // 	return await this.transferService.getCustomerInstruction(id);
  // }
}
