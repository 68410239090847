import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { SuccessCircle } from "../../common/success-circle";
import { theme } from "../../styles/theme";

interface PincodeChangeSuccessProps {
  onComplete: () => void;
}
export const PincodeChangeSuccess = (props: PincodeChangeSuccessProps) => {
  const { onComplete } = props;
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Title>{formatMessage("pincodeChangeScreen.title")}</Title>
      <Content>
        <GreenCheckCircle />
        <ExplanationMessage>{formatMessage("pincodeChangeFlow.success")}</ExplanationMessage>
        <DoneButton size="S" onClick={onComplete}>
          {formatMessage("pincodeChangeScreen.done")}
        </DoneButton>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 30px 30px 30px;
`;

const ExplanationMessage = styled.span`
  color: #b1b1b1;
  ${theme.mediumText};
  text-align: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;
const GreenCheckCircle = styled(SuccessCircle)`
  margin-top: 20px;
  margin-bottom: 50px;
`;

const DoneButton = styled(PrimaryButton)`
  margin-top: 30px;
`;
const Title = styled.div`
  ${theme.boldText};
  font-size: 1.375rem;
`;
