import { Amount, amountValueForCurrency } from "../../core/amount/amount";

import { isDefined } from "../../utils/assert";

export enum OutstandingsType {
  None,
  Payments,
  Cashouts,
}

export enum DurationUnit {
  Hour = "HOUR",
  Week = "WEEK",
  Day = "DAY",
  Month = "MONTH",
  Year = "YEAR",
}

const cashoutTypes = [
  "LIMIT_CASHOUT_DAY",
  "LIMIT_CASHOUT_WEEK",
  "LIMIT_CASHOUT_MONTH",
  "LIMIT_CASHOUT_YEAR",
  "LIMIT_CASHOUT_ALL",
] as const;
export type OutstandingCashoutType = (typeof cashoutTypes)[number];

const paymentTypes = [
  "LIMIT_PAYMENT_DAY",
  "LIMIT_PAYMENT_WEEK",
  "LIMIT_PAYMENT_MONTH",
  "LIMIT_PAYMENT_YEAR",
  "LIMIT_PAYMENT_ALL",
] as const;
export type OutstandingPaymentType = (typeof paymentTypes)[number];

export interface OutstandingDuration {
  value: number;
  unit: DurationUnit;
}

export interface OutstandingSpecific {
  startDate: Date;
  endDate: Date;
}

export interface OutstandingValue {
  value: number;
  currency?: string;
}

export interface OutstandingDTO {
  id: string;
  limit: OutstandingValue;
  current: OutstandingValue;
  label?: string;
  type?: OutstandingCashoutType | OutstandingPaymentType;
  period?: OutstandingDuration;
  termDay?: number;
  specificPeriod?: OutstandingSpecific;
}

export interface Outstanding {
  id: string;
  title: string;
  maxValue: number;
  usedValue: number;
  availableValue: number;
  maxAmount?: Amount;
  usedAmount?: Amount;
  availableAmount?: Amount;
  isPayment: boolean;
  period?: OutstandingDuration;
  specificPeriod?: OutstandingSpecific;
  type?: OutstandingDTO["type"];
}

export interface OutstandingResponse {
  outstandings: OutstandingDTO[];
}

export function isPayment(
  outstandingType?: OutstandingCashoutType | OutstandingPaymentType,
): outstandingType is OutstandingPaymentType {
  if (outstandingType) {
    return paymentTypes.includes(outstandingType as OutstandingPaymentType);
  }
  return false;
}

export function amount(value: number, currency?: string): Amount | undefined {
  if (isDefined(currency)) {
    const amountVal = amountValueForCurrency(value, currency);
    return new Amount(amountVal, currency);
  }
}
