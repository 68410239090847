import React from "react";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../shared/core/service/services";
import { Recipient } from "../../../../shared/domains/recipients/recipient";
import { useObservable } from "../../../../shared/utils/observable";
import { Path } from "../../../core/routing/path";
import { theme } from "../../styles/theme";
import { RecipientsScreen } from "../recipient/recipients-screen";
import { TransferTransaction } from "./components/transfer-modal";

export enum TransferDestination {
  Beneficiaries,
  ExternalAccounts,
}

export function TransferScreen() {
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const features = useObservable(featuresManager.features);

  return features.customerInstructionInitiation ? (
    <>
      <Title>{formatMessage("recipient.title")}</Title>
      <TransferTransaction isOpenLoop recipient={{} as Recipient} closeModal={() => push(Path.AccountTransactions)} />
    </>
  ) : (
    <RecipientsScreen />
  );
}

const Title = styled.span`
  font-size: 1.5625rem;
  margin-bottom: 12px;
  ${theme.boldText};
  color: ${theme.mainColor};
  white-space: pre;
`;
