import moment from "moment";
import { Cache } from "../../../shared/core/cache/cache";
import { CacheEntry, isCacheValid } from "../../../shared/core/cache/cache-entry";
import { CacheStatus } from "../../../shared/core/cache/cache-status";
import { SessionStorage } from "./session-storage";

export class SessionCache<T> implements Cache<T> {
  private sessionStorage = new SessionStorage<CacheEntry<T>>();

  constructor(
    private ttl: number,
    private storageKey: string,
  ) {}
  async read(key: string): Promise<T | null> {
    const value = await this.sessionStorage.read(this.getStorageKey(key));
    return value?.data ?? null;
  }
  store(data: T, creation: Date | undefined, key: string): void {
    const entryCreation = creation ? moment(creation) : moment();
    this.sessionStorage.store({ data, creation: entryCreation, ttl: this.ttl }, this.getStorageKey(key));
  }
  clear(key: string): Promise<void> {
    return this.sessionStorage.clear(this.getStorageKey(key));
  }
  async isValid(key: string): Promise<boolean> {
    const entry = await this.sessionStorage.read(this.getStorageKey(key));
    if (entry !== null) {
      return isCacheValid(entry);
    }
    return false;
  }
  async readStatus(key: string): Promise<CacheStatus | null> {
    const cacheEntry = await this.sessionStorage.read(this.getStorageKey(key));
    return cacheEntry
      ? {
          isValid: isCacheValid(cacheEntry),
          creation: moment(cacheEntry.creation).toDate(),
        }
      : null;
  }

  private getStorageKey(key: string): string {
    if (key !== "default") {
      return `${this.storageKey}_${key}`;
    }
    return this.storageKey;
  }
}
