import * as React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { DefaultButton } from "./default-button";

interface SwitchButtonProps {
  value: boolean;
  onSwitch: (value: boolean) => void;
  id?: string;
  className?: string;
}

export function SwitchButton(props: SwitchButtonProps) {
  const { value, onSwitch, ...rest } = props;

  const toggleSwitch = () => {
    onSwitch(!value);
  };
  return (
    <Switch role="switch" type="button" aria-checked={value} onClick={toggleSwitch} {...rest}>
      <Round />
    </Switch>
  );
}

interface SwitchContainerProps extends SwitchButtonProps {
  getSwitchLabel: (value: boolean) => string;
}

export function SwitchContainer(props: SwitchContainerProps) {
  const { className, getSwitchLabel, ...rest } = props;

  return (
    <Container className={className}>
      <Label>{getSwitchLabel(props.value)}</Label>
      <SwitchButton {...rest} />
    </Container>
  );
}

const Switch = styled(DefaultButton)`
  width: 50px;
  height: 25px;
  padding: 3px 4px 3px 27px;
  border: solid 1px ${theme.mainColor};
  border-radius: 12.5px;
  background-color: ${theme.mainColor};
  opacity: 1;
  transition: opacity 0.2s;
  will-change: opacity;

  &[aria-checked="false"] {
    background-color: transparent;
  }

  :disabled {
    opacity: 0.5;
  }
`;

const Round = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 10px;
  background-color: ${theme.mainColor};
  transition: transform 0.2s;
  will-change: transform;
  transform: translateX(-23px);

  [aria-checked="true"] & {
    background-color: #ffffff;
    transform: translateX(0);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000;
  padding: 0 8px 0 16px;
  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const Label = styled.div`
  flex: 1;
  ${theme.mediumText}
  color: black;
  font-size: 0.85rem;
`;
