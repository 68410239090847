import { FeaturesManager } from "../features/features-manager";
import { PincodeSubmission } from "../pincode/pincode";
import { AuthenticationsMethod, VerifyAuthenticationStatus } from "./authentications-sessions";
import AuthenticationsSessionsService from "./authentications-sessions-service";

export default class AuthenticationsSessionsManager {
  public constructor(
    private authenticationsSessionsService: AuthenticationsSessionsService,
    private featuresManager: FeaturesManager,
  ) {}

  public async getAuthenticationSession(strongAuthenticationReference: string) {
    if (!this.featuresManager.features.get().sdaAuthenticationsSessionsView) {
      throw "Cannot get authentication session. Feature not enabled";
    }
    try {
      const session = await this.authenticationsSessionsService.getAuthenticationSession(strongAuthenticationReference);
      if (session.token) {
        return this.sendAuthentication(session.token);
      }
    } catch (error) {
      throw error;
    }
  }

  public async sendAuthentication(strongAuthenticationReference: string) {
    if (!this.featuresManager.features.get().sdaAuthenticationsSessionsAuth) {
      throw "Cannot send authentication SMS. Feature not enabled";
    }
    return await this.authenticationsSessionsService.sendAuthentication(strongAuthenticationReference);
  }

  public async verifyAuthentication(
    strongAuthenticationReference: string,
    code: string | PincodeSubmission,
    method?: AuthenticationsMethod,
  ) {
    try {
      const verifyResponse = await this.authenticationsSessionsService.verifyAuthentication(
        strongAuthenticationReference,
        code,
        method,
      );
      if (verifyResponse.status === VerifyAuthenticationStatus.DONE) {
        return verifyResponse;
      } else {
        throw "Authentication failed";
      }
    } catch (error) {
      throw error;
    }
  }

  public async cancelAuthentication(strongAuthenticationReference: string) {
    try {
      await this.authenticationsSessionsService.cancelAuthentication(strongAuthenticationReference);
    } catch (error) {
      logger.debug("AuthenticationsSessionsService", "Failed to cancel authentication", error);
      throw error;
    }
  }
}
