import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../../../shared/core/logging/logger";
import { Card, CardStatus, OpposeCardReason } from "../../../../../../shared/domains/cards/card";
import { CARD_OPPOSITION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../../common/error-message";
import { RadioSelector, RadioSelectorType } from "../../../../common/forms/radio-selector";
import { Modal } from "../../../../common/modal/modal";
import { RoundedModalContainer } from "../../../../common/modal/rounded-modal-container";
import { shadows, theme } from "../../../../styles/theme";
import { UIConstants } from "../../../../styles/uiConstants";
import { ActionMenu } from "../action-menu";

interface OppositionCardProps {
  card: Card;
  loading: boolean;
  oppose: (reason: OpposeCardReason) => Promise<void>;
  className?: string;
}

export const OppositionButton: React.FC<OppositionCardProps> = ({ card, className, ...otherOptions }) => {
  const { formatMessage } = useIntl();

  const canOppose = useMemo(() => {
    switch (card.status) {
      case CardStatus.Pending:
      case CardStatus.Active:
      case CardStatus.Blocked:
        return true;
      default:
        return false;
    }
  }, [card.status]);

  if (!canOppose) {
    return null;
  }

  return (
    <ActionMenu
      title={formatMessage("cardOptions.oppositionCard.opposeButton")}
      className={className}
      onClick={() => {
        Modal.present(CARD_OPPOSITION_MODAL_ID, () => <OppositionModal card={card} {...otherOptions} />, {
          canBeDismissed: false,
        });
      }}
    />
  );
};

const OppositionModal = (props: OppositionCardProps) => (
  <RoundedModalContainer closeButton id={CARD_OPPOSITION_MODAL_ID}>
    <OppositionCard {...props} />
  </RoundedModalContainer>
);

const OppositionCard: React.FC<OppositionCardProps> = (props) => {
  const { card, loading, oppose, className } = props;
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string | null>(null);
  const [selectedOpposeReason, setSelectedOpposeReason] = useState(OpposeCardReason.Lost);

  useEffect(() => {
    setSelectedOpposeReason(OpposeCardReason.Lost);
  }, [card]);

  const opposeReasons = useMemo(
    () =>
      new Map<OpposeCardReason, string>([
        [OpposeCardReason.Lost, formatMessage("cardOptions.oppositionCard.reasonLost")],
        [OpposeCardReason.Stolen, formatMessage("cardOptions.oppositionCard.reasonStolen")],
        [OpposeCardReason.NotDistributed, formatMessage("cardOptions.oppositionCard.reasonNotDistributed")],
      ]),
    [formatMessage],
  );

  const opposeCard = async () => {
    try {
      setError(null);
      await oppose(selectedOpposeReason);
      Modal.dismiss(CARD_OPPOSITION_MODAL_ID);
    } catch (e) {
      logger.debug(e);
      setError(e?.response?.data?.error?.message);
    }
  };

  const opposeCardWithConfirm = () => {
    if (window?.confirm) {
      if (
        window.confirm(
          `${formatMessage("cardOptions.oppositionCard.opposePromptTitle")}. ${formatMessage(
            "cardOptions.oppositionCard.opposePromptMessage",
          )}`,
        )
      ) {
        opposeCard();
      } else {
        Modal.dismiss(CARD_OPPOSITION_MODAL_ID);
      }
    } else {
      opposeCard();
    }
  };

  return (
    <Container className={className}>
      <MessageLabel>{formatMessage("cardOptions.oppositionCard.title")}</MessageLabel>
      <ReasonsContainer>
        <StyledRadioSelector
          innerId="opposition_button"
          values={opposeReasons}
          value={selectedOpposeReason}
          onChange={setSelectedOpposeReason}
        />
      </ReasonsContainer>
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      <ConfirmButtonsContainer>
        <PrimaryButton showSpinner={loading} size="S" onClick={() => !loading && opposeCardWithConfirm()}>
          {formatMessage("cardOptions.oppositionCard.opposeConfirmButton")}
        </PrimaryButton>
        <PrimaryButton size="S" onClick={() => Modal.dismiss(CARD_OPPOSITION_MODAL_ID)}>
          {formatMessage("cardOptions.oppositionCard.opposeCancelButton")}
        </PrimaryButton>
      </ConfirmButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  border-radius: 13px;
  ${shadows.medium};
  padding: 20px;
`;

const ReasonsContainer = styled.div`
  display: flex;
  margin-top: 25px;
  align-self: stretch;
`;

const MessageLabel = styled.span`
  ${theme.mediumText}
  font-size: 14px;
  color: #b1b1b1;
`;

const ConfirmButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 36px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > * {
      width: 100%;
      max-width: 200px;
    }

    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const StyledRadioSelector = styled<RadioSelectorType<OpposeCardReason>>(RadioSelector)`
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0px;
`;
