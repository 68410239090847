import { useEffect, useState } from "react";
import { Features } from "../../features/features";
import { Recipient } from "../../recipients/recipient";

export enum TransferMode {
  AccountTransfer = "account-transfer",
  CashTransfer = "cash-transfer",
}

export const useAvailableTransferMode = (recipient: Recipient, features: Features) => {
  const [availableTypes, setAvailableTypes] = useState<TransferMode[]>([]);

  useEffect(() => {
    if (recipient?.iban || recipient?.accountReference) {
      setAvailableTypes([]);
      return;
    }
    const transferTypes: TransferMode[] = [];
    if (features.p2pTransfer) {
      transferTypes.push(TransferMode.AccountTransfer);
    }
    if (features.p2pCashTransfer) {
      transferTypes.push(TransferMode.CashTransfer);
    }
    setAvailableTypes(transferTypes);
  }, [recipient, features]);

  return availableTypes;
};
