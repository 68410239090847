import React from "react";
import { isAccountBlocked } from "../../../../../shared/domains/pincode/pincode-error";
import { TRANSACTION_CODE_ID } from "../../../../core/modal/modal-id";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { TransactionCodeState, useTransactionCodeMachine } from "../../../../machine/transaction-code-machine";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { SelectAcceptor } from "./select-acceptor";
import { SelectAmountLimit } from "./select-amount-limit";
import { TransactionCodePincode } from "./transaction-code-pincode";
import { TransactionCodeSummary } from "./transaction-code-summary";

const loadingStates = [TransactionCodeState.Confirmation];

export const TransactionCodeModal = () => {
  const { state, context, selectAcceptorId, goBack, selectAmount, submitPincode } = useTransactionCodeMachine();

  const errorMessage = !isAccountBlocked(context.error) ? context.error : undefined;

  const renderInnerStep: () => JSX.Element = () => {
    switch (state) {
      case TransactionCodeState.EnterAcceptorId:
        return <SelectAcceptor selectMethod={selectAcceptorId} />;

      case TransactionCodeState.SelectingAmount:
      case PincodeState.PincodeConfirmation:
        return (
          <SelectAmountLimit
            acceptorId={context.acceptorIdShortCode!}
            backMethod={goBack}
            selectMethod={selectAmount}
            loading={state in loadingStates}
          />
        );
      case TransactionCodeState.Confirmation:
      case PincodeState.FetchKeyboardAfterError:
      case PincodeState.PromptingKeyboard: {
        return (
          <TransactionCodePincode
            keyboard={context.keyboard!}
            backMethod={goBack}
            submitMethod={submitPincode}
            errorMessage={errorMessage}
            loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
          />
        );
      }

      case TransactionCodeState.Done:
        return (
          <TransactionCodeSummary
            transactionCode={context.transactionResult!}
            onDone={() => Modal.dismiss(TRANSACTION_CODE_ID)}
          />
        );
    }
  };
  return (
    <RoundedModalContainer closeButton id={TRANSACTION_CODE_ID}>
      {renderInnerStep()}
    </RoundedModalContainer>
  );
};
