import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { OtpInput } from "../../../common/forms/otp-input";
import { GBRDots } from "../../../common/svg/gbr-dots";
import { theme } from "../../../styles/theme";

export interface SelectAcceptorProps {
  className?: string;
  selectMethod: (acceptorIdShortCode: string) => void;
}

const ACCEPTOR_ID_LENGTH = 6;

export const SelectAcceptor = (props: SelectAcceptorProps) => {
  const { className, selectMethod } = props;

  const [acceptorId, setAcceptorId] = useState("");

  const { formatMessage } = useIntl();

  return (
    <Container className={className}>
      <AcceptorTitle>{formatMessage("transactionCode.selectAcceptor.title")}</AcceptorTitle>
      <IllustrationContainer>
        <GBRDots style={{ alignSelf: "center" }} />
      </IllustrationContainer>
      <AcceptorDescription>{formatMessage("transactionCode.selectAcceptor.description")}</AcceptorDescription>
      <OtpInput length={ACCEPTOR_ID_LENGTH} onOtpChange={setAcceptorId} />
      <NextButton size="M" disabled={acceptorId.length < ACCEPTOR_ID_LENGTH} onClick={() => selectMethod(acceptorId)}>
        {formatMessage("transactionCode.selectAcceptor.next")}
      </NextButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const AcceptorTitle = styled.span`
  ${theme.boldText};
  font-size: 1.375rem;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 45px;
`;

const AcceptorDescription = styled.span`
  ${theme.mediumText};
  font-size: 0.8125rem;
  color: #000000;
  margin-top: 45px;
  margin-bottom: 45px;
`;

const NextButton = styled(PrimaryButton)`
  margin-top: 63px;
  margin-bottom: 40px;
`;

const IllustrationContainer = styled.div`
  display: flex;
  width: 95px;
  height: 35px;
`;
