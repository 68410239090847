import { FormCollectionInput } from "../../core/data-forms/form-input-types";
import { logger } from "../../core/logging/logger";
import { ApiService } from "../../core/net/api-service";
import { RegisterMode, RegisterPreAuthDto, RegisterPreAuthErrorDto } from "./register-request";

export class RegisterService {
  public constructor(private apiService: ApiService) {}

  public async startRegister(phoneNumber: string) {
    try {
      const response = await this.apiService.instance.get<FormCollectionInput>(`/enrollment/${phoneNumber}`);
      return response.data;
    } catch (e) {
      logger.debug("RegisterService", "Failed to start register", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmRegister(phoneNumber: string, enrollmentId: string) {
    try {
      const response = await this.apiService.instance.post(`/enrollment/${phoneNumber}`, {
        metadata: {
          mode: RegisterMode.Transaction,
          enrollmentId,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("RegisterService", "Failed to confirm register", e, JSON.stringify(e));
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async validateForm(
    phoneNumber: string,
    form: { [key: string]: string | number | boolean },
    redirectUrl: string,
  ) {
    try {
      const response = await this.apiService.instance.post(`/enrollment/${phoneNumber}`, {
        metadata: { mode: RegisterMode.PreAuth },
        data: { form, redirectUrl },
      });
      if (response.status === 200) {
        return { data: response.data as RegisterPreAuthDto, done: true as const };
      } else {
        return { data: response.data as RegisterPreAuthErrorDto, done: false as const };
      }
    } catch (e) {
      logger.debug("RegisterService", "Failed to validate register", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }
}
