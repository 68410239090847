import { AuthenticationMode, Configuration } from "./configuration";
import { ConfigurationInitializationFailedError, ConfigurationNotLoadedError } from "./configuration-error";

import { CountryCode } from "libphonenumber-js";
import { DefaultCacheLoader } from "../../core/cache/default-cache-loader";
import { Config } from "../../core/config/config";
import { DefaultCountryProvider } from "../../core/i18n/default-country-provider";
import { I18NStore } from "../../core/i18n/i18n-store";
import { logger } from "../../core/logging/logger";
import { isDefined } from "../../utils/assert";
import { Observable } from "../../utils/observable";
import { ConfigurationService } from "./configuration-service";

const DEFAULT_NB_DAYS_TRUST_DURATION = 30;

export class ConfigurationManager {
  public configuration = new Observable<Configuration>({
    enrollment: true,
    pincode: { maxLength: 4, minLength: 4 },
    otp: { maxLength: 6, minLength: 6 },
    p2pCodeLength: { maxLength: 5, minLength: 5 },
    langs: ["fr_FR", "en_US", "es_ES"],
    countries: ["FR", "ES", "DE"] as CountryCode[],
    colorScheme: {
      primaryColor: "",
      menuColors: [],
      recipientColors: [],
      billIssuerColors: [],
    },
    authentication_mode: AuthenticationMode.LoginPassword,
    visaPaymentAvailable: false,
    isCameraCaptureAuthorized: false,
    isLocalFileUploadAuthorized: false,
    logo: "",
    favicon: "",
    registerImage: "",
    carouselImages: [],
    userAppMinimumVersion: "",
    trustDuration: DEFAULT_NB_DAYS_TRUST_DURATION,
    simplePayment: false,
    selectDistributorDuringOnboarding: false,
  });

  public defaultCountry = new Observable<CountryCode>("FR");

  public constructor(
    private configurationService: ConfigurationService,
    private cacheLoader: DefaultCacheLoader<Configuration>,
    private defaultCountryProvider: DefaultCountryProvider | null,
    private i18NStore: I18NStore,
  ) {}

  public async load() {
    try {
      const configuration = await this.cacheLoader.load(
        async () => await this.configurationService.fetchConfiguration(),
        true,
      );
      if (!configuration) {
        throw new ConfigurationNotLoadedError("Fetch configuration failed and configuration not in cache");
      }

      this.configuration.set(configuration);
      const defaultCountry = await this.getDefaultCountry(configuration);
      if (defaultCountry) {
        this.defaultCountry.set(defaultCountry);
      }
    } catch (e) {
      logger.debug("ConfigurationManager", "Fetch configuration failed", e);
      throw new ConfigurationInitializationFailedError("Failed to fetch configuration");
    }
  }

  public getPrimaryColor() {
    const primaryColor = this.configuration.get().colorScheme.primaryColor;
    if (primaryColor && primaryColor.length > 0) {
      return primaryColor;
    }
    return Config.APP_PRIMARY_COLOR;
  }

  public getAvatarColors() {
    const avatarColors = this.configuration.get().colorScheme.recipientColors;
    if (avatarColors && avatarColors.length > 0) {
      return avatarColors;
    }
    return Config.APP_AVATAR_COLORS;
  }

  public computeAvatarColor(str = "") {
    const avatarColors = this.getAvatarColors();
    return avatarColors[Array.from(str).reduce((acc, char) => acc + char.charCodeAt(0), 0) % avatarColors.length];
  }

  public getMenuColors() {
    const menuColors = this.configuration.get().colorScheme.menuColors;
    if (menuColors && menuColors.length > 0) {
      return menuColors;
    }
    return Config.APP_MORE_MENU_COLORS;
  }

  public getBillIssuerColors() {
    const billIssuerColors = this.configuration.get().colorScheme.billIssuerColors;
    if (billIssuerColors && billIssuerColors.length > 0) {
      return billIssuerColors;
    }
    return Config.APP_BILL_ISSUERS_COLORS;
  }

  private async getDefaultCountry(configuration: Configuration) {
    let carrierCountryCode: CountryCode | undefined;
    try {
      if (this.defaultCountryProvider) {
        carrierCountryCode = (await this.defaultCountryProvider.getDefaultCountry())?.toUpperCase() as CountryCode;
      }
    } catch (e) {
      logger.debug("ConfigurationManager", "Get default country failed", e);
    }

    if (configuration.countries) {
      return carrierCountryCode && configuration.countries.includes(carrierCountryCode)
        ? carrierCountryCode
        : configuration.countries[0];
    } else {
      return undefined;
    }
  }

  public getBestInfoUrlLocale() {
    const locale = this.i18NStore.localeTag.get();
    const infoUrl = this.configuration.get().infoUrl;
    const fallbackLocale = "en_US";
    if (!isDefined(infoUrl)) {
      return null;
    }
    const hasLocaleUrl = isDefined(infoUrl[locale]);
    if (hasLocaleUrl) {
      return infoUrl[locale];
    }
    const hasFallbackUrl = isDefined(infoUrl[fallbackLocale]);
    if (hasFallbackUrl) {
      return infoUrl[fallbackLocale];
    }
    const urlAsArray = Object.values(infoUrl);
    return urlAsArray.length > 0 ? urlAsArray[0] : null;
  }

  public getFaviconUrl(size?: "16x16" | "32x32" | "192x192" | "180x180") {
    const faviconFromConfig = this.configuration.get().favicon;
    if (isDefined(faviconFromConfig)) {
      return faviconFromConfig;
    }

    const extension = size ? ".png" : ".ico";
    const filename = size ? `favicon-${size}` : "favicon-mw";
    return `./static/${filename}${extension}`;
  }

  public getLogoUrl() {
    const logoFromConfig = this.configuration.get().logo;
    if (isDefined(logoFromConfig)) {
      return logoFromConfig;
    }

    return null;
  }

  public getRegisterImageUrl() {
    const registerImageFromConfig = this.configuration.get().registerImage;
    if (isDefined(registerImageFromConfig)) {
      return registerImageFromConfig;
    }

    return null;
  }

  public getCarouselUrls() {
    const carouselImages = this.configuration.get().carouselImages;
    if (isDefined(carouselImages) && carouselImages.length > 0) {
      return carouselImages;
    }

    return null;
  }

  public getNbDaysTrustDuration() {
    const nbDaysTrustDuration = this.configuration.get().trustDuration;
    if (isDefined(nbDaysTrustDuration)) {
      return nbDaysTrustDuration;
    }
    return DEFAULT_NB_DAYS_TRUST_DURATION;
  }

  public isSimplePayment() {
    const simplePayment = this.configuration.get().simplePayment;
    if (isDefined(simplePayment)) {
      return simplePayment;
    }
    return false;
  }

  public needDistributorSelectionDuringOnboarding() {
    const selectDistributorDuringOnboarding = this.configuration.get().selectDistributorDuringOnboarding;
    if (isDefined(selectDistributorDuringOnboarding)) {
      return selectDistributorDuringOnboarding;
    }
    return false;
  }

  public async reload() {
    this.cacheLoader.clear();
    await this.load();
  }
}
