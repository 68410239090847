import React, { SVGProps } from "react";

export const PhoneFailImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width="84" height="147" viewBox="0 0 84 147" fill="none" {...props}>
    <path fill="#F4F4F4" d="M82 125H1V22h81z" />
    <path d="M1 6a5 5 0 0 1 5-5h71a5 5 0 0 1 5 5v16H1V6zM82 141a5 5 0 0 1-5 5H6a5 5 0 0 1-5-5v-19h81v19z" fill="#fff" />
    <rect x="1.25" y="1.25" width="81.5" height="144.5" rx="5.75" stroke="#4D4D4D" strokeWidth="2.5" />
    <path d="M31 14h22" stroke="#4D4D4D" strokeWidth="2" />
    <circle cx="24" cy="14" r="1" fill="#4D4D4D" />
    <circle cx="41.5" cy="132.5" r="6.5" stroke="#4D4D4D" strokeWidth="2" />
    <mask id="x09nh9hhla" fill="#fff">
      <path d="M67 72.5C67 86.583 55.583 98 41.5 98S16 86.583 16 72.5 27.417 47 41.5 47 67 58.417 67 72.5zm-48.129 0c0 12.497 10.132 22.629 22.629 22.629 12.497 0 22.629-10.132 22.629-22.629 0-12.497-10.132-22.629-22.629-22.629-12.497 0-22.628 10.132-22.628 22.629z" />
    </mask>
    <path
      d="M67 72.5C67 86.583 55.583 98 41.5 98S16 86.583 16 72.5 27.417 47 41.5 47 67 58.417 67 72.5zm-48.129 0c0 12.497 10.132 22.629 22.629 22.629 12.497 0 22.629-10.132 22.629-22.629 0-12.497-10.132-22.629-22.629-22.629-12.497 0-22.628 10.132-22.628 22.629z"
      stroke="#F24164"
      strokeWidth="6"
      mask="url(#x09nh9hhla)"
    />
    <path
      d="M50.517 63.839 32.839 81.516M50.517 81.517 32.839 63.839"
      stroke="#F24164"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
