import React, { HTMLAttributes, useState } from "react";
import styled, { css } from "styled-components";
import { theme } from "../../../styles/theme";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { cardManager } from "../../../../../shared/core/service/services";
import { Card, CardStatus } from "../../../../../shared/domains/cards/card";
import { OverrideCardData } from "../../../../../shared/domains/cards/card-service";
import { useAsyncEffect } from "../../../../../shared/utils/utils";
import { MainColorSpinner } from "../../../common/spinner";
import { AnimatedSkeletonStyle } from "../../../styles/animated-skeleton";
import { UIConstants } from "../../../styles/uiConstants";
import { getCardConfigurations } from "./card-configurations";

interface CardTileProps extends HTMLAttributes<HTMLButtonElement> {
  card: Card;
  isActive?: boolean;
  loading?: boolean;
  showDetails?: boolean;
  sensitiveData?: OverrideCardData | null;
}

export function CardMask({ card, cardImageOverride }: { card: Card; cardImageOverride?: string | null }) {
  const [cardImage, setCardImage] = useState("");
  const { statusColor } = getCardConfigurations(card.status);

  useAsyncEffect(async () => {
    const image = await cardManager.getCardImage(card.id);
    setCardImage(image);
  }, []);

  return (
    <svg width="291" height="170" viewBox="0 0 291 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.3817 4.33843C63.0883 2.73525 63.9416 1.93367 64.923 1.36139C65.7933 0.853902 66.7353 0.480844 67.7171 0.25485C68.8242 0 69.995 0 72.3365 0H271.8C278.521 0 281.881 0 284.448 1.30792C286.706 2.4584 288.542 4.29417 289.692 6.55211C291 9.11905 291 12.4794 291 19.2V150.8C291 157.521 291 160.881 289.692 163.448C288.542 165.706 286.706 167.542 284.448 168.692C281.881 170 278.521 170 271.8 170H19.2C12.4794 170 9.11905 170 6.55211 168.692C4.29417 167.542 2.4584 165.706 1.30792 163.448C0 160.881 0 157.521 0 150.8V68.9221C0 66.3709 0 65.0952 0.298606 63.9009C0.563293 62.8422 0.999487 61.8339 1.58989 60.9162C2.25595 59.8808 3.18569 59.0074 5.04519 57.2606L61.3817 4.33843Z"
          fill="white"
        />
      </mask>
      {(cardImageOverride || cardImage) && (
        <image
          mask="url(#mask)"
          xlinkHref={`data:image/png;base64,${cardImageOverride || cardImage}`}
          x="0"
          y="0"
          width="291"
          height="170"
          preserveAspectRatio="xMidYMid slice"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13C0 5.8203 5.8203 0 13 0H51.4088C53.2214 0 54.0991 2.21798 52.7774 3.45836L3.36864 49.8275C2.09157 51.026 0 50.1205 0 48.3691V13Z"
        fill={statusColor}
      />
    </svg>
  );
}

export function CardTile(props: CardTileProps) {
  const { card, isActive, loading, sensitiveData, showDetails, ...rest } = props;
  const { formatMessage, formatDate } = useIntl();
  const { statusText, cornerIcon } = getCardConfigurations(card.status);

  const isVirtual = card.virtual;
  const cardImageOverride = isActive ? sensitiveData?.base64Image : null;

  return (
    <Tile isActive={isActive} {...rest}>
      <CardMask card={card} cardImageOverride={cardImageOverride} />
      <StatusContainer isBlocked={card.status === CardStatus.Blocked}>
        <StatusLabel>
          {isVirtual ? formatMessage("card.virtual") : statusText ? formatMessage(statusText) : null}
        </StatusLabel>
      </StatusContainer>
      {showDetails && !cardImageOverride && (
        <CardDetails>
          {(sensitiveData?.panDisplay || card.panDisplay) && (
            <CardDetailSection>
              <CardDetailLabel>{formatMessage("cardsList.details.number")}</CardDetailLabel>
              <CardPan>{sensitiveData?.panDisplay || card.panDisplay}</CardPan>
            </CardDetailSection>
          )}
          <CardDetailBottom>
            {(sensitiveData?.expirationDate || card.expirationDate) && (
              <CardDetailSection>
                <CardDetailLabel>{formatMessage("cardsList.details.date")}</CardDetailLabel>
                <CardDate>
                  {formatDate(sensitiveData?.expirationDate || card.expirationDate, {
                    month: "2-digit",
                    year: "2-digit",
                    timeZone: "UTC",
                  })}
                </CardDate>
              </CardDetailSection>
            )}
            {sensitiveData?.cvv && (
              <CardDetailSection style={{ marginLeft: 14 }}>
                <CardDetailLabel>{formatMessage("cardsList.details.cvv")}</CardDetailLabel>
                <CardDate>{sensitiveData.cvv}</CardDate>
              </CardDetailSection>
            )}
          </CardDetailBottom>
        </CardDetails>
      )}
      {(cornerIcon || loading) && <CornerIconWrapper>{loading ? <Spinner /> : cornerIcon}</CornerIconWrapper>}
    </Tile>
  );
}

const Tile = styled.button<{ isActive?: boolean }>`
  position: relative;
  padding: 0;
  width: ${UIConstants.CARD_TILE_WIDTH_NOT_MOBILE}px;
  min-height: 170px;
  border-radius: 13px;
  border: none;
  overflow: hidden;
  transition: transform ease-out 0.2s;
  background-color: transparent;
  will-change: transform;

  :hover {
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      transform: scale(1.1);
    `}

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CARD_TILE_WIDTH_MOBILE}px;
  }
`;

export const SkeletonCardTile = styled(Tile)`
  ${AnimatedSkeletonStyle};
`;

const StatusContainer = styled.div<{ isBlocked: boolean }>`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 12px;
    opacity: ${({ isBlocked }) => (isBlocked ? 1 : 0.6)};
    background-color: ${({ isBlocked }) => (isBlocked ? theme.colors.error[500] : "#626262")};
    z-index: -1;
  }
`;

const StatusLabel = styled.span`
  ${theme.mediumText}
  font-size: 0.75rem;
  color: #ffffff;
`;

const CornerIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
`;

const Spinner = styled(MainColorSpinner)`
  height: 20px;
  width: 20px;
`;

const CardDetails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
`;

const CardDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const CardDetailBottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const CardDetailLabel = styled.span`
  ${theme.mediumText}
  display: block;
  font-size: 10px;
  color: ${theme.colors.gray[500]};
  letter-spacing: 1px;
`;

const CardPan = styled.span`
  ${theme.boldText}
  font-size: 1rem;
  letter-spacing: 3px;
  color: #ffffff;
`;

const CardDate = styled.span`
  ${theme.boldText}
  font-size: 10px;
  letter-spacing: 0.5px;
  color: #ffffff;
`;
