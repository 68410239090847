import { BillCategory, BillIssuer } from "../../../../shared/domains/bills/bill";
import { PaybillState, usePaybillMachine } from "../../../machine/paybill-machine";
import React, { useEffect, useMemo } from "react";

import { BILL_ISSUER_MODAL_ID } from "../../../core/modal/modal-id";
import { BillConfirmationView } from "./components/bill-confirmation-view";
import { BillIssuerForm } from "./components/bill-issuer-form";
import { MainColorSpinner } from "../../common/spinner";
import { Modal } from "../../common/modal/modal";
import { PaybillContext } from "../../../machine/paybill-machine-provider";
import { PincodeKeyboard } from "../../common/keyboard/pincode-keyboard";
import { PincodeState } from "../../../machine/keyboard-machine-type";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { TransferSummary } from "../transfer/components/transfer-summary";
import { billCategoryIcon } from "./components/bill-category-icons";
import { isAccountBlocked } from "../../../../shared/domains/pincode/pincode-error";
import { redirectionHandledOnAccountBlocked } from "../../../../shared/domains/account/account";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { useHistory } from "react-router-dom";
import { useIntl } from "../../../../shared/core/i18n/use-intl";

export interface BillIssuerModalProps {
  issuer: BillIssuer;
  topLevelCategory?: BillCategory;
  color: string;
}

export const BillIssuerModal = (props: BillIssuerModalProps) => {
  const { issuer, topLevelCategory, color } = props;
  const logoUrl = topLevelCategory
    ? billCategoryIcon[topLevelCategory?.id ?? ""] || billCategoryIcon.default
    : issuer.logoUrl;
  const issuerLogo = issuer.logoUrl || logoUrl;
  return (
    <RoundedModalContainer closeButton id={BILL_ISSUER_MODAL_ID}>
      <Container>
        <Header>
          <Logo style={topLevelCategory ? { backgroundColor: color, padding: 5 } : {}} src={logoUrl} />
          <TitleContainer>
            <Title>{topLevelCategory?.name || issuer.name}</Title>
            {!!topLevelCategory?.name && <SubTitle>{issuer.name}</SubTitle>}
          </TitleContainer>
        </Header>
        <BillIssuerContent
          issuer={{ ...issuer, logoUrl: issuerLogo }}
          styledIssuerLogo={!issuer.logoUrl}
          color={color}
        />
      </Container>
    </RoundedModalContainer>
  );
};

const BillIssuerContent = (props: { issuer: BillIssuer; styledIssuerLogo?: boolean; color?: string }) => {
  const { issuer, styledIssuerLogo, color } = props;
  const history = useHistory();
  const paybillMachine = usePaybillMachine();
  const { state, submitPincode, userConfirmPayment, context } = paybillMachine;
  const closeModal = () => Modal.dismiss(BILL_ISSUER_MODAL_ID);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (redirectionHandledOnAccountBlocked(context.error, history.push)) {
      closeModal();
    }
  }, [context.error, history.push]);

  const renderPaybillMachineState = useMemo(() => {
    const errorMessage = !isAccountBlocked(context.error) ? context.error : undefined;
    switch (state) {
      case PaybillState.FillingForm: {
        return <BillIssuerForm issuer={issuer} />;
      }
      case PaybillState.UserConfirmPayment: {
        return (
          <BillConfirmationView
            amount={context.transaction?.amount}
            transactionDetails={context.transaction?.detailedAccounting}
            issuer={issuer}
            onSubmit={userConfirmPayment}
          />
        );
      }
      case PincodeState.PromptingKeyboard: {
        const keyboard = context.keyboard!;
        return (
          <PincodeKeyboard
            keyboard={keyboard}
            onSubmit={submitPincode}
            title={formatMessage("pincodeConfirmation.label")}
            errorMessage={errorMessage}
          />
        );
      }
      case PaybillState.Done: {
        const billPaymentResult = context.billPaymentResult!;
        return (
          <SummaryContainer>
            <StyledTransferSummary
              billIssuer={issuer}
              title={formatMessage("transferSummary.summaryTitle")}
              subtitle={formatMessage("transferSummary.accountSummarySubtitle")}
              middleStepLabel={formatMessage("transferSummary.summaryMiddleStep")}
              transaction={billPaymentResult.metadata.transaction}
              onDone={closeModal}
              styledIssuerLogo={styledIssuerLogo}
              color={color}
            />
          </SummaryContainer>
        );
      }
      default: {
        return <MainColorSpinner style={{ margin: "auto" }} size="50px" />;
      }
    }
  }, [state]);

  return <PaybillContext.Provider value={paybillMachine}>{renderPaybillMachineState}</PaybillContext.Provider>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 530px;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 9px;
  object-fit: contain;
  margin-right: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const TitleContainer = styled.div`
  flex: 1;
`;

const Title = styled.h3`
  ${theme.boldText};
  color: #000000;
  margin: 0;
`;

const SubTitle = styled.span`
  ${theme.text};
  font-size: 0.8125rem;
  color: #b1b1b1;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  margin-top: -20px;
`;

const StyledTransferSummary = styled(TransferSummary)`
  height: 100%;
  margin-bottom: 0;
`;
