import { FormInput, FormInputType, FormPincodeInput } from "./form-input-types";

export interface ExtraStepFormInput {
  pincodeFormInput: (FormPincodeInput & { hierarchy: string }) | null;
}

export function parseExtraStepFormInput(input: (FormInput & { hierarchy: string }) | null): ExtraStepFormInput {
  let pincodeFormInput = null;

  function findConfigurationRecursively(input: any) {
    if (input.type === "COLLECTION") {
      return input.inputs.map((it: FormInput) => {
        findConfigurationRecursively(it);
      }, []);
    } else if (input.type === FormInputType.Pincode) {
      pincodeFormInput = input;
    }
    return [];
  }

  if (input !== null) {
    findConfigurationRecursively(input);
  }

  return { pincodeFormInput };
}
