import React, { CSSProperties, HTMLAttributes } from "react";

import { StyledTaggedSpan } from "@betomorrow/styled-tagged-text";
import { theme } from "../../styles/theme";

interface MarkdownTextProps extends HTMLAttributes<HTMLSpanElement> {
  children?: string;
}

export const MarkdownText = (props: MarkdownTextProps) => {
  const { children, ...otherProps } = props;
  const tagStyles: Record<string, CSSProperties> = {
    emph: { color: theme.mainColor() },
    b: {
      color: "#000000",
      ...theme.boldText,
    },
  };

  return (
    <StyledTaggedSpan {...otherProps} tagsStyle={tagStyles}>
      {children}
    </StyledTaggedSpan>
  );
};
