import { transferManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";

export const useCustomerInstruction = () => {
  const paymentNetworks = useObservable(transferManager.paymentNetworks);

  const getPaymentNetworks = async () => transferManager.getPaymentNetworks();

  return {
    paymentNetworks,
    getPaymentNetworks,
  };
};
