import { BaseUrl } from "../BaseUrl";
import { cardStatusLabels } from "./card-filter-labels";

export enum CardType {
  DebitCard = "DEBIT_CARD",
}

export enum CardStatus {
  New = "NEW",
  Pending = "PENDING",
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Blocked = "BLOCKED",
  Opposed = "OPPOSED",
  Removed = "REMOVED",
  Cancelled = "CANCELLED",
}

export const cardsStatusOptions = [
  {
    value: "ALL",
    label: cardStatusLabels.ALL,
  },
  {
    value: CardStatus.New,
    label: cardStatusLabels[CardStatus.New],
  },
  {
    value: CardStatus.Pending,
    label: cardStatusLabels[CardStatus.Pending],
  },
  {
    value: CardStatus.Active,
    label: cardStatusLabels[CardStatus.Active],
  },
  {
    value: CardStatus.Blocked,
    label: cardStatusLabels[CardStatus.Blocked],
  },
  {
    value: CardStatus.Opposed,
    label: cardStatusLabels[CardStatus.Opposed],
  },
];

export enum CardPendingOperationType {
  Refabrication = "refabrication",
  CodeReissuing = "card_code_reissuing",
  Opposition = "card_opposition",
  Blocking = "card_blocking",
  Unblocking = "card_unblocking",
  Activation = "card_activation",
  Creation = "card_creation",
  FeaturesUpdate = "card_features_update",
  LimitsUpdate = "card_limits_update",
}

export enum CardFeatureType {
  Nfc = "NFC",
  CashOperation = "CASH_OPERATION",
  ForeignPayment = "FOREIGN_PAYMENT",
  OnlinePayment = "ONLINE_PAYMENT",
  EurWithdrawal = "EUR_WITHDRAWAL",
  ForeignWithdrawal = "FOREIGN_WITHDRAWAL",
  PaymentAuthorized = "PAYMENT_AUTHORIZED",
  EurPayment = "EUR_PAYMENT",
  ForeignAll = "FOREIGN_ALL",
  ForeignOnlinePayment = "FOREIGN_ONLINE_PAYMENT",
}

export interface CardFeature {
  id: CardFeatureType;
  enabled: boolean;
}

export interface CardPendingOperation {
  operationType: CardPendingOperationType;
  featureId?: CardFeatureType;
}

export interface Card extends BaseUrl {
  id: string;
  accountId: string;
  currency?: string;
  productId: string;
  productName: string;
  type: CardType;
  status: CardStatus;
  embossedName?: string;
  embossedReference?: string;
  panDisplay?: string;
  expirationDate?: string;
  features?: CardFeature[];
  image?: string;
  pendingManagementOperation?: CardPendingOperation;
  limits: any;
  virtual?: boolean;
}

export enum OpposeCardReason {
  Lost = "LOST",
  Stolen = "STOLEN",
  NotDistributed = "NOT_DISTRIBUTED",
}

export enum CardRefabricateMotive {
  Damaged = "DAMAGED",
  Stolen = "STOLEN",
  Lost = "LOST",
  Abuse = "ABUSE",
}

export const motivesMapping = [
  {
    key: CardRefabricateMotive.Damaged,
  },
];

export const opposedMotivesMapping = [
  {
    key: CardRefabricateMotive.Stolen,
  },
  {
    key: CardRefabricateMotive.Lost,
  },
  {
    key: CardRefabricateMotive.Abuse,
  },
];
