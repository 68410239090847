export const anonimyzeIban = (iban: string) => {
  const splittedIban = iban.split(" ");
  const length = splittedIban.length;
  const anonymizedIban = splittedIban.map((bloc, i) => {
    if (i === 0 || i === length - 1) {
      return bloc;
    } else {
      return bloc.replace(/[0-9A-Z]/g, "•");
    }
  });

  return anonymizedIban.join(" ");
};
