export function colorWithOpacity(color: string, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

const colorRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export function hexToRgb(hex: string) {
  const result = colorRegex.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgbToHex({ r, g, b }: { r: number; g: number; b: number }) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function componentToHex(c: number) {
  let hex = c.toString(16);
  if (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex;
}

export const lighten = (color: string, factor = 0.1) => {
  const rgb = hexToRgb(color);
  if (!rgb) {
    throw new Error("Can't convert color");
  }
  const newColor = {
    r: Math.round(Math.min(255, rgb.r + 255 * factor)),
    g: Math.round(Math.min(255, rgb.g + 255 * factor)),
    b: Math.round(Math.min(255, rgb.b + 255 * factor)),
  };
  return rgbToHex(newColor);
};

export const darken = (color: string, factor = 0.1) => {
  const rgb = hexToRgb(color);
  if (!rgb) {
    throw new Error("Can't convert color");
  }
  const newColor = {
    r: Math.round(Math.max(0, rgb.r - 255 * factor)),
    g: Math.round(Math.max(0, rgb.g - 255 * factor)),
    b: Math.round(Math.max(0, rgb.b - 255 * factor)),
  };
  return rgbToHex(newColor);
};
