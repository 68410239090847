import { logger } from "../../core/logging/logger";
import { ConnectedServiceDomainApiService } from "../../core/net/connected-service-domain-api-service";
import { PincodeSubmission } from "../pincode/pincode";
import { AuthenticationsMethod, verifyAuthenticationResponse } from "./authentications-sessions";

export default class AuthenticationsSessionsService {
  public constructor(private serviceDomainApiService: ConnectedServiceDomainApiService) {}

  public async getAuthenticationSession(sessionReference: string) {
    try {
      const response = await this.serviceDomainApiService.instance.get(`/authentications/sessions/${sessionReference}`);
      return response.data;
    } catch (e) {
      logger.debug("AuthenticationsSessionService", "Failed to get authentication session", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
  public async sendAuthentication(sessionToken: string, method = AuthenticationsMethod.SMS) {
    try {
      const response = await this.serviceDomainApiService.instance.post(
        `/authentications/sessions/${sessionToken}/methods/${method}/send`,
      );
      return response.data;
    } catch (e) {
      logger.debug("AuthenticationsSessionService", "Failed to send authentication SMS", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
  public async verifyAuthentication(
    sessionToken: string,
    code: string | PincodeSubmission,
    method = AuthenticationsMethod.SMS,
  ): Promise<verifyAuthenticationResponse> {
    try {
      const response = await this.serviceDomainApiService.instance.post(
        `/authentications/sessions/${sessionToken}/methods/${method}/verify`,
        { ...(method === AuthenticationsMethod.PINCODE ? { pincode: code } : { code }) },
      );
      return response.data;
    } catch (e) {
      logger.debug("AuthenticationsSessionService", "Failed to send authentication SMS", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async cancelAuthentication(sessionToken: string) {
    try {
      await this.serviceDomainApiService.instance.post(`/authentications/sessions/${sessionToken}/cancel`);
    } catch (error) {
      logger.debug("ServiceDomainApiService", "Failed to cancel authentication", error);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
}
