export class TranslationsUpdateError extends Error {
  public constructor(message?: string) {
    super(message);
  }
}

export class TranslationsNotLoadedError extends Error {
  public constructor(message?: string) {
    super(message);
  }
}
