import { AxiosRequestConfig } from "axios";
import { Config } from "../../config/config";
import { Interceptor } from "./interceptor";

export const addBaseUrlInterceptor: (apiUrl?: string) => Interceptor<AxiosRequestConfig> = (
  apiUrl = Config.API_URL,
) => ({
  onFulfilled: (config) => {
    if (config.url?.startsWith("https://")) {
      return config;
    } else {
      return {
        ...config,
        url: `${apiUrl}${config.url}`,
      };
    }
  },
});
