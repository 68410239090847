import { useState } from "react";
import { logger } from "../../../shared/core/logging/logger";
import { transactionCodeManager } from "../../../shared/core/service/services";
import { useObservable } from "../../../shared/utils/observable";
import { useAsyncEffect } from "../../../shared/utils/utils";

export const useTransactionCodes = () => {
  const transactionCodes = useObservable(transactionCodeManager.transactionCodes);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await transactionCodeManager.refresh();
    } catch (e) {
      logger.debug("client fetch error", e);
    } finally {
      setLoading(false);
    }
  }, [transactionCodeManager]);
  return { transactionCodes, loading };
};
