import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Acceptor } from "../../../../../shared/domains/acceptors/acceptor";
import { formatPhoneNumber } from "../../../../../shared/utils/phone-number";
import { useAcceptorCategory } from "../../../../domain/acceptors/use-acceptor-category";
import { useRTL } from "../../../../domain/language/use-rtl";
import { Avatar } from "../../../common/avatar/avatar";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { DocumentIcon } from "../../../common/svg/document-icon";
import { PenIcon } from "../../../common/svg/pen-icon";
import { PhoneIcon } from "../../../common/svg/phone-icon";
import { theme } from "../../../styles/theme";

export interface AcceptorRowProps {
  acceptor: Acceptor;
  onClick?: () => void;
}

export const AcceptorRow = (props: AcceptorRowProps) => {
  const { acceptor, onClick } = props;
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const { name, contactphone, email } = acceptor;
  const category = useAcceptorCategory(acceptor);
  const { formatMessage } = useIntl();
  const { isRTL } = useRTL();

  return (
    <Card onClick={() => setShowMoreDetails((last) => !last)}>
      <MainDetails>
        <AcceptorWrapper>
          <Avatar size={40} name={name} />
          <AcceptorDetailContainer $isRTL={isRTL}>
            <AcceptorName>{name}</AcceptorName>
            {category && <AcceptorDetail>{category}</AcceptorDetail>}
          </AcceptorDetailContainer>
        </AcceptorWrapper>
        {!showMoreDetails && (
          <ContactIcons>
            {contactphone && (
              <IconContainer $isRTL={isRTL}>
                <PhoneIcon />
              </IconContainer>
            )}
            {email && (
              <IconContainer $isRTL={isRTL}>
                <PenIcon />
              </IconContainer>
            )}
          </ContactIcons>
        )}
      </MainDetails>
      {showMoreDetails && (
        <MoreDetailsContainer>
          {contactphone && <DetailRow type="phone" label={formatPhoneNumber(contactphone)} />}
          {email && <DetailRow type="email" label={email} />}
          <SelectionButton size="XS" onClick={onClick}>
            {formatMessage("acceptorSelection.selectionButton")}
          </SelectionButton>
        </MoreDetailsContainer>
      )}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 18px 0px;
  border-bottom: 1px solid #ededed;
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: auto;
  }
`;

const MainDetails = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const AcceptorWrapper = styled.div`
  display: flex;
`;

const AcceptorName = styled.span`
  font-size: 1rem;
  ${theme.boldText};
  color: #000000;
`;

const AcceptorDetailContainer = styled.div<{ $isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.$isRTL ? 0 : 15)}px;
  margin-right: ${(props) => (props.$isRTL ? 15 : 0)}px;
  justify-content: center;
`;

const AcceptorDetail = styled.span`
  ${theme.mediumText};
  font-size: 0.8125rem;
  color: #aaaaaa;
`;

const ContactIcons = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const IconContainer = styled.div<{ $isRTL: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin: ${(props) => (props.$isRTL ? "1px 15px 0 0" : "1px 0 0 15px")};
  padding: 8px;
  background-color: #ededed;
`;

const MoreDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

const SelectionButton = styled(PrimaryButton)`
  margin-top: 15px;
  align-self: center;
  padding: 10px 20px;
`;

const iconProps = { fill: "#b1b1b1", width: 20, height: 20 };
const acceptorInfoType = {
  phone: { icon: <PhoneIcon {...iconProps} /> },
  email: { icon: <DocumentIcon {...iconProps} /> },
};

interface DetailRowProps {
  type: "phone" | "email";
  label: string;
}

const DetailRow = (props: DetailRowProps) => {
  const { type, label } = props;
  const { isRTL } = useRTL();

  return (
    <Row onClick={(e) => e.stopPropagation()}>
      <IconWrapper $isRTL={isRTL}>{acceptorInfoType[type].icon}</IconWrapper>
      <AcceptorInformation>{label}</AcceptorInformation>
    </Row>
  );
};
const Row = styled.div`
  cursor: text;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 35px;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
const IconWrapper = styled.div<{ $isRTL: boolean }>`
  padding-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  padding-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
`;

const AcceptorInformation = styled.span`
  ${theme.mediumText};
  font-size: 0.8125rem;
  color: #b1b1b1;
  direction: ltr;
`;
