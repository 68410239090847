import { useMemo } from "react";
import { acceptorCategoriesManager } from "../../../shared/core/service/services";
import { Acceptor } from "../../../shared/domains/acceptors/acceptor";
import { useObservable } from "../../../shared/utils/observable";
import { useAsyncEffect } from "../../../shared/utils/utils";

export const useAcceptorCategory = (acceptor: Acceptor | null) => {
  const acceptorCategories = useObservable(acceptorCategoriesManager.acceptorCategories);

  useAsyncEffect(async () => {
    if (acceptorCategories.length === 0) {
      await acceptorCategoriesManager.refresh();
    }
  }, [acceptorCategories]);

  const categoryLabel = useMemo(() => {
    if (!acceptor) {
      return "";
    }
    const category = acceptorCategories.find((c) => c.id === acceptor.category);
    return category ? category.label : null;
  }, [acceptor, acceptorCategories]);

  return categoryLabel;
};
