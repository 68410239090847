import React, { useRef } from "react";

import { AccountingEntry } from "../../../../../shared/domains/accounting-transaction/accounting-entry";
import { Amount } from "../../../../../shared/core/amount/amount";
import { BillIssuer } from "../../../../../shared/domains/bills/bill";
import { MarkdownText } from "../../../common/forms/markdown-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { SideEntryLabel } from "../../../common/transaction/side-entry-label";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";

interface BillConfirmationViewProps {
  issuer?: BillIssuer;
  transactionDetails?: AccountingEntry[];
  amount?: Amount;
  onSubmit?: () => void;
}

export const BillConfirmationView = (props: BillConfirmationViewProps) => {
  const { issuer, transactionDetails, amount, onSubmit } = props;
  const { formatMessage, formatAmount } = useIntl();

  return (
    <Wrapper>
      <DetailsWrapper>
        <Details>
          <SummaryText>
            {formatMessage("billFormScreen.confirmationMessage", {
              amount: formatAmount(amount, true),
              issuer: issuer?.name,
            })}
          </SummaryText>
          {transactionDetails &&
            transactionDetails
              .filter((entry) => entry.type !== "TRANSFER")
              .map((entry, index) => (
                <Name key={`${entry.type}_${index}`}>
                  <SideEntryLabel entry={entry} translationKeyPrefix="billConfirmationScreen" />
                </Name>
              ))}
        </Details>
      </DetailsWrapper>
      <ButtonContainer>
        <PrimaryButton size="M" style={{ marginBottom: 15 }} onClick={onSubmit}>
          {formatMessage("billFormScreen.confirmButton")}
        </PrimaryButton>
      </ButtonContainer>
    </Wrapper>
  );
};

const SummaryText = styled(MarkdownText)`
  font-size: 15px;
  text-align: center;
  color: #aaaaaa;
  width: 80%;
  margin-top: 20;
  margin-bottom: 20;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 20;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled.div`
  ${theme.mediumText};
  font-size: 0.875rem;
  color: #aaaaaa;
  white-space: pre-line;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
`;
