import { AccountStatus } from "./account";

export const statusLabels = {
  [AccountStatus.Opened]: "accountStatus.opened",
  [AccountStatus.Closed]: "accountStatus.closed",
  [AccountStatus.Blocked]: "accountStatus.blocked",
  [AccountStatus.DebitBlocked]: "accountStatus.debitBlocked",
  [AccountStatus.CreditBlocked]: "accountStatus.creditBlocked",
};

export const statusColors = {
  [AccountStatus.Opened]: "transparent",
  [AccountStatus.Closed]: "#ff4242",
  [AccountStatus.Blocked]: "#ff4242",
  [AccountStatus.DebitBlocked]: "#ff6b21",
  [AccountStatus.CreditBlocked]: "#ff6b21",
};
