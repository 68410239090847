import React, { SVGProps } from "react";

export function CrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" {...props}>
      <g fillRule="evenodd" fill={props.fill ?? "#fff"}>
        <path
          d="M175.548 302.605c.586-.585 1.536-.585 2.122 0l9.663 9.664 9.664-9.664c.586-.585 1.536-.585 2.121 0l.943.943c.586.586.586 1.536 0 2.122l-9.664 9.663 9.664 9.664c.586.586.586 1.536 0 2.121l-.943.943c-.585.586-1.535.586-2.12 0l-9.666-9.664-9.662 9.664c-.586.586-1.536.586-2.122 0l-.943-.943c-.585-.585-.585-1.535 0-2.12l9.663-9.665-9.663-9.663c-.585-.586-.585-1.536 0-2.122l.943-.943z"
          transform="translate(-174 -302)"
        />
      </g>
    </svg>
  );
}
