import React, { SVGProps } from "react";

export function RechargeCardIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 16 16" width="16" height="16" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h15.714v15.714H0z" />
        <path
          d="M3.681 3.222c-.865 0-1.566.701-1.566 1.566v1.044H13.6V4.788a1.566 1.566 0 0 0-1.566-1.566H3.681zM13.6 6.876H2.115v4.176a1.566 1.566 0 0 0 1.566 1.565h8.352a1.566 1.566 0 0 0 1.566-1.565V6.876zm-8.946 4.243h1.793a.792.792 0 0 0 .79-.79.792.792 0 0 0-.79-.79H4.653a.792.792 0 0 0-.79.79.792.792 0 0 0 .79.79z"
          fill={props.fill}
        />
      </g>
    </svg>
  );
}
