import React from "react";
import { AccountingTransactionType } from "../../../shared/domains/accounting-transaction/accounting-transaction-type";
import { TransactionReceivedIcon } from "./svg/transaction-received-icon";
import { TransactionSentIcon } from "./svg/transaction-sent-icon";

export const TransactionIcon: React.FC<{
  type: AccountingTransactionType | "CASH_TRANSFER";
  positive?: boolean;
  className?: string;
}> = ({ type, positive, className }) =>
  type === AccountingTransactionType.Transaction && positive ? (
    <TransactionReceivedIcon className={className} width={40} height={40} />
  ) : (
    <TransactionSentIcon className={className} width={40} height={40} />
  );
