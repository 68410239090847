import React, { useEffect } from "react";
import { Recipient, TransferRecipient } from "../../../../../shared/domains/recipients/recipient";
import { TransferState, useTransferMachine } from "../../../../machine/transfer-machine";
import { Subtitle, TransferSummary } from "./transfer-summary";

import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { redirectionHandledOnAccountBlocked } from "../../../../../shared/domains/account/account";
import {
  isAccountBlocked,
  isRecipientNotFoundByInvalidPhoneErrorResponse,
} from "../../../../../shared/domains/pincode/pincode-error";
import { TransferMode } from "../../../../../shared/domains/transactions/cash-transfer/transfer-mode";
import { TransactionRequest } from "../../../../../shared/domains/transactions/transaction-request";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { ErrorMessage } from "../../../common/error-message";
import { PincodeKeyboard } from "../../../common/keyboard/pincode-keyboard";
import { MainColorSpinner } from "../../../common/spinner";
import { AmountSelection } from "../../../common/transaction/amount-selection";
import { theme } from "../../../styles/theme";

export interface SimpleTransferModalProps {
  recipient?: Recipient;
  closeModal?: () => void;
}

const loadingState = [TransferState.RequestingTransfer, PincodeState.PincodeConfirmation];

export const SimpleTransferModal = (props: SimpleTransferModalProps) => {
  const { closeModal } = props;
  const { state, selectAmount, submitPincode, context } = useTransferMachine(true);
  const history = useHistory();

  useEffect(() => {
    if (redirectionHandledOnAccountBlocked(context.error, history.push)) {
      closeModal?.();
    }
  }, [context.error, history.push, closeModal]);

  const { formatMessage } = useIntl();
  const errorPhoneMessage = formatMessage("simpleTransfer.invalidPhoneNumber");
  const errorMessage = isRecipientNotFoundByInvalidPhoneErrorResponse(context.error)
    ? errorPhoneMessage
    : !isAccountBlocked(context.error)
      ? context.error
      : undefined;
  switch (state) {
    case TransferState.SelectingAmount:
    case TransferState.RequestingTransfer:
    case TransferState.RequestingTransferError:
    case PincodeState.PincodeConfirmation:
    case TransferState.ConfirmTransferError: {
      return (
        <>
          <Centered>{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}</Centered>
          <StyledAmountSelection
            isP2PSimpleTransfer
            recipient={{} as TransferRecipient}
            submitAmount={selectAmount}
            loading={loadingState.includes(state)}
          />
        </>
      );
    }
    case PincodeState.FetchKeyboardAfterError:
    case PincodeState.PromptingKeyboard: {
      const keyboard = context.keyboard!;
      return (
        <PincodeKeyboard
          keyboard={keyboard}
          onSubmit={submitPincode}
          title={formatMessage("pincodeConfirmation.label")}
          errorMessage={errorMessage}
          loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
        />
      );
    }
    case TransferState.Done: {
      const transactionResult = context.transactionResult!;
      const recipient = {
        ...context.recipient,
        name: context.recipient?.name || "",
      };
      return (
        <StyledTransferSummary
          recipient={recipient}
          title={formatMessage("transferSummary.summaryTitle")}
          subtitle={
            context.transferMode === TransferMode.CashTransfer
              ? formatMessage("transferSummary.cashSummarySubtitle", { recipient: "recipient.name" })
              : formatMessage("transferSummary.accountSummarySubtitle")
          }
          middleStepLabel={formatMessage("transferSummary.summaryMiddleStep")}
          transaction={(transactionResult as TransactionRequest).metadata.transaction}
          onDone={closeModal}
          boldSubtitle={context.transferMode !== TransferMode.CashTransfer}
        />
      );
    }
    default: {
      return (
        <LoadingContainer>
          <MainColorSpinner size="30px" />
        </LoadingContainer>
      );
    }
  }
};

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.span`
  font-size: 1.125rem;
  ${theme.boldText};
  text-align: center;
  margin-top: 20px;
`;

const StyledAmountSelection = styled(AmountSelection)`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 50px;
  text-align: center;
`;

const StyledTransferSummary = styled(TransferSummary)<{ boldSubtitle?: boolean }>`
  ${Subtitle} {
    ${(props) => !props.boldSubtitle && theme.text}
  }
`;
