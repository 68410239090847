import React, { SVGProps } from "react";

export const ExpirationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 1.667a8.333 8.333 0 1 0 0 16.666 8.333 8.333 0 0 0 0-16.666zm3.758 11.666-.441.442a.425.425 0 0 1-.592 0l-3.333-3.333a.409.409 0 0 1-.125-.3V5.417c0-.23.186-.417.416-.417h.634c.23 0 .416.187.416.417V9.7l3.025 3.025a.425.425 0 0 1 0 .608z"
      fill="#161616"
      fillRule="evenodd"
    />
  </svg>
);
