import { useEffect, useState } from "react";

export function useWindowWidth(): number {
  const [widowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    const resizeListener = () => {
      setWindowWidth(window.innerWidth);
    };
    resizeListener();
    window.addEventListener("resize", resizeListener, { passive: true });
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return widowWidth;
}
