import React from "react";
import styled from "styled-components";
import { ResponsiveContainer } from "../../common/responsive-container";
import { theme } from "../../styles/theme";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { authExecutor } from "../../../../shared/core/service/services";
import { ErrorIcon } from "../../common/svg/error-icon";

export const ErrorScreen = () => {
  const { formatMessage } = useIntl();

  const handleBackToLogin = async () => {
    try {
      authExecutor.connect();
    } catch (error) {
      console.error("Retry failed:", error);
    }
  };
  return (
    <>
      <Page>
        <MainContainer>
          <ErrorIcon />
          <Subtitle>{formatMessage("errorScreen.subTitle")}</Subtitle>
          <Title>{formatMessage("errorScreen.title")}</Title>
          <ErrorMessage>{formatMessage("errorScreen.message")}</ErrorMessage>
          <ButtonContainer>
            <PrimaryButton size="M" onClick={handleBackToLogin}>
              {formatMessage("errorScreen.button")}
            </PrimaryButton>
          </ButtonContainer>
        </MainContainer>
      </Page>
    </>
  );
};

const BackgroundSrc = require("../../../assets/images/svg/connection-background.svg");

const Page = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: url(${BackgroundSrc}) 100% 0 no-repeat;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Title = styled.h2`
  ${theme.boldText}
  font-size: 1.875rem;
  color: #000;
  margin: 0 0 20px;
`;

const Subtitle = styled.span`
  ${theme.boldText}
  font-size: 1.125rem;
  color: #adadad;
  text-transform: uppercase;
  margin: 40px 0 20px;
  letter-spacing: 2px;
`;
const ErrorMessage = styled.p`
  line-height: 18px;
  font-size: 1.125rem;
  white-space: pre-line;
`;
const ButtonContainer = styled.div`
  margin-top: 40px;
`;
