import { FormInput, FormInputBase } from "../../../../../shared/core/data-forms/form-input-types";
import { useCallback, useContext, useRef, useState } from "react";

import { BILL_ISSUER_MODAL_ID } from "../../../../core/modal/modal-id";
import { BillIssuer } from "../../../../../shared/domains/bills/bill";
import { Modal } from "../../../common/modal/modal";
import { PayBillResult } from "../../../../../shared/domains/bills/bill-service";
import { PaybillContext } from "../../../../machine/paybill-machine-provider";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { billManager } from "../../../../../shared/core/service/services";
import { collectValues } from "../../../../../shared/utils/form";
import { logger } from "../../../../../shared/core/logging/logger";
import { redirectionHandledOnAccountBlocked } from "../../../../../shared/domains/account/account";
import { useAsyncEffect } from "../../../../../shared/utils/utils";
import { useHistory } from "react-router-dom";

export const usePaybillFlow = (
  issuer: BillIssuer,
  onSaveReference?: (issuer: BillIssuer, newReference: { label: string; value: string }) => void,
) => {
  const history = useHistory();
  const [inputs, setInputs] = useState<FormInput[]>([]);
  const [latestInputs, setLatestInputs] = useState<FormInput[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const sessionId = useRef("");
  const { startPaybillConfirmation, context, state } = useContext(PaybillContext);

  const setupNewInputs = useCallback(
    (result: PayBillResult) => {
      if (result.done) {
        startPaybillConfirmation(
          issuer,
          sessionId.current,
          result.data.metadata.confirmationMode,
          result.data.metadata.transaction,
          result.data.data,
        );
      } else {
        setInputs((inputs) => [...inputs, ...latestInputs]);
        setLatestInputs(result.data.inputs);
      }
    },
    [issuer, latestInputs, startPaybillConfirmation],
  );

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const { paybillSessionId } = await billManager.initPayBill(issuer.id);
      sessionId.current = paybillSessionId;
      saveBillReference(issuer, inputs, onSaveReference);
      const result = await billManager.payBill(sessionId.current, {});
      setupNewInputs(result);
    } catch (error) {
      if (redirectionHandledOnAccountBlocked(error, history.push)) {
        logger.debug("Paybill init error ", error);
        Modal.dismiss(BILL_ISSUER_MODAL_ID);
      } else {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const sendInputs = useCallback(
    async (inputs: FormInput[]) => {
      setError(null);
      setLoading(true);
      try {
        saveBillReference(issuer, inputs, onSaveReference);
        const result = await billManager.payBill(sessionId.current, collectValues(inputs));
        setupNewInputs(result);
      } catch (error) {
        if (redirectionHandledOnAccountBlocked(error, history.push)) {
          logger.debug("Paybill error ", error);
          Modal.dismiss(BILL_ISSUER_MODAL_ID);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    },
    [history.push, issuer, onSaveReference, setupNewInputs],
  );

  const updateInputValue = useCallback(
    function <T, U extends FormInputBase<T>>(input: U, value: T) {
      const index = latestInputs.findIndex((i) => i.id === input.id);
      setLatestInputs((latestInputs) => [
        ...latestInputs.slice(0, index),
        { ...input, value } as any,
        ...latestInputs.slice(index + 1),
      ]);
    },
    [latestInputs],
  );

  return {
    inputs,
    latestInputs,
    loading: loading || state === PincodeState.PincodeConfirmation,
    error: error || context.error,
    sendInputs,
    updateInputValue,
  };
};

function saveBillReference(
  issuer: BillIssuer,
  inputs: FormInput[],
  onSaveReference?: (issuer: BillIssuer, newReference: { label: string; value: string }) => void,
) {
  const referenceInput = inputs.find((input) => input.id === "billId");

  if (referenceInput && !isReferenceAlreadySaved(referenceInput, issuer)) {
    const referenceName = referenceInput.label ? referenceInput.label.toLowerCase() : referenceInput.id;
    onSaveReference?.(issuer, { label: referenceName, value: referenceInput.value as string });
  }
}

function isReferenceAlreadySaved(referenceInput: FormInput, issuer: BillIssuer) {
  return !!issuer.references && referenceInput.value === issuer.references[0];
}
