import { CacheLoader } from "../../core/cache/cache-loader";
import { CacheStatus } from "../../core/cache/cache-status";
import { logger } from "../../core/logging/logger";
import { Observable } from "../../utils/observable";
import { ObservablePaginated } from "../../utils/observable-paginated";
import { createEmptyPaginatedData, Paginated } from "../../utils/pagination";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { FeaturesManager } from "../features/features-manager";
import { ExternalAccountData, ExternalAccountService } from "./external-account-service";
import { Recipient } from "./recipient";

export class ExternalAccountManager {
  public externalAccounts = new ObservablePaginated<Recipient>(createEmptyPaginatedData<Recipient>());
  public cacheStatus = new Observable<CacheStatus | null>(null);

  public loading = new Observable<boolean>(false);
  public error = new Observable<Error | null>(null);

  public loadingMore = new Observable<boolean>(false);
  public loadingMoreError = new Observable<Error | null>(null);

  public constructor(
    private externalAccountService: ExternalAccountService,
    private authenticationManager: AuthenticationManager,
    private featuresManager: FeaturesManager,
    private cacheLoader: CacheLoader<Paginated<Recipient>>,
  ) {
    this.authenticationManager.isConnected.onChange.add(async (isConnected) => {
      if (!isConnected) {
        await this.clear();
      }
    });
  }

  public async load(forceRefresh?: boolean): Promise<void> {
    if (
      this.loading.get() ||
      !(
        this.featuresManager.features.get().externalAccountsManagement ||
        this.featuresManager.features.get().externalAccountsUsage
      )
    ) {
      return;
    }
    try {
      this.loading.set(true);
      this.error.set(null);

      const externalAccounts = await this.cacheLoader.load(
        () => this.externalAccountService.fetchRecipients(undefined),
        forceRefresh,
      );
      if (!externalAccounts) {
        throw Error("Failed to fetch externalAccounts");
      }
      await this.updateCacheStatus();
      this.externalAccounts.set(externalAccounts);
    } catch (e) {
      logger.debug("BeneficiaryManager", "Load beneficiary failed", e);
      this.error.set(e);
      this.externalAccounts.set(createEmptyPaginatedData<Recipient>());
    } finally {
      this.loading.set(false);
    }
  }

  public async loadMore(): Promise<void> {
    if (this.loading.get() || this.loadingMore.get()) {
      return;
    }
    try {
      this.loadingMore.set(true);
      this.loadingMoreError.set(null);
      const additional = await this.externalAccountService.fetchRecipients({
        offset: this.externalAccounts.get().offset + this.externalAccounts.get().limit,
        limit: this.externalAccounts.get().limit,
        sort: this.externalAccounts.get().sort,
      });
      this.externalAccounts.add(additional);
      this.cacheLoader.store(this.externalAccounts.get(), this.cacheStatus.get()?.creation);
    } catch (e) {
      logger.debug("BeneficiaryManager", "Load more recipient failed", e);
      this.loadingMoreError.set(e);
    } finally {
      this.loadingMore.set(false);
    }
  }

  public async requestNewRecipient(name: string, identity: ExternalAccountData) {
    return await this.externalAccountService.requestNewRecipient(name, identity);
  }

  public async confirmNewRecipient(name: string, identity: ExternalAccountData, otp: string) {
    const result = await this.externalAccountService.confirmNewRecipient(name, identity, otp);
    await this.load(true);
    return result;
  }

  public async updateRecipient(id: string, name: string) {
    await this.externalAccountService.updateRecipient(id, name);
    const externalAccounts = this.externalAccounts.get();
    const index = externalAccounts.items.findIndex((externalAccount) => externalAccount.id === id);
    const externalAccount = externalAccounts.items[index];
    if (index >= 0) {
      externalAccounts.items[index] = { ...externalAccount, name };
      this.externalAccounts.set(externalAccounts);
    }
    await this.load(true);
  }

  public async deleteRecipient(id: string) {
    await this.externalAccountService.deleteRecipient(id);
    await this.load(true);
  }

  private async updateCacheStatus() {
    const cacheStatus = await this.cacheLoader.readStatus();
    this.cacheStatus.set(cacheStatus);
  }

  private async clear() {
    await this.cacheLoader.clear();
    this.externalAccounts.set(createEmptyPaginatedData<Recipient>());
    this.cacheStatus.set(null);
  }
}
