import React from "react";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { Spending } from "../../../../../shared/domains/accounting-transaction/transaction/spending";
import { AmountText } from "../../../common/amount-text";

type AttachmentPreviewProps = {
  spending: Spending;
};

export default function SpendingPreview(props: AttachmentPreviewProps) {
  const { spending } = props;
  return (
    <Wrapper>
      <div>
        <Title>{spending.label}</Title>
        <Category>
          {spending.category.label || spending.category.id} - {spending.subcategory.label || spending.subcategory.id}
        </Category>
      </div>
      {!!spending.amount && <StyledAmountText amount={spending.amount} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-right: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: block;
`;

const Category = styled.div`
  display: block;
  color: ${theme.colors.gray[500]};
  font-transform: uppercase;
  font-size: 12px;
`;

const StyledAmountText = styled(AmountText)`
  ${theme.mediumText};
`;
