import React from "react";
import styled from "styled-components";

export const ScrollableDiv: React.FC = ({ children }) => (
  <FaderContainer>
    <ScrollContainer>{children}</ScrollContainer>
  </FaderContainer>
);

const FaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  ::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 15px;
    height: 15px;
    background: linear-gradient(to bottom, #fafafa 4%, rgba(250, 250, 250, 0.15) 100%);
    pointer-events: none;
    z-index: 1;
  }

  ::after {
    position: absolute;
    content: "";
    pointer-events: none;
    bottom: 0;
    left: 0;
    right: 15px;
    height: 15px;
    background: linear-gradient(to top, #fafafa 4%, rgba(250, 250, 250, 0.15) 100%);
  }
`;

const ScrollContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
`;
