import React from "react";
import { ViewProps } from "react-native";
import styled from "styled-components";
import { useRTL } from "../../../../domain/language/use-rtl";
import { theme } from "../../../styles/theme";

interface BadgeCountProps extends ViewProps {
  num: string;
  margin?: number;
}

export const BadgeCount: React.FC<BadgeCountProps> = (props) => {
  const { num, margin = 0 } = props;

  const { isRTL } = useRTL();

  return (
    <Circle $isRTL={isRTL} margin={margin}>
      <Value>{num}</Value>
    </Circle>
  );
};

const Circle = styled.div<{ $isRTL: boolean; margin: number }>`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background-color: #ececec;
  align-items: center;
  justify-content: center;
  margin-right: ${({ $isRTL, margin }) => ($isRTL ? 0 : margin)}px;
  margin-left: ${({ $isRTL, margin }) => ($isRTL ? margin : 0)}px;
`;

const Value = styled.span`
  ${theme.boldText}
  color: black;
  font-size: 0.75rem;
`;
