import { BillIssuer, BillCategory } from "./bill";

export function collectIssuers(issuers: (BillIssuer | BillCategory)[]): BillIssuer[] {
  return issuers.reduce(
    (acc, item) => (item.type === "CATEGORY" ? [...acc, ...collectIssuers(item.children)] : [...acc, item]),
    [] as BillIssuer[],
  );
}

export interface BillIssuerWithParentId extends BillIssuer {
  topLevelCategory?: BillCategory;
}
export function collectIssuersWithTopLevelCategory(
  issuers: (BillIssuer | BillCategory)[],
  topLevelCategory?: BillCategory,
): BillIssuerWithParentId[] {
  return issuers.reduce(
    (acc, item) =>
      item.type === "CATEGORY"
        ? [...acc, ...collectIssuersWithTopLevelCategory(item.children, topLevelCategory || item)]
        : [...acc, { topLevelCategory, ...item }],
    [] as BillIssuerWithParentId[],
  );
}
