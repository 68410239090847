import React, { SVGProps } from "react";

export const PhoneSuccessImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width="84" height="147" viewBox="0 0 84 147" fill="none" {...props}>
    <path fill="#F4F4F4" d="M82 125H1V22h81z" />
    <path d="M1 6a5 5 0 0 1 5-5h71a5 5 0 0 1 5 5v16H1V6zM82 141a5 5 0 0 1-5 5H6a5 5 0 0 1-5-5v-19h81v19z" fill="#fff" />
    <rect x="1.25" y="1.25" width="81.5" height="144.5" rx="5.75" stroke="#4D4D4D" strokeWidth="2.5" />
    <path d="m28.5 67 13 12L63 54" stroke="#72D362" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M31 14h22" stroke="#4D4D4D" strokeWidth="2" />
    <circle cx="24" cy="14" r="1" fill="#4D4D4D" />
    <circle cx="41.5" cy="132.5" r="6.5" stroke="#4D4D4D" strokeWidth="2" />
    <mask id="2n11v50m4a" fill="#fff">
      <path d="M65.25 63.218a25.5 25.5 0 1 1-9.465-11.841l-1.46 2.16a22.893 22.893 0 1 0 8.498 10.63l2.428-.95z" />
    </mask>
    <path
      d="M65.25 63.218a25.5 25.5 0 1 1-9.465-11.841l-1.46 2.16a22.893 22.893 0 1 0 8.498 10.63l2.428-.95z"
      stroke="#72D362"
      strokeWidth="6"
      mask="url(#2n11v50m4a)"
    />
  </svg>
);
