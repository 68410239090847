export enum RecipientStateEnum {
  OPENED = "opened",
  CLOSED = "closed",
  PENDING = "pending",
}

export enum RecipientStrongAuthenticationModeEnum {
  OTP_SMS = "SMS_OTP",
  PINCODE = "PINCODE",
}

export type RecipientStrongAuthentication = {
  authenticationMode: RecipientStrongAuthenticationModeEnum[];
  strongAuthenticationToken: string;
};

export interface Recipient {
  id: string;
  name: string;
  iban?: string;
  bankName?: string;
  bic?: string;
  phone?: string;
  accountReference?: string;
  counterparty?: boolean;
  state?: RecipientStateEnum;
  strongAuthentication: RecipientStrongAuthentication | null;

  externalData?: { [key: string]: string };
}

export type SimpleRecipient = Partial<Recipient>;

export type TransferRecipient = Recipient | SimpleRecipient;
