import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Acceptor } from "../../../../../shared/domains/acceptors/acceptor";
import { redirectionHandledOnAccountBlocked } from "../../../../../shared/domains/account/account";
import { isAccountBlocked } from "../../../../../shared/domains/pincode/pincode-error";
import { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { DedicatedTransferState, useDedicatedTransferMachine } from "../../../../machine/dedicated-transfer-machine";
import { PincodeState } from "../../../../machine/keyboard-machine-type";
import { Avatar } from "../../../common/avatar/avatar";
import { ErrorMessage } from "../../../common/error-message";
import { PincodeKeyboard } from "../../../common/keyboard/pincode-keyboard";
import { MainColorSpinner } from "../../../common/spinner";
import { AmountSelection } from "../../../common/transaction/amount-selection";
import { theme } from "../../../styles/theme";
import { AcceptorSelection } from "./acceptor-selection";
import { DedicatedTransferSummary } from "./dedicated-transfer-summary";

export interface DedicatedTransferModalProps {
  recipient: Recipient;
  closeModal: () => void;
}

const loadingState = [DedicatedTransferState.RequestingTransfer, PincodeState.PincodeConfirmation];

export const DedicatedTransferTransaction = (props: DedicatedTransferModalProps) => {
  const { recipient, closeModal } = props;
  const { state, selectAcceptorId, selectAmount, submitPincode, context } = useDedicatedTransferMachine();
  const [selectedAcceptor, setSelectedAcceptor] = useState<Acceptor | undefined>(undefined);
  const history = useHistory();

  const submitAcceptor = (acceptor: Acceptor) => {
    setSelectedAcceptor(acceptor);
    selectAcceptorId(acceptor.id);
  };

  useEffect(() => {
    if (redirectionHandledOnAccountBlocked(context.error, history.push)) {
      closeModal();
    }
  }, [context.error, history.push, closeModal]);

  const { formatMessage } = useIntl();
  const errorMessage = !isAccountBlocked(context.error) ? context.error : undefined;

  switch (state) {
    case DedicatedTransferState.EnterAcceptorId: {
      return <AcceptorSelection title={formatMessage("acceptorSelection.title")} onSubmit={submitAcceptor} />;
    }
    case DedicatedTransferState.SelectingAmount:
    case DedicatedTransferState.RequestingTransfer:
    case DedicatedTransferState.RequestingTransferError:
    case PincodeState.PincodeConfirmation:
    case DedicatedTransferState.ConfirmTransferError: {
      return (
        <>
          <Centered>
            <Title>{formatMessage("dedicatedTransferAmount.title")}</Title>
            {selectedAcceptor && (
              <SecondaryInfo>
                {formatMessage("dedicatedTransferAmount.subtitle", { code: selectedAcceptor.code })}
              </SecondaryInfo>
            )}
            <Avatar size={36} name={recipient.name} />
            <SecondaryInfo>{formatMessage("recipient.transferTo", { name: recipient.name })}</SecondaryInfo>
            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
          </Centered>
          <StyledAmountSelection
            recipient={recipient}
            acceptor={selectedAcceptor}
            submitAmount={selectAmount}
            loading={loadingState.includes(state)}
          />
        </>
      );
    }
    case PincodeState.FetchKeyboardAfterError:
    case PincodeState.PromptingKeyboard: {
      const keyboard = context.keyboard!;
      return (
        <PincodeKeyboard
          keyboard={keyboard}
          onSubmit={submitPincode}
          title={formatMessage("pincodeConfirmation.label")}
          errorMessage={errorMessage}
          loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
        />
      );
    }
    case DedicatedTransferState.Done: {
      const transactionResult = context.transactionResult!;
      return (
        <DedicatedTransferSummary
          recipient={recipient}
          acceptor={selectedAcceptor}
          title={formatMessage("transferSummary.summaryTitle")}
          transaction={transactionResult.metadata.transaction}
          sourceAccount={context.sourceAccount}
          onDone={closeModal}
        />
      );
    }
    default: {
      return (
        <LoadingContainer>
          <MainColorSpinner size="30px" />
        </LoadingContainer>
      );
    }
  }
};

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.span`
  font-size: 1.125rem;
  ${theme.boldText};
  text-align: center;
  margin-top: 20px;
`;

const SecondaryInfo = styled.span`
  font-size: 0.813rem;
  ${theme.mediumText};
  color: #b1b1b1;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const StyledAmountSelection = styled(AmountSelection)`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 50px;
  text-align: center;
`;
