import { NetworkInfoManager } from "../../../shared/core/net/network-info-manager";

export class WebNetworkInfoManager implements NetworkInfoManager {
  hasInternet(): Promise<boolean> {
    return Promise.resolve(true);
  }
  addHasInternettListener(listener: (hasInternet: boolean) => void): () => void {
    listener(true);
    return () => null;
  }
}
