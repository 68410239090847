import React, { useState } from "react";

import { ChevronIcon } from "../../../common/svg/chevron";
import styled from "styled-components";

interface SectionRow {
  title: string;
  value: string;
}

interface CollapsableRowsSectionProps {
  rows: SectionRow[];
}

export const CollapsableRowsSection = (props: CollapsableRowsSectionProps) => {
  const { rows } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <StyledSectionContainer>
      <StyledSectionHeader onClick={() => setIsOpened(!isOpened)}>
        <StyledHeaderLabel>{rows[0].title}</StyledHeaderLabel>
        <StyledHeaderSeparator>—</StyledHeaderSeparator>
        <StyledHeaderLabel>{rows[0].value}</StyledHeaderLabel>
        <ArrowDown width={16} height={16} fill={"#666666"} reversed={isOpened} />
      </StyledSectionHeader>
      {isOpened && (
        <StyledRowContainer>
          {rows.slice(1, rows.length).map((r) => (
            <StyledRow key={r.title}>
              <StyledRowText>{r.title}</StyledRowText>
              <StyledRowText>{r.value}</StyledRowText>
            </StyledRow>
          ))}
        </StyledRowContainer>
      )}
    </StyledSectionContainer>
  );
};

const StyledSectionContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const StyledSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 7px;
  margin-top: 8px;
`;

const StyledHeaderSeparator = styled.span`
  margin-left: 12px;
  margin-right: 12px;
  color: #d6d6d6;
`;

const StyledRowText = styled.span`
  font-weight: 500;
  color: #666666;
  margin-bottom: 7px;
`;

const StyledRowContainer = styled.div`
  border-left-color: #d6d6d6;
  border-left-width: 1px;
  border-left-style: solid;
  padding-left: 8px;
  padding-top: 6px;
  margin-left: 6px;
  margin-right: 34px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ArrowDown = styled(ChevronIcon)<{ reversed: boolean }>`
  transform: ${(props) => (props.reversed ? "rotate(90deg)" : "rotate(-90deg)")};
`;

const StyledHeaderLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: #212121;
`;
