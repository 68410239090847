import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account, AccountType } from "../../../../../shared/domains/account/account";
import { Client, useClientDisplayName } from "../../../../../shared/domains/client/client";
import { Gender } from "../../../../../shared/domains/client/gender";
import { nameWithGenderLabels } from "../../../../../shared/domains/client/gender-labels";
import { isDefined } from "../../../../../shared/utils/assert";
import { AmountText } from "../../../common/amount-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { AmountInput } from "../../../common/forms/amount-input";
import { RechargeCardIcon } from "../../../common/svg/recharge-card-icon";
import { shadows, theme } from "../../../styles/theme";

interface SelectRechargeAmountProps {
  account: Account;
  owner: Client | null;
  submitAmount: (amount: Amount) => void;
  loading?: boolean;
  errorMessage?: string;
}
export const SelectRechargeByCardAmount = (props: SelectRechargeAmountProps) => {
  const { account, owner, submitAmount, loading, errorMessage } = props;
  const [amount, setAmount] = useState<Amount>(new Amount(0, account.balance.currency));
  const { formatMessage } = useIntl();

  const displayName = useClientDisplayName(owner, true);
  const gender = owner?.gender ?? Gender.None;
  const accountDisplayName = useMemo(
    () =>
      account.type === AccountType.Dedicated && isDefined(account.merchant) && account.merchant.length > 0
        ? account.merchant
        : formatMessage(nameWithGenderLabels[gender], { name: displayName }),
    [account.type, account.merchant, displayName, gender, formatMessage],
  );

  return (
    <ContentContainer>
      <Header>
        <HeaderLogo>
          <IconContainer>
            <RechargeCardIcon width={24} height={24} fill={theme.mainColor()} />
          </IconContainer>
        </HeaderLogo>
        <HeaderContentContainer>
          <Title>{formatMessage("rechargeByCard.title")}</Title>
          <Subtitle>{formatMessage("rechargeByCard.subtitle")}</Subtitle>
        </HeaderContentContainer>
      </Header>
      {isDefined(errorMessage) && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      <Card>
        <CardTitle>{formatMessage("rechargeByCard.creditedAccountTitle")}</CardTitle>
        <CardSection>
          <AccountDetails>
            <AccountName>{accountDisplayName}</AccountName>
            <AccountId>{formatMessage("rechargeByCard.accountId", { id: account.id })}</AccountId>
          </AccountDetails>
          <AccountBalance amount={account.balance} />
        </CardSection>
      </Card>
      <CardTitle>{formatMessage("rechargeByCard.amountToCredit")}</CardTitle>
      <StyledAmountInput value={amount} onChange={setAmount} />
      <Footer>
        <PrimaryButton
          size="S"
          onClick={() => submitAmount(amount)}
          showSpinner={loading}
          disabled={!(amount.value > 0)}>
          {formatMessage("rechargeByCard.recharge")}
        </PrimaryButton>
      </Footer>
    </ContentContainer>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  margin-bottom: 40px;
`;
const HeaderLogo = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
`;
const HeaderContentContainer = styled.div`
  flex-direction: column;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: ${() => theme.mainColorLightBackground()};
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-right: 15px;
`;
const Title = styled.div`
  font-size: 1.125rem;
  ${theme.boldText}
`;

const Subtitle = styled.div`
  font-size: 0.8125rem;
  ${theme.text};
  color: #b1b1b1;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  background-color: #ffffff;
  border-radius: 13px;
  ${shadows.medium};
  margin-bottom: 16px;
`;

const CardTitle = styled.div`
  ${theme.boldText};
  font-size: 0.9375rem;
  margin-bottom: 5px;
`;

const CardSection = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 5px;
`;

const AccountDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccountBalance = styled(AmountText)`
  font-size: 1.25rem;
  ${theme.boldText};
  text-align: start;
`;

const AccountName = styled.div`
  font-size: 0.9375rem;
  ${theme.text};
`;

const AccountId = styled.div`
  font-size: 0.8125rem;
  ${theme.boldText};
  color: #b1b1b1;
`;

const StyledAmountInput = styled(AmountInput)`
  margin-top: 15px;
`;

const Footer = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: center;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0px 10px 0px;
  text-align: center;
`;
