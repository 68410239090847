import React, { useCallback, useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../../../shared/utils/assert";
import { useAsyncOperation } from "../../../../../../shared/utils/utils";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../../common/error-message";
import { theme } from "../../../../styles/theme";
import { DigitsInput, DigitsInputRef } from "../options/digits-input";

export interface SetCardPincodeProps {
  title: string;
  instructionsText: string;
  confirmationText: string;
  validatePincode: (pincode: string) => Promise<void>;
  updatePincode?: (pincode: string) => void;
  validateButtonTitle?: string;
}

const CardPinCodeLength = 4;

export const SetCardPincode = (props: SetCardPincodeProps) => {
  const {
    validatePincode,
    updatePincode,
    title,
    instructionsText: instructions,
    confirmationText: confirmation,
    validateButtonTitle,
  } = props;
  type PincodeState = "define" | "confirm";

  const { formatMessage } = useIntl();

  const [pincode, setPincode] = useState("");
  const [confirmPincode, setConfirmPincode] = useState("");
  const [pincodeState, setPincodeState] = useState<PincodeState>("define");

  const isDefinePincodeValid = pincode.length === CardPinCodeLength;
  const isConfirmPincodeValid = confirmPincode === pincode;

  const [goToNextStep, loading] = useAsyncOperation(async () => {
    if (pincodeState === "confirm") {
      await validatePincode(pincode);
    } else {
      setPincodeState("confirm");
      resetInput();
    }
  });

  const pincodeInputRef = useRef<DigitsInputRef>(null);
  const resetInput = useCallback(() => {
    pincodeInputRef.current?.reset();
  }, []);

  const definePincode = (code: string, complete: boolean) => {
    // Do not reset pincode once it has been defined
    if (pincode === "") {
      updatePincode?.(code);
    }
    if (complete) {
      setPincode(code);
    }
  };

  const changePinCode = () => {
    setPincode("");
    setPincodeState("define");
    setConfirmPincode("");
    resetInput();
  };

  const pincodeConfig =
    pincodeState === "define"
      ? {
          pincodeInstructions: instructions,
          onIdentifierChange: definePincode,
          isPinCodeValid: isDefinePincodeValid,
          isError: false,
          back: undefined,
        }
      : {
          pincodeInstructions: confirmation,
          onIdentifierChange: (code: string) => setConfirmPincode(code),
          isPinCodeValid: isConfirmPincodeValid,
          isError: confirmPincode.length === CardPinCodeLength && !isConfirmPincodeValid,
          validator: (value: string) => value === pincode,
          back: changePinCode,
        };
  return (
    <Container>
      <PincodeTitle>{title}</PincodeTitle>

      <InstructionsContainer>
        <PincodeInstructions>{pincodeConfig.pincodeInstructions}</PincodeInstructions>
      </InstructionsContainer>

      <PincodeInput
        ref={pincodeInputRef}
        length={CardPinCodeLength}
        onIdentifierChange={pincodeConfig.onIdentifierChange}
        validator={pincodeConfig.validator}
      />
      <ErrorContainer>
        {pincodeConfig.isError && <ErrorMessage>{formatMessage("cardPincode.errorCodesMismatch")}</ErrorMessage>}
      </ErrorContainer>
      <ImportantMessageContainer>
        <ImportantMessage>{formatMessage("cardPincode.importantMessage")}</ImportantMessage>
      </ImportantMessageContainer>
      <ButtonContainer>
        {isDefined(pincodeConfig.back) && (
          <PreviousButton size="S" onClick={pincodeConfig.back}>
            {formatMessage("cardPincode.previous")}
          </PreviousButton>
        )}
        <NextButton disabled={!pincodeConfig.isPinCodeValid} showSpinner={loading} size="S" onClick={goToNextStep}>
          {isDefined(pincodeConfig.back) && isDefined(validateButtonTitle)
            ? validateButtonTitle
            : formatMessage("cardPincode.next")}
        </NextButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const PincodeTitle = styled.span`
  ${theme.boldText}
  padding-bottom: 40px;
  font-size: 1.125rem;
  color: #000000;
`;

const InstructionsContainer = styled.div`
  height: 100px;
  dispaly: flex;
`;

const PincodeInstructions = styled.span`
  ${theme.text}
  font-size: 0.875rem;
  color: #aaaaaa;
`;

const PincodeInput = styled(DigitsInput)`
  margin-top: 20px;
  margin-bottom: 20px;
  align-self: center;
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
`;

const ImportantMessageContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ImportantMessage = styled.span`
  ${theme.text}
  font-size: 0.875rem;
  color: #aaaaaa;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const PreviousButton = styled(PrimaryButton)`
  margin-right: 16px;
`;

const NextButton = styled(PrimaryButton)``;
