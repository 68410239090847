import { useState } from "react";
import { spendingsManager } from "../../../core/service/services";
import { logger } from "../../../core/logging/logger";
import { useObservable } from "../../../utils/observable";
import { useAsyncEffect } from "../../../utils/utils";
import { addAmounts, Amount, diffAmount } from "../../../core/amount/amount";
import { isDefined } from "../../../utils/assert";
import { getAmounts } from "./spending";
import { useTransactionLinks } from "../use-transaction-links";
import { Transaction } from "./transaction";
import { getUrlFromLink } from "../../BaseUrl";
import { TransactionLinks } from "./transaction-links";

export const useSpendings = (transaction?: Transaction) => {
  const spendings = useObservable(spendingsManager.spendings);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { canGetSpendings } = useTransactionLinks();

  const refresh = async (forceRefresh = false) => {
    await spendingsManager.clear();
    if (transaction && (canGetSpendings(transaction) || forceRefresh)) {
      try {
        setError("");
        setLoading(true);
        const url = getUrlFromLink(transaction.links, TransactionLinks.GetSpendings);
        await spendingsManager.refresh(transaction.id, url);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(e.response.data.error.message);
        logger.debug("error fetching spendings", e);
      }
    } else {
      spendingsManager.clear();
    }
  };

  useAsyncEffect(async () => {
    await refresh();
  }, [transaction]);

  const amountJustified = () => {
    const filteredAmounts = getAmounts(spendings);
    if (filteredAmounts.length > 0) {
      return addAmounts(...filteredAmounts);
    }
    return undefined;
  };

  const spendingsCount = () => spendings.length;

  const remainingAmount = (transactionAmount: Amount | undefined) => {
    if (!isDefined(transactionAmount)) {
      return undefined;
    }
    const amountAlreadyJustified = amountJustified();
    if (!isDefined(amountAlreadyJustified)) {
      return transactionAmount;
    }

    if (amountAlreadyJustified.currency == transactionAmount.currency) {
      return diffAmount(transactionAmount, amountAlreadyJustified);
    }
    return new Amount(0, transactionAmount.currency);
  };

  return { spendings, spendingsCount, amountJustified, remainingAmount, refresh, loading, error };
};
