import { accountManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";

export const useAccounts = () => {
  const accounts = useObservable(accountManager.accounts);

  useAsyncEffect(async () => {
    await accountManager.load();
  }, []);

  return accounts;
};
