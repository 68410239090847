import { addRequestInterceptor, addResponseInterceptor } from "./interceptors/interceptor";
import axios, { AxiosInstance } from "axios";

import { Config } from "../config/config";
import { DebugInterface } from "../debug/debug-interface";
import { HttpService } from "./http-service";
import { I18NStore } from "../i18n/i18n-store";
import { SecuredCookiesService } from "./secured-cookies-service";
import { ServiceDomainManager } from "../../domains/service-domain/service-domain-manager";
import { UserAgentService } from "./user-agent-service";
import { addAcceptLanguageInterceptor } from "./interceptors/add-accept-language-interceptor";
import { addAuthorizationInterceptor } from "./interceptors/add-authorization-interceptor";
import { addBaseUrlInterceptor } from "./interceptors/add-base-url-interceptor";
import { addSecuredCookieInterceptor } from "./interceptors/add-secured-cookie-interceptor";
import { addUserAgentInterceptor } from "./interceptors/add-user-agent-interceptor";
import { debugResponseInterceptor } from "./interceptors/debug-response-interceptor";
import { logResponseInterceptor } from "./interceptors/log-response-interceptor";
import { renewTokenInterceptor } from "./interceptors/renew-token-interceptor";

export class SavingsApiService {
  public instance: AxiosInstance;

  public constructor(
    private httpService: HttpService,
    private customUserAgentService: UserAgentService | null,
    private serviceDomainManager: ServiceDomainManager,
    private i18NStore: I18NStore,
    private securedCookiesService?: SecuredCookiesService,
    private debugIntercept?: DebugInterface | undefined,
  ) {
    this.instance = axios.create();
    addRequestInterceptor(this.instance, addBaseUrlInterceptor(Config.SAVINGS_API_URL));
    addRequestInterceptor(this.instance, addAcceptLanguageInterceptor(this.i18NStore));
    addRequestInterceptor(this.instance, addAuthorizationInterceptor(this.serviceDomainManager));
    addResponseInterceptor(
      this.instance,
      renewTokenInterceptor(this.httpService, this.serviceDomainManager, this.securedCookiesService),
    );
    if (this.securedCookiesService) {
      addRequestInterceptor(this.instance, addSecuredCookieInterceptor(this.securedCookiesService));
    }
    if (this.customUserAgentService) {
      addRequestInterceptor(this.instance, addUserAgentInterceptor(this.customUserAgentService));
    }
    if (this.debugIntercept) {
      addResponseInterceptor(this.instance, debugResponseInterceptor(debugIntercept));
    }
    addResponseInterceptor(this.instance, logResponseInterceptor);
  }
}
