export enum RegisterMode {
  PreAuth = "PREAUTH",
  Transaction = "TRANSACTION",
}

export interface RegisterPreAuthDto {
  metadata: {
    mode: RegisterMode;
    enrollmentId: string;
    confirmationMode?: RegisterConfirmationModeDto;
  };
}

export enum RegisterConfirmationMode {
  ValidationNeeded = "VALIDATION_NEEDED",
}
export enum RegisterTypeConfirmation {
  None = "NONE",
  Url = "URL",
}

export type RegisterConfirmationModeDto = RegisterConfirmationModeWithUrl | RegisterConfirmationModeWithoutUrl;
interface RegisterConfirmationModeWithUrl {
  mode: RegisterConfirmationMode;
  type: RegisterTypeConfirmation.Url;
  value: string;
}
interface RegisterConfirmationModeWithoutUrl {
  mode: RegisterConfirmationMode;
  type: RegisterTypeConfirmation.None;
}

export interface RegisterPreAuthErrorDto {
  error: {
    code: number;
    message: string;
  };
}
