import React, { useCallback } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { Recipient } from "../../../../shared/domains/recipients/recipient";
import { REMOVE_RECIPIENT_MODAL_ID } from "../../../core/modal/modal-id";
import { Modal } from "../../common/modal/modal";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { theme } from "../../styles/theme";

export interface RemoveRecipientModalProps {
  recipient: Recipient;
  onConfirm: () => void;
}

export const RemoveRecipientModal = (props: RemoveRecipientModalProps) => {
  const { recipient, onConfirm } = props;

  const { formatMessage } = useIntl();
  const closeModal = useCallback(() => Modal.dismiss(REMOVE_RECIPIENT_MODAL_ID), []);

  return (
    <ModalContainer id={REMOVE_RECIPIENT_MODAL_ID}>
      <>
        <Title>
          {recipient.counterparty
            ? formatMessage("recipient.deletePrompt.externalAccountTitle")
            : formatMessage("recipient.deletePrompt.title")}
        </Title>
        <Message>
          {recipient.counterparty
            ? formatMessage("recipient.deletePrompt.externalAccountMessage")
            : formatMessage("recipient.deletePrompt.message", { name: recipient.name })}
        </Message>
        <ButtonsContainer>
          <CancelButton onClick={() => closeModal()}>
            <CancelText>{formatMessage("recipient.deletePrompt.cancelButton")}</CancelText>
          </CancelButton>
          <ConfirmButton onClick={onConfirm}>
            <ConfirmText>{formatMessage("recipient.deletePrompt.confirmButton")}</ConfirmText>
          </ConfirmButton>
        </ButtonsContainer>
      </>
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 16px;
  min-width: 400px;
  margin-top: 200px;
  border-radius: 4px;
  background-color: white;
`;

const Title = styled.div`
  ${theme.mediumText};
  color: black;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
`;

const Message = styled.div`
  ${theme.text};
  color: black;
  font-size: 0.75em;
  line-height: 1.25rem;
  text-align: left;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
`;

const Button = styled.button`
  height: 30px;
  border-radius: 4px;
  min-width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;

  :hover {
    cursor: pointer;
  }
`;

const CancelButton = styled(Button)`
  background-color: white;
  border: solid;
  border: solid 1px #ececec;
`;

const Text = styled.span`
  ${theme.mediumText};
  font-size: 0.75em;
  line-height: 1.25rem;
  text-align: center;
`;

const CancelText = styled(Text)`
  color: #3474e0;
`;

const ConfirmButton = styled(Button)`
  background-color: #3474e0;
  border: none;
`;

const ConfirmText = styled(Text)`
  color: white;
`;
