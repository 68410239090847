export enum VerifyAuthenticationStatus {
  INITIATED = "INITIATED",
  STARTED = "STARTED",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  REFUSED = "REFUSED",
}

export enum AuthenticationsMethod {
  SMS = "sms",
  EMAIL = "email",
  PINCODE = "pincode",
}

export interface verifyAuthenticationResponse {
  status: VerifyAuthenticationStatus;
}
