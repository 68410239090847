import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { CARD_CREATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { Modal } from "../../../../common/modal/modal";
import { AddWithFilledCircleIcon } from "../../../../common/svg/add-with-filled-circle-icon";
import { shadows, theme } from "../../../../styles/theme";
import { UIConstants } from "../../../../styles/uiConstants";
import { CardCreationModal } from "./create-card-view";

export const AddCardItem = () => {
  const { formatMessage } = useIntl();

  const startCardCreationFlow = () => {
    Modal.present(CARD_CREATION_MODAL_ID, () => <CardCreationModal />, {
      canBeDismissed: false,
    });
  };

  return (
    <CardViewContainer onClick={startCardCreationFlow}>
      <ContentContainer>
        <AddIconContainer>
          <AddWithFilledCircleIcon />
        </AddIconContainer>
        <Label>{formatMessage("cardsList.orderCard")}</Label>
      </ContentContainer>
    </CardViewContainer>
  );
};

const CardViewContainer = styled.div`
  display: flex;
  width: ${UIConstants.CARD_TILE_WIDTH_NOT_MOBILE}px;
  height: 170px;
  border-radius: 13px;
  background-color: ${theme.mainColor};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${shadows.medium};
  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Label = styled.span`
  font-size: 1rem;
  ${theme.boldText};
  color: #ffffff;
  text-overflow: ellipsis;
  text-align: center;
`;

const AddIconContainer = styled.div`
  padding-bottom: 10px;
`;
