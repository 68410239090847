import { Signal } from "micro-signals";
import moment from "moment";
import { BasicStorage } from "../../../shared/core/cache/basic-storage";
import { logger } from "../../../shared/core/logging/logger";

export enum TrustStatus {
  Trusted,
  Untrusted,
  Unknown,
}
const TRUST_KEY = "trust";

export interface Trust {
  status: TrustStatus;
  validUntil: Date;
}

export class TrustManager {
  private trust: Trust | null = null;

  public constructor(private cache: BasicStorage<Trust>) {}

  public readonly onRemoveState = new Signal();

  public async isValid(): Promise<boolean> {
    await this.updateTrust();
    const result = this.dateIsValid();
    return result;
  }

  public async checkTrusted() {
    await this.updateTrust();
    const isTrusted = this.trust?.status === TrustStatus.Trusted;
    if (!isTrusted) {
      this.onRemoveState.dispatch(null);
    }
  }

  public setUnknown() {
    this.onRemoveState.dispatch(null);
    if (this.trust) {
      this.trust.status = TrustStatus.Unknown;
      this.updateNextDate();
      this.save();
    }
  }

  public setTrust(nbDays: number) {
    if (this.trust) {
      this.trust.status = TrustStatus.Trusted;
      this.updateNextDate(nbDays);
      this.save();
    }
  }

  public setUntrust(nbDays: number) {
    this.onRemoveState.dispatch(null);
    if (this.trust) {
      this.trust.status = TrustStatus.Untrusted;
      this.updateNextDate(nbDays);
      this.save();
    }
  }

  private async updateTrust() {
    const restoredTrust = await this.restore();
    if (restoredTrust) {
      this.trust = restoredTrust;
    } else {
      this.trust = { validUntil: new Date(), status: TrustStatus.Unknown };
    }
  }

  private save() {
    if (this.trust) {
      try {
        this.cache.store(this.trust, TRUST_KEY);
      } catch (e) {
        logger.debug("Trust Manager", "Failed to save trust ", e);
      }
    }
  }

  private async restore(): Promise<Trust | null> {
    try {
      return await this.cache.read(TRUST_KEY);
    } catch (e) {
      logger.debug("Trust Manager", "Failed to restore trust ", e);
      return Promise.resolve(null);
    }
  }

  private updateNextDate(nbDays = 1) {
    if (this.trust) {
      const nextValidDate = moment(this.trust.validUntil).add(nbDays, "days");
      this.trust.validUntil = new Date(
        nextValidDate.get("year"),
        nextValidDate.get("month"),
        nextValidDate.get("date"),
      );
    }
  }

  private dateIsValid() {
    const today = new Date();
    return moment(this.trust?.validUntil).isAfter(moment(today));
  }
}
