import { Paginated, PaginationOptions, createEmptyPaginatedData } from "../../utils/pagination";

import { Acceptor } from "./acceptor";
import { AcceptorCategory } from "./acceptor-category";
import { AcceptorService } from "./acceptor-service";
import { Observable } from "../../utils/observable";
import { ObservableArray } from "../../utils/observable-array";
import { ObservablePaginated } from "../../utils/observable-paginated";
import { logger } from "../../core/logging/logger";

export class AcceptorManager {
  public acceptors = new ObservablePaginated<Acceptor>(createEmptyPaginatedData<Acceptor>());

  public error = new Observable<Error | null>(null);
  public loading = new Observable(false);
  public loadingMore = new Observable(false);
  public loadingMoreError = new Observable<Error | null>(null);

  public latitude = new Observable(0);
  public longitude = new Observable(0);
  public radius = new Observable<number | undefined>(undefined);
  public categoryFilters = new ObservableArray<AcceptorCategory>([]);

  private currentRefreshPromise: Promise<Paginated<Acceptor>> | null = null;
  private supportDedicatedTransfers = new Observable(false);

  public constructor(private acceptorService: AcceptorService) {}

  public isFiltered(category: AcceptorCategory) {
    return this.categoryFilters.get().includes(category);
  }

  public areAllFiltered(categories: AcceptorCategory[]) {
    return this.categoryFilters.get().length === Object.keys(categories).length;
  }

  public async toggleAll(categories: AcceptorCategory[]) {
    if (this.areAllFiltered(categories)) {
      this.categoryFilters.set([]);
    } else {
      this.categoryFilters.set(categories);
    }
    await this.load(this.latitude.get(), this.longitude.get(), this.supportDedicatedTransfers.get());
  }

  public async load(
    latitude: number,
    longitude: number,
    supportDedicatedTransfers = false,
    limit?: number,
    radius?: number,
  ) {
    if (this.loading.get()) {
      return;
    }
    try {
      this.loading.set(true);
      this.error.set(null);

      this.acceptors.set(createEmptyPaginatedData<Acceptor>());

      this.latitude.set(latitude);
      this.longitude.set(longitude);
      this.radius.set(radius);

      const acceptors = await this.searchAcceptor(
        latitude,
        longitude,
        supportDedicatedTransfers,
        this.categoryFilters.get(),
        {
          offset: 0,
          limit: limit || 10,
        },
        radius,
      );
      if (!acceptors) {
        throw Error("Failed to fetch acceptors");
      }
      this.acceptors.set(acceptors);
      this.loading.set(false);
    } catch (e) {
      this.error.set(e);
      logger.debug("AcceptorManager", "Fetch acceptors failed", e);
      this.acceptors.set(createEmptyPaginatedData<Acceptor>());
      this.loading.set(false);

      if (!(e instanceof AcceptorSearchCancelledError)) {
        throw e;
      }
    }
  }

  public async loadMore(): Promise<void> {
    if (this.loading.get() || this.loadingMore.get()) {
      return;
    }
    try {
      this.loadingMore.set(true);
      this.loadingMoreError.set(null);

      const additional = await this.acceptorService.searchAcceptors(
        this.latitude.get(),
        this.longitude.get(),
        this.supportDedicatedTransfers.get(),
        this.categoryFilters.get(),
        {
          offset: this.acceptors.get().offset + this.acceptors.get().limit,
          limit: this.acceptors.get().limit,
        },
      );

      this.acceptors.add(additional);
      this.loadingMore.set(false);
    } catch (e) {
      this.loadingMoreError.set(e);
      logger.debug("Acceptors Manager", "Load more acceptors failed", e);
      this.loadingMore.set(false);
    }
  }

  public async resetFilters(limit?: number, latitude?: number, longitude?: number, radius?: number) {
    this.categoryFilters.set([]);
    await this.load(
      latitude || this.latitude.get(),
      longitude || this.longitude.get(),
      this.supportDedicatedTransfers.get(),
      limit,
      radius,
    );
  }

  public async updateFilters(category: AcceptorCategory) {
    const filters = this.categoryFilters.get();
    if (filters.includes(category)) {
      this.categoryFilters.set(filters.filter((c) => c !== category));
    } else {
      filters.push(category);
      this.categoryFilters.set(filters);
    }
    await this.load(this.latitude.get(), this.longitude.get(), this.supportDedicatedTransfers.get());
  }

  private async searchAcceptor(
    latitude: number,
    longitude: number,
    supportDedicatedTransfers: boolean,
    categories: AcceptorCategory[],
    pagination?: PaginationOptions,
    radius?: number,
  ): Promise<Paginated<Acceptor>> {
    this.supportDedicatedTransfers.set(supportDedicatedTransfers);
    const promise = this.acceptorService.searchAcceptors(
      latitude,
      longitude,
      supportDedicatedTransfers,
      categories,
      pagination,
      radius,
    );
    this.currentRefreshPromise = promise;
    const acceptors = await promise;
    if (promise !== this.currentRefreshPromise) {
      throw new AcceptorSearchCancelledError();
    }
    return acceptors;
  }
}

export class AcceptorSearchCancelledError extends Error {
  public constructor(message?: string) {
    super(message);
  }
}
