export const UIConstants = {
  PAGE_MAX_WIDTH: 2000,
  CONTENT_HORIZONTAL_PADDING_NOT_DESKTOP: 20,
  CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL: 50,
  CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE: 70,
  NAV_WIDTH: 285,
  NAV_WIDTH_REDUCED: 80,
  NAV_LEFT_MARGIN_DESKTOP_SMALL: 10,
  NAV_LEFT_MARGIN_DESKTOP_LARGE: 10,
  ACCOUNT_TILE_WIDTH_NOT_MOBILE: 392,
  ACCOUNT_TILE_WIDTH_MOBILE: 250,
  CARD_TILE_WIDTH_NOT_MOBILE: 291,
  CARD_TILE_WIDTH_MOBILE: 250,
  TABLET_BREAKPOINT: 768,
  PHONE_BREAKPOINT: 450,
  DESKTOP_SMALL_BREAKPOINT: 1024,
  DESKTOP_LARGE_BREAKPOINT: 1440,
  MODAL_WIDTH: 420,
  CONTACT_TILE_WIDTH_NOT_MOBILE: 392,
  CONTACT_TILE_WIDTH_MOBILE: 300,
  DESKTOP_EXTRA_LARGE_BREAKPOINT: 1640,
  PROFILE_GRID_WIDTH_BREAKPOINT: 1204,
};
