import React, { InputHTMLAttributes, useMemo } from "react";
import styled from "styled-components";
import { TextInput } from "./text-input";

export type DateMode = "date" | "datetime-local";

export const DateInput: React.FC<
  InputHTMLAttributes<HTMLInputElement> & {
    withoutTheme?: boolean;
    mode?: DateMode;
  }
> = ({ withoutTheme, mode, ...props }) => {
  const dateInputIsSupported = useMemo(() => isDateInputSupported(), []);
  const commonProps = {
    value: dateInputIsSupported ? props.value : deNormalizeDate(props.value?.toString() ?? ""),
    onChange: (e) =>
      dateInputIsSupported
        ? props.onChange?.(e)
        : props.onChange?.({ ...e, target: { ...e.target, value: normalizeDate(e.target.value) } }),

    type: mode,
    max: mode === "date" ? "9999-12-31" : undefined,
    pattern: dateInputIsSupported ? "[0-9]{4}-[0-9]{2}-[0-9]{2}" : "[0-9]{2}/[0-9]{2}/[0-9]{4}",
    placeholder: dateInputIsSupported ? props.placeholder : "JJ/MM/YYYY",
  };
  return withoutTheme ? <Input {...props} {...commonProps} /> : <StyledTextInput {...props} {...commonProps} />;
};

function isDateInputSupported() {
  const input = document.createElement("input");
  const value = "a";
  input.setAttribute("type", "date");
  input.setAttribute("value", value);
  return input.value !== value;
}

function normalizeDate(date: string) {
  const match = date.match(/(\d{0,2})\/(\d{0,2})\/(\d{0,4})/);
  if (match) {
    return `${match[3]}-${match[2]}-${match[1]}`;
  } else {
    return date;
  }
}

function deNormalizeDate(date: string) {
  const match = date.match(/(\d{0,4})\-(\d{0,2})\-(\d{0,2})/);
  if (match) {
    return `${match[3]}/${match[2]}/${match[1]}`;
  } else {
    return date;
  }
}

const StyledTextInput = styled(TextInput)<{ value?: string | number | readonly string[] | undefined }>`
  input {
    color: ${(props) => (props.value ? null : "#b1b1b1")};
  }
`;

const Input = styled.input`
  appearance: none;
  border-radius: 7px;
  border: none;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  flex-grow: 1;

  &:focus {
    outline: 0;
  }
`;
