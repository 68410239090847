import React from "react";
import { Amount } from "../../../../shared/core/amount/amount";
import { FormAmountInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { AmountInput } from "../forms/amount-input";
import { DisableValueText } from "./disable-value-text";

export const AmountField: React.FC<{
  input: FormAmountInput;
  onChange?: (amount: Amount | undefined) => void;
  disabled?: boolean;
}> = ({ input, onChange, disabled }) => {
  const { formatAmount } = useIntl();
  return (input.disabled || disabled) && input.value ? (
    <DisableValueText>{formatAmount(input.value)}</DisableValueText>
  ) : (
    <AmountInput
      required={input.required}
      value={input.value || new Amount(0, input.defaultCurrency)}
      currencies={input.currencies}
      onChange={(value) => onChange && onChange(isNaN(value.value) ? undefined : value)}
    />
  );
};
