import React, { useState } from "react";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { Recipient } from "../../../../shared/domains/recipients/recipient";
import { EDIT_RECIPIENT_MODAL_ID } from "../../../core/modal/modal-id";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import styled, { css } from "styled-components";
import { fonts, theme } from "../../styles/theme";
import { UserIcon } from "../../common/svg/user-icon";
import { PrefixedTextInput } from "../../common/forms/prefixed-text-input";
import { TextInput } from "../../common/forms/text-input";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { ErrorMessage } from "../../common/error-message";
import { logger } from "../../../../shared/core/logging/logger";
import { beneficiaryManager, externalAccountManager } from "../../../../shared/core/service/services";
import { Modal } from "../../common/modal/modal";

export interface EditBeneficiaryModalProps {
  recipient: Recipient;
  forExternalAccount?: boolean;
}

export const EditBeneficiaryModal = (props: EditBeneficiaryModalProps) => {
  const { recipient, forExternalAccount } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [name, setName] = useState<string>(recipient?.name || "");

  return (
    <RoundedModalContainer closeButton id={EDIT_RECIPIENT_MODAL_ID}>
      <Container>
        <Title>{formatMessage("editRecipient.title")}</Title>
        <Subtitle>
          {formatMessage(forExternalAccount ? "editRecipient.externalAccountSubtitle" : "editRecipient.subtitle")}
        </Subtitle>
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        <NameInput
          prefixElement={<UserIcon height={20} width={20} color={"#000000"} />}
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={formatMessage("addRecipient.nameFieldPlaceholder")}
        />
        {recipient?.iban && (
          <IbanInput value={recipient.iban} dir={"ltr"} bottomOffset={16} onChange={() => null} disabled />
        )}
        {recipient?.iban && (
          <BicInput value={recipient.bic} dir={"ltr"} onChange={() => null} bottomOffset={16} disabled />
        )}
        <ConfirmButton
          size="M"
          onClick={async () => {
            setLoading(true);
            try {
              if (forExternalAccount) {
                await externalAccountManager.updateRecipient(recipient.id, name);
              } else {
                await beneficiaryManager.updateRecipient(recipient.id, name);
              }
            } catch (e) {
              logger.debug("AddBeneficiaryDetail: Confirm error", e);
            } finally {
              Modal.dismiss(EDIT_RECIPIENT_MODAL_ID);
              setLoading(false);
            }
          }}
          disabled={loading}
          showSpinner={loading}>
          {formatMessage("editRecipient.saveButton")}
        </ConfirmButton>
      </Container>
    </RoundedModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

const Title = styled.span`
  ${fonts.bold}
  font-size: 1.5rem;
  color: ${theme.mainColor};
`;

const Subtitle = styled(Title)`
  color: black;
`;

const inputStyle = css`
  width: 100%;
  max-width: 360px;
  margin-bottom: 25px;
`;

const NameInput = styled(PrefixedTextInput)`
  margin-top: 48px;
  ${inputStyle};
`;

const IbanInput = styled(TextInput)`
  ${inputStyle};
`;

const BicInput = styled(TextInput)`
  ${inputStyle};
`;

const ConfirmButton = styled(PrimaryButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0px 0px 0px;
  text-align: center;
`;
