import { ActivationCardProps } from "./activation-modal";
import { CARD_REFABRICATION_MODAL_ID } from "../../../../../core/modal/modal-id";
import { CardRefabricateModal } from "../card-refabricate-modal";
import { Modal } from "../../../../common/modal/modal";
import React from "react";
import styled from "styled-components";
import { theme } from "../../../../styles/theme";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { useRTL } from "../../../../../domain/language/use-rtl";

export const RefabricateButton: React.FC<ActivationCardProps> = ({ card }) => {
  const { isRTL } = useRTL();
  const { formatMessage } = useIntl();

  const handleModalPresent = () => {
    Modal.present(CARD_REFABRICATION_MODAL_ID, () => <CardRefabricateModal card={card} />, {
      canBeDismissed: false,
    });
  };

  return (
    <RenewContainer
      onClick={() => {
        handleModalPresent();
      }}>
      <GreenCheck $isRTL={isRTL} src={require("../../../../../assets/images/cards/autorenew.png")} />
      <RenewLabel>{formatMessage("cardsList.refabricateButton")}</RenewLabel>
    </RenewContainer>
  );
};

const SideIcon = styled.img`
  cursor: pointer;
  width: 50px;
  height: 50px;
`;

const GreenCheck = styled(SideIcon)<{ $isRTL: boolean }>`
  width: 40px;
  height: 40px;
  z-index: 2;
  padding: 10px;
`;

const RenewContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const RenewLabel = styled.div`
  ${theme.boldText}
  color: black;
  font-size: 0.9375rem;
`;
