import { downloadFile } from "../../utils/download";
import { ConnectedServiceDomainApiService } from "../../../shared/core/net/connected-service-domain-api-service";
export class WebAccountBankDetailsService {
  constructor(private connectedServiceDomainApiService: ConnectedServiceDomainApiService) {}

  public async downloadAccountBankDetails(accountId: string) {
    const result = await this.connectedServiceDomainApiService.instance.get(
      `/accounts/${accountId}/bank-account-details/download`,
      {
        responseType: "arraybuffer",
      },
    );
    await downloadFile(`${accountId}_details.pdf`, result.data, "application/pdf");
    return `${accountId}_details.pdf`;
  }
}
