import { css, keyframes } from "styled-components";

const loadingAnimation = keyframes`
	0%{background-position:0% 0%}
	100%{background-position:89% 89%}
`;

export const AnimatedSkeletonStyle = css`
  background: linear-gradient(313deg, #e8e8e8, #ffffff, #e8e8e8, #ffffff);
  background-position: 100% 100%;
  background-size: 400% 400%;
  animation: ${loadingAnimation} ease-in-out infinite 1s;
`;
