import { Observable } from "../../utils/observable";
import { SavingDetails } from "./savings";
import { SavingsService } from "./savings-service";
import { logger } from "../../core/logging/logger";

export class SavingsManager {
  public savingDetails = new Observable<SavingDetails>({} as SavingDetails);
  public error = new Observable<Error | null>(null);
  public loading = new Observable<boolean>(true);

  public constructor(private savingsService: SavingsService) {}

  public async getSavingsDetails(accountId) {
    try {
      this.loading.set(true);
      this.error.set(null);
      const details = await this.savingsService.getSavingsDetails(accountId);
      this.loading.set(false);
      this.savingDetails.set(details);
    } catch (e) {
      logger.debug("Savings Manager", "Failed to get Savings details", e);
      this.error.set(e);
      this.loading.set(false);
      throw e;
    }
  }
}
