import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";
import { Machine, assign, send } from "xstate";
import {
  authenticationManager,
  loginService,
  pincodeKeyboardService,
  registerManager,
  uuidManager,
} from "../../shared/core/service/services";
import { FONT_SIZE, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

import { useMachine } from "@xstate/react";
import { useState } from "react";
import { useIntl } from "../../shared/core/i18n/use-intl";
import { RegisteringParameters } from "../../shared/domains/authentication/authentication-service";
import { Keyboard } from "../../shared/domains/pincode/keyboard";
import { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import { formatPhoneNumber } from "../../shared/utils/phone-number";

export const useConnectMachine = () => {
  const [state, sendEvent] = useMachine(connectMachine);

  const [phoneError, setPhoneError] = useState("");
  const [phoneLoading, setPhoneLoading] = useState(false);

  const { formatMessage } = useIntl();

  const showContactSupport = () => {
    sendEvent("CONTACT_SUPPORT");
  };

  const goBack = () => {
    sendEvent("GO_BACK");
  };

  const submitPinCode = async (pincode: PincodeSubmission) => {
    const pendingEnrollment = await registerManager.loadPendingEnrollment();
    sendEvent("SUBMIT_PINCODE", {
      pincode,
      ...(pendingEnrollment?.preAuth?.metadata.enrollmentId && {
        registerParams: {
          enrollment_id: pendingEnrollment?.preAuth?.metadata.enrollmentId,
        },
      }),
    });
  };
  const confirmOtp = (otp: string) => {
    sendEvent("OTP_CONFIRM", { otp });
  };

  const checkPhoneNumber = async (phone: string, countryCode: CountryCode) => {
    try {
      setPhoneLoading(true);
      setPhoneError("");
      const phoneNumberParsed = parsePhoneNumberFromString(phone, countryCode);
      if (!phoneNumberParsed) {
        throw formatMessage("common.invalidPhoneNumber");
      }
      const prefixedPhoneNumber = phoneNumberParsed.number.substring(1);
      const hasAccount = await loginService.checkPhoneNumber(prefixedPhoneNumber);
      setPhoneLoading(false);
      if (hasAccount) {
        sendEvent("PHONE_NUMBER_CHECKED", { phone: prefixedPhoneNumber });
      } else {
        setPhoneError(
          formatMessage("connectScreen.noAccountError", {
            phoneNumber: formatPhoneNumber(phoneNumberParsed.number.substring(1)),
          }),
        );
      }
    } catch (e) {
      setPhoneLoading(false);
      setPhoneError(e);
    }
  };

  return {
    state: state.value as ConnectState | PincodeState,
    context: state.context,
    phoneLoading,
    phoneError,
    showContactSupport,
    goBack,
    checkPhoneNumber,
    submitPinCode,
    confirmOtp,
  };
};

interface ConnectMachineContext {
  name: string;
  phone?: string;
  phoneError: string;
  keyboard: Keyboard;
  pincode: PincodeSubmission;
  registerParams?: RegisteringParameters;
  otp?: string;
  errorMessage?: string | undefined;
}

export enum ConnectState {
  WaitingForPhoneNumber = "WAITING_FOR_PHONE_NUMBER",
  ContactSupport = "CONTACT_SUPPORT",
  ValidatePincode = "VALIDATE_PINCODE",
  OTPSubmission = "OTP_SUBMISSION",
  OTPConfirming = "OTP_CONFIRMING",
  Connected = "CONNECTED",
}

type ConnectEvent =
  | { type: "CONTACT_SUPPORT" }
  | { type: "PHONE_NUMBER_CHECKED"; phone: string }
  | { type: "PROMPT_KEYBOARD" }
  | { type: "SUBMIT_PINCODE"; pincode: PincodeSubmission; registerParams?: RegisteringParameters }
  | { type: "PINCODE_ERROR" }
  | { type: "PINCODE_VALIDATED" }
  | { type: "OTP_CONFIRM"; otp: string }
  | { type: "OTP_ERROR" }
  | { type: "OTP_CONFIRMED" }
  | { type: "GO_BACK" };

enum ConnectInvokeName {
  FetchKeyboard = "FetchKeyboard",
  PincodeValidating = "PincodeValidating",
  OtpSubmitting = "OtpSubmitting",
  OtpRefreshing = "OtpRefresihng",
  OtpConfirming = "OtpConfirming",
  Connected = "Connected",
}

export const connectMachine = Machine<ConnectMachineContext, ConnectEvent>({
  id: "Connect",
  initial: ConnectState.WaitingForPhoneNumber,
  states: {
    [ConnectState.WaitingForPhoneNumber]: {
      on: {
        PHONE_NUMBER_CHECKED: {
          target: PincodeState.PincodeConfirmation,
          actions: assign({
            phone: (_, event) => event.phone,
          }),
        },

        CONTACT_SUPPORT: ConnectState.ContactSupport,
      },
    },

    [PincodeState.PincodeConfirmation]: {
      invoke: {
        id: ConnectInvokeName.FetchKeyboard,
        src: (ctx) => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, ctx.phone, FONT_SIZE),
        onDone: {
          actions: [
            assign({
              keyboard: (_, event) => event.data,
            }),
            send({ type: "PROMPT_KEYBOARD" }),
          ],
        },
      },
      on: {
        PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
      },
    },
    [PincodeState.PromptingKeyboard]: {
      on: {
        SUBMIT_PINCODE: {
          target: ConnectState.ValidatePincode,
          actions: assign({ pincode: (_, event) => event.pincode, registerParams: (_, event) => event.registerParams }),
        },

        GO_BACK: {
          target: ConnectState.WaitingForPhoneNumber,
        },
      },
    },

    [PincodeState.FetchKeyboardAfterError]: {
      invoke: {
        id: ConnectInvokeName.FetchKeyboard,
        src: (ctx) => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, ctx.phone, FONT_SIZE),
        onDone: {
          actions: [
            assign({
              keyboard: (_, event) => event.data,
            }),
            send({ type: "PROMPT_KEYBOARD" }),
          ],
        },
      },
      on: {
        PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
      },
    },

    [ConnectState.ValidatePincode]: {
      invoke: {
        id: ConnectInvokeName.PincodeValidating,
        src: (ctx) => authenticationManager.askWebAuthentOtp(ctx.pincode, ctx.registerParams),
        onDone: {
          actions: [
            assign<ConnectMachineContext, { type: string; data: any }>({
              errorMessage: undefined,
            }),
            send((event) => ({ type: event.registerParams ? "OTP_CONFIRMED" : "PINCODE_VALIDATED" })),
          ],
        },
        onError: {
          actions: [
            assign({
              errorMessage: (_, event) => event.data,
            }),
            send({ type: "PINCODE_ERROR" }),
          ],
        },
      },
      on: {
        PINCODE_ERROR: PincodeState.FetchKeyboardAfterError,
        PINCODE_VALIDATED: ConnectState.OTPSubmission,
        OTP_CONFIRMED: ConnectState.Connected,
      },
    },

    [ConnectState.OTPSubmission]: {
      on: {
        OTP_CONFIRM: {
          target: ConnectState.OTPConfirming,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions: assign<ConnectMachineContext, any>({
            otp: (_, event) => event.otp,
          }),
        },
        CONTACT_SUPPORT: ConnectState.ContactSupport,
      },
    },

    [ConnectState.OTPConfirming]: {
      invoke: {
        id: ConnectInvokeName.OtpConfirming,

        src: (ctx) => authenticationManager.validateWebAuthentOtp(ctx.otp),
        onDone: {
          actions: [
            assign<ConnectMachineContext, { type: string; data: any }>({
              errorMessage: undefined,
            }),
            send({ type: "OTP_CONFIRMED" }),
          ],
        },
        onError: {
          actions: [
            assign({
              errorMessage: (_, event) => event.data,
            }),
            send({ type: "OTP_ERROR" }),
          ],
        },
      },
      on: {
        OTP_ERROR: ConnectState.OTPSubmission,
        OTP_CONFIRMED: ConnectState.Connected,
        CONTACT_SUPPORT: ConnectState.ContactSupport,
      },
    },

    [ConnectState.ContactSupport]: { type: "final" },

    [ConnectState.Connected]: {
      type: "final",
      invoke: {
        id: ConnectInvokeName.Connected,
        src: async (ctx) => {
          await registerManager.clearPendingEnrollment();
          return uuidManager.setUuid(ctx.pincode.id);
        },
      },
    },
  },
});
