import { countries } from "../../assets/countries";
import { hasKey } from "../../utils/object";

export interface Address {
  line1?: string;
  line2?: string;
  zipcode?: string;
  district?: string;
  province?: string;
  region?: string;
  city?: string;
  country?: string;
}

export const formatAddress = (address: Address | undefined): string => {
  if (!address) {
    return "";
  }
  let country = address.country;
  if (country && hasKey(countries, country)) {
    country = countries[country].englishName;
  }
  return [
    address.line1,
    address.line2,
    address.district,
    address.city,
    address.province,
    address.region,
    address.zipcode,
    country,
  ]
    .filter((x) => typeof x === "string" && x.length > 0)
    .join("\n");
};
