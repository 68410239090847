import { useEffect } from "react";
import { i18NManager } from "../../../shared/core/service/services";
import { useObservable } from "../../../shared/utils/observable";

export function useRTL() {
  const isRTL = useObservable(i18NManager.isRTL);

  useEffect(() => {
    document.body.dir = isRTL ? "rtl" : "ltr";
  }, [isRTL]);

  return { isRTL };
}
