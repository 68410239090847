import React, { useCallback, useMemo, useState } from "react";
import { authenticationsSessionsService, cardManager } from "../../../../../../shared/core/service/services";
import {
  CardUpdatePincodeStep,
  useCardUpdatePincode,
} from "../../../../../../shared/domains/cards/use-card-update-pincode";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../../../shared/utils/phone-number";

import styled from "styled-components";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { VerifyAuthenticationStatus } from "../../../../../../shared/domains/authentication/authentications-sessions";
import { Card } from "../../../../../../shared/domains/cards/card";
import { CARD_PINCODE_UPDATE_MODAL_ID } from "../../../../../core/modal/modal-id";
import { useClient } from "../../../../../domain/authentication/use-client";
import { Modal } from "../../../../common/modal/modal";
import { RoundedModalContainer } from "../../../../common/modal/rounded-modal-container";
import { useQuitBlocking } from "../../../../common/nav/use-quit-blocking";
import { OtpConfirm } from "../../../recipient/otp-confirm";
import { ResultView } from "../../../result/result-view";
import { SetCardPincode } from "./set-card-pincode";

interface CardUpdatePincodeModalProps {
  card: Card;
  className?: string;
}

export const CardUpdatePincodeModal = (props: CardUpdatePincodeModalProps) => {
  const { card } = props;
  const { client } = useClient();
  const { step, validatePincode, textError, scaToken } = useCardUpdatePincode();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [scaIsLoading, setScaIsLoading] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  const [pincode, setPincode] = useState("");

  const isEdtingPinCode = useMemo(
    () => step === CardUpdatePincodeStep.DefinePincode && pincode.length > 0,
    [step, pincode],
  );

  const updateCard = useCallback((card: Card) => {
    try {
      cardManager.retrieveCard(card.id);
    } catch (e) {
      const error = e as any;
      console.error(error.response.data.error.message);
    }
  }, []);

  const { isAllowedToQuit } = useQuitBlocking(
    isEdtingPinCode,
    formatMessage("cardOptions.updateCardPincode.confirmationPopup.message"),
  );

  const handleOTPValidation = async (card, otp, scaToken) => {
    if (scaToken) {
      try {
        setScaIsLoading(true);
        const response = await authenticationsSessionsService.verifyAuthentication(scaToken, otp);
        if (response.status === VerifyAuthenticationStatus.DONE) {
          validatePincode(card, pincode, scaToken);
        }
      } catch (e) {
        setErrorMessage(e);
      } finally {
        setScaIsLoading(false);
      }
    } else {
      throw new Error("Missing SCA Token");
    }
  };

  const innerStepComponent = () => {
    switch (step) {
      case CardUpdatePincodeStep.DefinePincode:
        return (
          <SetCardPincode
            validatePincode={(pincode: string) => validatePincode(card, pincode)}
            updatePincode={setPincode}
            title={formatMessage("cardOptions.updateCardPincode.title")}
            instructionsText={formatMessage("cardOptions.updateCardPincode.definePincodeMessage")}
            confirmationText={formatMessage("cardOptions.updateCardPincode.confirmPincodeMessage")}
          />
        );
      case CardUpdatePincodeStep.SCACheck:
        return (
          <OtpConfirm
            submitOtp={(otp: string) => handleOTPValidation(card, otp, scaToken)}
            errorMessage={errorMessage}
            phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
            loading={scaIsLoading}
          />
        );

      case CardUpdatePincodeStep.Error:
        return (
          <StyledResultView
            type={"error"}
            title={formatMessage("cardOptions.updateCardPincode.failureTitle")}
            subtitle={formatMessage("cardOptions.updateCardPincode.failureSubtitle")}
            description={formatMessage("cardOptions.updateCardPincode.failureDescription")}
            error={textError}
            onConfirm={() => {
              updateCard(card);
              Modal.dismiss(CARD_PINCODE_UPDATE_MODAL_ID);
            }}
          />
        );

      case CardUpdatePincodeStep.Success:
        return (
          <StyledResultView
            type={"success"}
            title={formatMessage("cardOptions.updateCardPincode.successTitle")}
            subtitle={formatMessage("cardOptions.updateCardPincode.successSubtitle")}
            onConfirm={() => {
              updateCard(card);
              Modal.dismiss(CARD_PINCODE_UPDATE_MODAL_ID);
            }}
          />
        );
    }
  };

  return (
    <RoundedModalContainer
      id={CARD_PINCODE_UPDATE_MODAL_ID}
      closeButton
      closeModal={async () => {
        if (await isAllowedToQuit()) {
          Modal.dismiss(CARD_PINCODE_UPDATE_MODAL_ID);
        }
      }}>
      <ModalContainer>{innerStepComponent()}</ModalContainer>
    </RoundedModalContainer>
  );
};

const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
  height: 530px;
`;

const StyledResultView = styled(ResultView)`
  margin-left: 20px;
  margin-right: 20px;
`;
