import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../shared/utils/assert";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { ErrorCircle } from "../../common/error-circle";
import { ErrorMessage } from "../../common/error-message";
import { SuccessCircle } from "../../common/success-circle";
import { theme } from "../../styles/theme";

interface ResultViewProps {
  type: "success" | "error";
  title: string;
  onConfirm: () => void;
  subtitle?: string;
  description?: string;
  error?: string;
}

export const ResultView = (props: ResultViewProps) => {
  const { type, title, onConfirm, subtitle, description, error } = props;
  const { formatMessage } = useIntl();
  return (
    <Container>
      {type === "success" ? <SuccessCircle theme="shallow" /> : <ErrorCircle theme="shallow" />}
      <Title>{title}</Title>
      {isDefined(subtitle) && <Subtitle>{subtitle}</Subtitle>}
      {isDefined(description) && <Description>{description}</Description>}
      {isDefined(error) && <StyledErrorMessage>{error}</StyledErrorMessage>}
      <ButtonContainer>
        <CompleteButton size="S" onClick={onConfirm}>
          {formatMessage("resultScreen.done")}
        </CompleteButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  flex: 1;
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.375rem;
  ${theme.boldText};
  padding-top: 30px;
  padding-bottom: 5px;
`;

const Subtitle = styled.div`
  text-align: center;
  ${theme.text};
  font-size: 1rem;
  padding-top: 5px;
`;

const Description = styled.div`
  text-align: center;
  font-size: 0.9375rem;
  ${theme.text};
  padding-top: 15px;
  color: #aaaaaa;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0px 10px 0px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1 0;
`;

const CompleteButton = styled(PrimaryButton)`
  margin-top: 30px;
  margin-bottom: 20px;
`;
