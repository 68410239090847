import React, { useState } from "react";
import styled from "styled-components";
import { beneficiaryManager } from "../../../../../shared/core/service/services";
import { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { formatPhoneNumber } from "../../../../../shared/utils/phone-number";
import { REMOVE_RECIPIENT_MODAL_ID } from "../../../../core/modal/modal-id";
import { Avatar } from "../../../common/avatar/avatar";
import CardMoreMenu from "../../../common/card-more-menu";
import { Modal } from "../../../common/modal/modal";
import { shadows, theme } from "../../../styles/theme";
import { RemoveRecipientModal } from "../../recipient/remove-recipient-modal";

export interface RecipientRowProps {
  recipient: Recipient;
  readonly?: boolean;
  isRTL: boolean;
  onClick?: () => void;
  onEdit?: () => void;
}

export const RecipientRow = (props: RecipientRowProps) => {
  const { recipient, onClick, onEdit, readonly = true, isRTL } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu((bool) => !bool);

  const recipientDetail = recipient.phone
    ? formatPhoneNumber(recipient.phone)
    : recipient.bankName
      ? recipient.bankName
      : "";
  return (
    <Card onClick={onClick}>
      <Avatar size={40} name={recipient.name} />
      <RecipientDetailContainer $isRTL={isRTL}>
        <RecipientName>{recipient.name}</RecipientName>
        <RecipientDetail>{recipientDetail}</RecipientDetail>
      </RecipientDetailContainer>
      {!readonly && (
        <CardMoreMenu
          isRTL={isRTL}
          onToggle={toggleMenu}
          isOpen={openMenu}
          onEdit={() => onEdit?.()}
          onDelete={() => {
            Modal.present(
              REMOVE_RECIPIENT_MODAL_ID,
              // eslint-disable-next-line react/no-unstable-nested-components
              () => (
                <RemoveRecipientModal
                  recipient={recipient}
                  onConfirm={() => beneficiaryManager.deleteRecipient(recipient.id)}
                />
              ),
              {
                canBeDismissed: true,
              },
            );
          }}
        />
      )}
    </Card>
  );
};

const Card = styled.div`
  position: relative;
  display: flex;
  padding: 18px 27px 18px 15px;
  border-radius: 13px;
  ${shadows.medium};
  background-color: #ffffff;
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
  :hover {
    cursor: pointer;
  }
  :disabled {
    cursor: auto;
  }
`;

const RecipientName = styled.span`
  font-size: 0.9375rem;
  ${theme.mediumText};
  color: #000000;
`;

const RecipientDetail = styled.span`
  ${theme.text};
  font-size: 0.8125rem;
  color: #b1b1b1;
  direction: ltr;
`;

const RecipientDetailContainer = styled.div<{ $isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.$isRTL ? 15 : 0)}px;
  margin-left: ${(props) => (props.$isRTL ? 0 : 15)}px;
`;
