import { useMemo } from "react";
import {
  NETWORK_ACCEPTED_ADDRESSES_NEEDED,
  PaymentAcceptedAddress,
  PaymentIdentificationMode,
  PaymentNetwork,
} from "./customer-instruction";

export default function useNetworks(
  paymentNetworks: PaymentNetwork[],
  selectedPaymentNetwork: PaymentNetwork | null,
  concatNetworksIdentificationModes = false,
) {
  const networkAcceptedAddresses = useMemo(() => selectedPaymentNetwork?.acceptedAddresses, [selectedPaymentNetwork]);

  const canDefineAddress = useMemo(
    () => (networkAcceptedAddresses ? NETWORK_ACCEPTED_ADDRESSES_NEEDED.includes(networkAcceptedAddresses) : false),
    [networkAcceptedAddresses],
  );

  const networkIdentificationModes = useMemo(() => {
    if (concatNetworksIdentificationModes) {
      const identificationModes = [...paymentNetworks].reduce(
        (prev, curr) => prev.concat(curr.identificationMode || []),
        [] as PaymentNetwork["identificationMode"],
      );
      return [...new Set(identificationModes)];
    } else {
      return selectedPaymentNetwork?.identificationMode || [];
    }
  }, [paymentNetworks, concatNetworksIdentificationModes, selectedPaymentNetwork]);

  const isAddressUnstructured = useMemo(
    () =>
      canDefineAddress && networkAcceptedAddresses
        ? [
            PaymentAcceptedAddress.UNSTRUCTURED,
            PaymentAcceptedAddress.UNSTRUCTURED_OR_NULL,
            PaymentAcceptedAddress.BOTH,
          ].includes(networkAcceptedAddresses)
        : false,
    [canDefineAddress, networkAcceptedAddresses],
  );

  const isAddressOptional = useMemo(
    () => isAddressUnstructured && networkAcceptedAddresses === PaymentAcceptedAddress.UNSTRUCTURED_OR_NULL,
    [isAddressUnstructured, networkAcceptedAddresses],
  );

  const needIbanIdentification = useMemo(
    () => selectedPaymentNetwork?.identificationMode.includes(PaymentIdentificationMode.IBAN),
    [selectedPaymentNetwork],
  );

  return {
    networkAcceptedAddresses,
    canDefineAddress,
    networkIdentificationModes,
    isAddressUnstructured,
    isAddressOptional,
    needIbanIdentification,
  };
}
