import { BasicStorage } from "../../../shared/core/cache/basic-storage";

export class SessionStorage<T> implements BasicStorage<T> {
  async read(key: string): Promise<T | null> {
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }
  async store(data: T, key: string): Promise<void> {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
  async clear(key: string): Promise<void> {
    sessionStorage.removeItem(key);
  }
}
