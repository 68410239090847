import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";
import styled from "styled-components";
import { FormEmailInput, FormInputType } from "../../../../shared/core/data-forms/form-input-types";
import { LanguageTag } from "../../../../shared/core/i18n/translations";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import {
  configurationManager,
  customerActionService,
  featuresManager,
  i18NManager,
} from "../../../../shared/core/service/services";
import { formatAddress } from "../../../../shared/domains/client/address";
import { useClientDisplayName } from "../../../../shared/domains/client/client";
import { useClientLinks } from "../../../../shared/domains/client/use-client-links";
import { isDefined } from "../../../../shared/utils/assert";
import { getLocaleDateFromUtcDateString } from "../../../../shared/utils/date-to-local";
import { useObservable } from "../../../../shared/utils/observable";
import { ACCOUNT_DELETE_MODAL_ID, PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../../../core/modal/modal-id";
import { useClient } from "../../../domain/authentication/use-client";
import { changeLanguage } from "../../../domain/language/language";
import { useRTL } from "../../../domain/language/use-rtl";
import { Avatar } from "../../common/avatar/avatar";
import { PrimaryButton, WhitePrimaryButton } from "../../common/buttons/primary-button";
import { EmailField } from "../../common/data-forms/email-field";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/nav/page-header";
import { PageSectionTitle } from "../../common/nav/page-section-title";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { PincodeChangeModal } from "../pincode/pincode-change-modal";
import { AccountDeleteModal } from "./components/account-delete-modal";
import { InformationSection } from "./components/information-section";
import { SettingsActionItem, SettingsSelectItem } from "./components/settings-item";
const CheckIcon = require("../../../assets/images/svg/check-circle.svg");
const CloseIcon = require("../../../assets/images/svg/close-square.svg");

export const MyProfileScreen = () => {
  const { formatMessage, formatDate } = useIntl();
  const { client } = useClient();
  const displayName = useClientDisplayName(client);
  const configurationLanguages = useObservable(configurationManager.configuration).langs as LanguageTag[];
  const userLanguage = useObservable(i18NManager.localeTag);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [editingEmail, setEditingEmail] = React.useState(false);
  const features = useObservable(featuresManager.features);
  const { updateEmailAddressLink, canUpdateEmailAddress } = useClientLinks();
  const [successMsg, setSuccessMsg] = React.useState<string | null>();
  const [userEmail, setUserEmail] = React.useState<FormEmailInput>({
    type: FormInputType.Email,
    id: "email",
    label: "Email",
    value: client?.email || "",
  });

  const getLanguageName = (languageTag: string) => formatMessage(`common.languages.${languageTag}`);
  const { isRTL } = useRTL();

  const handleEditEmail = () => {
    setEditingEmail(!editingEmail);
    setUserEmail({
      ...userEmail,
      value: client?.email || "",
    });
    setTimeout(() => {
      if (emailRef.current) {
        emailRef.current.focus();
      }
    }, 100);
  };

  const handleSetUserEmail = (value: string) => {
    setUserEmail({
      ...userEmail,
      value,
    });
  };

  const handleSubmitNewEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (client && features?.sdaCustomerOnboardingActionsInitiate && userEmail.value !== client.email) {
      const url = updateEmailAddressLink(client);
      if (url) {
        setIsSubmitting(true);
        emailRef.current?.blur();
        try {
          await customerActionService.makeCustomerAction(url, { email: userEmail.value });
          setSuccessMsg(formatMessage("clientInformation.emailSuccess"));
        } catch (e) {
          console.log("catch", e);
        } finally {
          handleEditEmail();
          setIsSubmitting(false);
        }
      }
    } else {
      handleEditEmail();
    }
  };

  return (
    <>
      {!!successMsg && (
        <SuccessMsg>
          <img src={CheckIcon} alt="" />
          {successMsg}
          <CloseButton onClick={() => setSuccessMsg(null)}>
            <img src={CloseIcon} alt="" />
          </CloseButton>
        </SuccessMsg>
      )}
      <Page>
        <Profile $isRTL={isRTL}>
          <PageHeader>
            <PageSectionTitle>{formatMessage("clientInformation.title")}</PageSectionTitle>
          </PageHeader>
          {isDefined(client) && (
            <ProfileCard>
              <NameContainer>
                <ProfileAvatar size={40} name={displayName} $isRTL={isRTL} />
                <Name>{displayName}</Name>
              </NameContainer>
              {client.birthDate ? (
                <StyledInformationSection
                  label={formatMessage("clientInformation.birthDateTitle")}
                  value={formatDate(getLocaleDateFromUtcDateString(client.birthDate), {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                />
              ) : null}
              <StyledInformationSection
                label={formatMessage("clientInformation.birthPlaceTitle")}
                value={client.birthPlace}
              />
              <StyledInformationSection
                label={formatMessage("clientInformation.addressTitle")}
                value={formatAddress(client.address)}
              />
              <StyledInformationSection
                label={formatMessage("clientInformation.phoneTitle")}
                value={
                  client.contactphone ? parsePhoneNumber(`+${client.contactphone}`).formatInternational() : undefined
                }
                isPhone={true}
              />
              <StyledInformationSection
                label={formatMessage("clientInformation.emailTitle")}
                value={client.email}
                onEdit={
                  canUpdateEmailAddress(client) && features?.sdaCustomerOnboardingActionsInitiate
                    ? () => handleEditEmail()
                    : undefined
                }>
                {editingEmail ? (
                  <form
                    style={{
                      pointerEvents: isSubmitting ? "none" : "auto",
                    }}
                    onSubmit={(e) => (canUpdateEmailAddress(client) ? handleSubmitNewEmail(e) : null)}>
                    <>
                      <EmailField ref={emailRef} input={userEmail} onChange={handleSetUserEmail} />
                      <StyledActionWrapper>
                        <WhitePrimaryButton
                          disabled={isSubmitting}
                          type="submit"
                          style={{ padding: "5px 20px" }}
                          size="S">
                          {formatMessage("common.validate")}
                        </WhitePrimaryButton>
                        <PrimaryButton type="button" style={{ padding: "5px 20px" }} size="S" onClick={handleEditEmail}>
                          {formatMessage("common.cancel")}
                        </PrimaryButton>
                      </StyledActionWrapper>
                    </>
                  </form>
                ) : null}
              </StyledInformationSection>
            </ProfileCard>
          )}
        </Profile>
        <Settings $isRTL={isRTL}>
          <PageHeader>
            <PageSectionTitle>{formatMessage("settings.title")}</PageSectionTitle>
          </PageHeader>
          <SettingsCards>
            <ActionItem
              title={formatMessage("settings.changePincodeTitle")}
              subtitle={formatMessage("settings.changePincodeDescription")}
              onClick={() =>
                Modal.present(PINCODE_MUST_BE_CHANGED_MODAL_ID, () => <PincodeChangeModal type="skip-explanation" />, {
                  canBeDismissed: false,
                })
              }
            />
            <SettingsSelectItem
              title={formatMessage("settings.changeLanguageTitle")}
              subtitle={formatMessage("settings.changeLanguageDescription")}
              selectInputProps={{
                innerId: "language",
                value: userLanguage,
                onChange: (language) => changeLanguage(language),
                options: configurationLanguages,
                itemRenderer: getLanguageName,
                style: { marginTop: "10px" },
              }}
            />
            <ActionItem
              style={{ marginTop: "24px" }}
              title={formatMessage("settings.accountDeleteTitle")}
              subtitle={formatMessage("settings.accountDeleteDescription")}
              onClick={() => {
                Modal.present(ACCOUNT_DELETE_MODAL_ID, () => <AccountDeleteModal />, {
                  canBeDismissed: true,
                });
              }}
            />
          </SettingsCards>
        </Settings>
      </Page>
    </>
  );
};

const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  ${shadows.medium};
  border-radius: 18px;
  background-color: #ffffff;
  margin-bottom: 20px;
`;

const Profile = styled.div<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 20)}px;

  width: ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px;
  }
`;

const ProfileAvatar = styled(Avatar)<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
const Name = styled.span`
  ${theme.mediumText};
  color: #000000;
  font-size: 0.9375rem;
`;

const StyledInformationSection = styled(InformationSection)`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const Settings = styled.div<{ $isRTL: boolean }>`
  margin-left: ${(props) => (props.$isRTL ? 20 : 0)}px;

  width: ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px;
  }
`;

const SettingsCards = styled.div`
  padding: 25px;
  ${shadows.medium};
  border-radius: 18px;
  background-color: #ffffff;
`;

const ActionItem = styled(SettingsActionItem)`
  margin-bottom: 20px;
`;

const Page = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 45px;
  grid-column-gap: 45px;
  grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px);
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px);
  }
`;

const StyledActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const SuccessMsg = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: ${theme.colors.info[300]};
  color: ${theme.colors.info[500]};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  ${shadows.medium}
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  justify-self: flex-end;
  margin-left: 10px;
`;
