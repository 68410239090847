import { AxiosRequestConfig } from "axios";
import { I18NStore } from "../../i18n/i18n-store";
import { Interceptor } from "./interceptor";

export const addAcceptLanguageInterceptor: (store: I18NStore) => Interceptor<AxiosRequestConfig> = (store) => ({
  onFulfilled: (config) => ({
    ...config,
    headers: { "Accept-Language": store.localeTag.get(), ...config.headers },
  }),
});
