import React, { useCallback, useState } from "react";
import { atLeastOneIsDefined, isDefined } from "../../../../../shared/utils/assert";

import { Avatar } from "../../../common/avatar/avatar";
import { DedicatedTransferTransaction } from "./dedicated-transfer-modal";
import { DocumentIcon } from "../../../common/svg/document-icon";
import { HomeIcon } from "../../../common/svg/home-icon";
import { Modal } from "../../../common/modal/modal";
import { Path } from "../../../../core/routing/path";
import { PhoneIcon } from "../../../common/svg/phone-icon";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { Recipient } from "../../../../../shared/domains/recipients/recipient";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { TRANSFER_MODAL_ID } from "../../../../core/modal/modal-id";
import { TransferTransaction } from "./transfer-modal";
import { featuresManager } from "../../../../../shared/core/service/services";
import { formatPhoneNumber } from "../../../../../shared/utils/phone-number";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { useLocation } from "react-router-dom";
import { useObservable } from "../../../../../shared/utils/observable";

export interface RecipientDetailProps {
  startTransfer: () => void;
  startDedicatedTransfer: () => void;
  recipient: Recipient;
}

export const RecipientDetailModal = (props: { recipient: Recipient }) => {
  const { recipient } = props;
  const [transferMode, setTransferMode] = useState(false);
  const [dedicatedTransferMode, setDedicatedTransferMode] = useState(false);
  const closeModal = useCallback(() => Modal.dismiss(TRANSFER_MODAL_ID), []);

  return (
    <RoundedModalContainer closeButton id={TRANSFER_MODAL_ID}>
      {transferMode ? (
        <TransferTransaction showAvatar showTitle recipient={recipient} closeModal={closeModal} />
      ) : dedicatedTransferMode ? (
        <DedicatedTransferTransaction recipient={recipient} closeModal={closeModal} />
      ) : (
        <RecipientDetail
          recipient={recipient}
          startTransfer={() => setTransferMode(true)}
          startDedicatedTransfer={() => setDedicatedTransferMode(true)}
        />
      )}
    </RoundedModalContainer>
  );
};

const RecipientDetail = (props: RecipientDetailProps) => {
  const { recipient, startTransfer, startDedicatedTransfer } = props;
  const { formatMessage } = useIntl();
  const hasAccountInformation = atLeastOneIsDefined([recipient.iban, recipient.bankName, recipient.bic]);
  const location = useLocation();
  const features = useObservable(featuresManager.features);

  const canTransfer = location.pathname === Path.Transfer;
  const canDedicatedTransfer = features.p2pDedicatedTransfer;

  return (
    <Container>
      <Centered>
        <Avatar size={72} name={recipient.name} />
        <RecipientName>{recipient.name}</RecipientName>
        {canTransfer && (
          <>
            <TransferMoneyButton onClick={startTransfer} size="S">
              {formatMessage("recipientDetail.transferMoneyButton")}
            </TransferMoneyButton>
            <div style={{ height: 20 }} />
            {canDedicatedTransfer && (
              <PrimaryButton size="S" onClick={startDedicatedTransfer}>
                {formatMessage("recipientDetail.dedicatedTransferButton")}
              </PrimaryButton>
            )}
          </>
        )}
      </Centered>
      <RecipientDetailContainer>
        {isDefined(recipient.phone) && (
          <>
            <RecipientDetailTitle>{formatMessage("recipientDetail.information")}</RecipientDetailTitle>
            <DetailRow type="phone" label={formatPhoneNumber(recipient.phone)} />
          </>
        )}
        {hasAccountInformation && (
          <>
            <RecipientDetailTitle>{formatMessage("recipientDetail.accountDetail")}</RecipientDetailTitle>
            {isDefined(recipient.iban) && <DetailRow type="iban" label={recipient.iban} />}
            {isDefined(recipient.bic) && <DetailRow type="bic" label={recipient.bic} />}
            {isDefined(recipient.accountReference) && <DetailRow type="bic" label={recipient.accountReference} />}
            {isDefined(recipient.bankName) && <DetailRow type="bankName" label={recipient.bankName} />}
          </>
        )}
      </RecipientDetailContainer>
    </Container>
  );
};

const Container = styled.div``;
const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RecipientName = styled.span`
  font-size: 1.125rem;
  ${theme.boldText};
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const RecipientDetailTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.125rem;
  ${theme.boldText};
`;

const RecipientInformation = styled.span`
  ${theme.mediumText};
  font-size: 0.8125rem;
  color: #b1b1b1;
`;

const RecipientDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;
`;

const TransferMoneyButton = styled(PrimaryButton)``;

const iconProps = { fill: "#b1b1b1", width: 20, height: 20 };
const recipientInfoType = {
  phone: { icon: <PhoneIcon {...iconProps} /> },
  iban: { icon: <DocumentIcon {...iconProps} /> },
  bic: { icon: <DocumentIcon {...iconProps} /> },
  accountRef: { icon: <DocumentIcon {...iconProps} /> },
  bankName: { icon: <HomeIcon {...iconProps} /> },
};

interface DetailRowProps {
  type: "phone" | "iban" | "bic" | "bankName" | "accountRef";
  label: string;
}

const DetailRow = (props: DetailRowProps) => {
  const { type, label } = props;

  return (
    <Row>
      <IconContainer>{recipientInfoType[type].icon}</IconContainer>
      <RecipientInformation dir={"ltr"}>{label}</RecipientInformation>
    </Row>
  );
};
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
const IconContainer = styled.div`
  padding-right: 10px;
`;
