import React, { HTMLAttributes, MouseEventHandler, useRef, useState } from "react";
import { Amount, sanitizeAmountValue } from "../../../../../shared/core/amount/amount";
import { cardFilterManager, transactionSearchManager } from "../../../../../shared/core/service/services";
import {
  useFormattedAmountRange,
  useTransactionDateFilter,
} from "../../../../../shared/domains/accounting-transaction/transaction/use-transaction-filter";
import { Input, TextInput } from "../../../common/forms/text-input";

import moment from "moment";
import styled from "styled-components";
import { Currencies } from "../../../../../shared/core/currency/currency";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { Account } from "../../../../../shared/domains/account/account";
import { TimeRange } from "../../../../../shared/domains/accounting-transaction/search/timerange";
import { transactionDirectionLabels } from "../../../../../shared/domains/accounting-transaction/search/transaction-filter-labels";
import { timeRangeLabel } from "../../../../../shared/domains/accounting-transaction/transaction/timerange-label";
import { TransactionDirection } from "../../../../../shared/domains/accounting-transaction/transaction/transaction-filter";
import { CardStatus } from "../../../../../shared/domains/cards/card-filter";
import { cardStatusLabels } from "../../../../../shared/domains/cards/card-filter-labels";
import { useObservable } from "../../../../../shared/utils/observable";
import { useClickOutsideHandler } from "../../../../utils/use-outside-click";
import { RoundActionButton } from "../../../common/buttons/round-button";
import { TertiaryDiv } from "../../../common/buttons/tertiary-button";
import { DateInput } from "../../../common/forms/date-input";
import { CheckIcon } from "../../../common/svg/check-icon";
import { ChevronDown } from "../../../common/svg/chevron-down";
import { theme } from "../../../styles/theme";

interface FilterButtonProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  active?: boolean;
}

export const FilterDropdown = (props: FilterButtonProps) => {
  const { children, onClick, active, ...rest } = props;
  return (
    <FilterButtonContainer {...rest} onClick={onClick} active={active}>
      {children}
      <ChevronDownIcon />
    </FilterButtonContainer>
  );
};

const timeranges = [TimeRange.Quarter, TimeRange.Month, TimeRange.Week];
const PressableRow = styled.div`
  padding: 5px 10px 5px 10px;
  :focus,
  :active,
  :hover {
    background-color: #e2e2e2;
    cursor: pointer;
  }
`;

export const TimeRangeFilterButton = (props: HTMLAttributes<HTMLDivElement>) => {
  const dropdownContentRef = useRef(null);
  useClickOutsideHandler(dropdownContentRef, () => setShowDropdown(false));
  const [showDropdown, setShowDropdown] = useState(false);
  const { formatMessage, formatDate } = useIntl();
  const timerangeSelection = useObservable(transactionSearchManager.timeRangeSelection);
  const { startDate, endDate, onStartDateChange, onEndDateChange } = useTransactionDateFilter();
  const hasDateCustomFilter = timerangeSelection.timeRange === TimeRange.Other;
  const [fromDate, toDate] = timerangeSelection.bounds;
  const dateFormatter = new Intl.DateTimeFormat(undefined, { day: "2-digit", month: "2-digit", year: "2-digit" });
  const label = hasDateCustomFilter
    ? `${fromDate ? dateFormatter.format(fromDate.toDate()) : "--/--/----"} - ${
        toDate ? dateFormatter.format(toDate.toDate()) : "--/--/----"
      }`
    : formatMessage(timeRangeLabel[timerangeSelection.timeRange]);

  const applyCustomTimerange = () => {
    transactionSearchManager.setTimeRange(TimeRange.Other, [moment(startDate), moment(endDate)]);
    setShowDropdown(false);
  };
  return (
    <FilterDropdown
      {...props}
      onClick={() => {
        setShowDropdown((show) => !show);
      }}
      active={timerangeSelection.timeRange !== TimeRange.Quarter}>
      {label}
      <DropdownContent ref={dropdownContentRef} $isVisible={showDropdown} onClick={(e) => e.stopPropagation()}>
        {timeranges.map((timerange) => (
          <PressableRow
            key={timerange}
            onClick={() => {
              transactionSearchManager.setTimeRange(timerange);
              setShowDropdown(false);
            }}>
            {formatMessage(timeRangeLabel[timerange])}
          </PressableRow>
        ))}
        <Panel>
          <CustomDateTitle>{formatMessage("transactionFilter.timeRangeSelectionTitle")}</CustomDateTitle>
          <Row>
            <Column>
              <DateContainer>
                <DateCaption>{formatMessage("transactionFilter.timeRange.from")}</DateCaption>
                <StyledDateInput
                  mode="date"
                  withoutTheme
                  onChange={(e) => {
                    const date = moment(e.target.value);
                    if (date.isValid()) {
                      onStartDateChange(date.toDate());
                    }
                  }}
                  value={startDate ? moment(startDate).format("YYYY-MM-DD") : ""}
                />
              </DateContainer>
              <DateContainer>
                <DateCaption>{formatMessage("transactionFilter.timeRange.to")}</DateCaption>
                <StyledDateInput
                  mode="date"
                  withoutTheme
                  onChange={(e) => {
                    const date = moment(e.target.value);
                    if (date.isValid()) {
                      onEndDateChange(date.toDate());
                    }
                  }}
                  value={endDate ? moment(endDate).format("YYYY-MM-DD") : ""}
                />
              </DateContainer>
            </Column>
            <CheckContainer>
              <RoundActionButton size={30} onClick={applyCustomTimerange}>
                <SmallCheckIcon />
              </RoundActionButton>
            </CheckContainer>
          </Row>
        </Panel>
      </DropdownContent>
    </FilterDropdown>
  );
};

interface AmountRangeFilterProps extends HTMLAttributes<HTMLDivElement> {
  account: Account | null;
}
export const AmountRangeFilter = (props: AmountRangeFilterProps) => {
  const { account, ...rest } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownContentRef = useRef(null);
  useClickOutsideHandler(dropdownContentRef, () => setShowDropdown(false));
  const { formatMessage } = useIntl();
  const { formattedAmountRange, hasAmountFilter } = useFormattedAmountRange(account);
  return (
    <FilterDropdown
      {...rest}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setShowDropdown((show) => !show);
        }
      }}
      active={hasAmountFilter}>
      {hasAmountFilter ? formattedAmountRange : formatMessage("transactionFilter.allAmountRange")}
      <DropdownContent
        ref={dropdownContentRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        $isVisible={showDropdown}>
        {account && <AmountSelection account={account} onSubmit={() => setShowDropdown(false)} />}
      </DropdownContent>
    </FilterDropdown>
  );
};
interface AmountSelectionProps {
  account: Account;
  onSubmit: () => void;
}
const AmountSelection = (props: AmountSelectionProps) => {
  const { account, onSubmit } = props;
  const currency = account.balance.currency;
  const [initialLow, initialHigh] = transactionSearchManager.amountRange.get();
  const [lowTextValue, setLowTextValue] = useState<string>(initialLow ? initialLow.toString() : "");
  const [highTextValue, setHighTextValue] = useState<string>(initialHigh ? initialHigh.toString() : "");
  const { formatAmount, formatMessage } = useIntl();
  const applyAmountFilter = () => {
    const lowValue = Number.parseFloat(lowTextValue);
    const highValue = Number.parseFloat(highTextValue);
    transactionSearchManager.setAmountRange(
      Number.isNaN(lowValue) ? null : lowValue,
      Number.isNaN(highValue) || highValue === 0 ? null : highValue,
    );
    onSubmit();
  };
  return (
    <AmountSelectionContainer>
      <CustomDateTitle>{formatMessage("transactionFilter.amountRangeSelectionTitle")}</CustomDateTitle>
      <Row>
        <Column>
          <AmountInputContainer>
            <AmountInputCaption>{formatMessage("transactionFilter.amountRange.min")}</AmountInputCaption>
            <StyledAmountInput
              value={lowTextValue}
              placeholder={formatAmount(new Amount(0, currency))}
              onChange={(e) => {
                const safeValue = sanitizeAmountValue(e.target.value, Currencies[currency].decimal_number);
                setLowTextValue(safeValue);
              }}
            />
          </AmountInputContainer>
          <AmountInputContainer>
            <AmountInputCaption>{formatMessage("transactionFilter.amountRange.max")}</AmountInputCaption>
            <StyledAmountInput
              value={highTextValue}
              placeholder={formatAmount(new Amount(0, currency))}
              onChange={(e) => {
                const safeValue = sanitizeAmountValue(e.target.value, Currencies[currency].decimal_number);
                setHighTextValue(safeValue);
              }}
            />
          </AmountInputContainer>
        </Column>
        <CheckContainer>
          <RoundActionButton size={30} onClick={applyAmountFilter}>
            <SmallCheckIcon />
          </RoundActionButton>
        </CheckContainer>
      </Row>
    </AmountSelectionContainer>
  );
};

export const TransactionDirectionFilter = (props: HTMLAttributes<HTMLDivElement>) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownContentRef = useRef(null);
  useClickOutsideHandler(dropdownContentRef, () => setShowDropdown(false));
  const { formatMessage } = useIntl();
  const currentDirection = useObservable(transactionSearchManager.directionSelection);
  return (
    <FilterDropdown
      {...props}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setShowDropdown((show) => !show);
        }
      }}
      active={currentDirection !== TransactionDirection.ALL}>
      {formatMessage(transactionDirectionLabels[currentDirection])}
      <DropdownContent
        ref={dropdownContentRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        $isVisible={showDropdown}>
        {[TransactionDirection.ALL, TransactionDirection.OUTCOME, TransactionDirection.INCOME].map((direction) => (
          <PressableRow
            key={direction}
            onClick={() => {
              transactionSearchManager.setTransactionDirection(direction);
              setShowDropdown(false);
            }}>
            {formatMessage(transactionDirectionLabels[direction])}
          </PressableRow>
        ))}
      </DropdownContent>
    </FilterDropdown>
  );
};

export const CardsStatusFilter = (props: HTMLAttributes<HTMLDivElement>) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownContentRef = useRef(null);
  useClickOutsideHandler(dropdownContentRef, () => setShowDropdown(false));
  const { formatMessage } = useIntl();
  const currentStatus = useObservable(cardFilterManager.statusSelection);
  return (
    <FilterDropdown
      {...props}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setShowDropdown((show) => !show);
        }
      }}
      active={currentStatus !== CardStatus.ALL}>
      {formatMessage(cardStatusLabels[currentStatus])}
      <DropdownContent
        ref={dropdownContentRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        $isVisible={showDropdown}>
        {[CardStatus.NEW, CardStatus.PENDING, CardStatus.ACTIVE, CardStatus.BLOCKED, CardStatus.OPPOSED].map(
          (status) => (
            <PressableRow
              key={status}
              onClick={() => {
                cardFilterManager.setStatusSelection(status);
                setShowDropdown(false);
              }}>
              {formatMessage(cardStatusLabels[status])}
            </PressableRow>
          ),
        )}
      </DropdownContent>
    </FilterDropdown>
  );
};

const AmountInputCaption = styled.div`
  display: flex;
  flex: 1 1 auto;
  ${theme.text};
`;

const AmountInputContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const AmountSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
`;

const StyledAmountInput = styled(TextInput)`
  padding: 0px;
  margin-left: 10px;
  margin-right: 10px;
  ${Input} {
    width: 80px;
  }
`;
const DropdownContent = styled.div<{ $isVisible: boolean }>`
  display: ${(props) => (props.$isVisible ? "block" : "none")};
  overflow: hidden;
  text-align: start;
  position: absolute;
  top: 120%;
  left: 0px;
  background-color: #f4f4f4;
  border-radius: 10px;
  z-index: 1;
  min-width: 100%;
  :hover {
    cursor: auto;
  }
`;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
  margin: 5px;
  border-radius: 10px;
  background-color: #ffffff;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const CheckContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const CustomDateTitle = styled.div`
  margin-bottom: 10px;
`;
const DateCaption = styled.div`
  flex-shrink: 0;
  margin-right: 5px;
  ${theme.text}
`;
const DateContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
const FilterButtonContainer = styled(TertiaryDiv)<{ active?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background-color: ${(props) => (props.active ? "#e4e4e4" : "#f4f4f4")};
  :hover {
    cursor: pointer;
  }
`;

const ChevronDownIcon = styled(ChevronDown)`
  margin-left: 6px;
  margin-right: 6px;
  pointer-events: none;
  width: 15px;
`;

const SmallCheckIcon = styled(CheckIcon)`
  width: 14px;
  height: 14px;
`;
const StyledDateInput = styled(DateInput)`
  min-width: 120px;
  background-color: #f4f4f4;
`;
