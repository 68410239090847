import { BasicSecureStorage } from "../../../../mobile/core/cache/basic-secure-storage";
import { Amount } from "../../../core/amount/amount";
import { BasicStorage } from "../../../core/cache/basic-storage";
import { TransactionGeolocationManager } from "../../../core/geolocalisation/transaction-geolocalisation-manager";
import { runAfterInteractions } from "../../../core/interaction/interaction-manager";
import { AccountManager } from "../../account/account-manager";
import { TransactionsManager } from "../../accounting-transaction/transaction/transactions-manager";
import { PincodeSubmission } from "../../pincode/pincode";
import { getPspRedirectUrl } from "../recharge-by-card/psp-redirect-url";
import { ConfirmationMode } from "../transaction-request";
import { BankToWalletService } from "./bank-to-wallet-service";
import { PspOperationResult } from "./psp-transaction-request";

export interface PendingRechargeTransaction {
  accountId: string;
  amount: Amount;
}

const PENDING_PSP_RECHARGE_KEYSTORE_KEY = "pending-psp-recharge";

export class BankToWalletManager {
  public constructor(
    private bankToWalletService: BankToWalletService,
    private storage: BasicStorage<string | null> | BasicSecureStorage,
    private geolocationManager: TransactionGeolocationManager,
    private accountManager: AccountManager,
    private transactionsManager: TransactionsManager,
  ) {}

  async startBankToWallet(amount: Amount, creditorReference: string, debtorReference?: string) {
    const location = await this.geolocationManager.updatePosition();
    return await this.bankToWalletService.startBankToWallet(amount, creditorReference, debtorReference, location);
  }

  async confirmBankToWallet(
    confirmationMode: ConfirmationMode,
    amount: Amount,
    creditorReference: string,
    debtorReference?: string,
    pincode?: PincodeSubmission,
  ) {
    const location = this.geolocationManager.getPosition();
    const result = await this.bankToWalletService.confirmBankToWallet(
      confirmationMode,
      amount,
      creditorReference,
      debtorReference,
      pincode,
      location,
    );
    return result;
  }

  async confirmRechargeByCard(amount: Amount, accountId: string) {
    const location = this.geolocationManager.getPosition();
    const result = await this.bankToWalletService.confirmRechargeByCard(
      amount,
      accountId,
      getPspRedirectUrl(),
      location,
    );
    if (result.data.pspResult !== PspOperationResult.Failure) {
      await this.savePendingRechargeTransaction({ accountId: accountId, amount: result.metadata.transaction.amount! });
    }
    return result;
  }

  async completePspTransaction(): Promise<PendingRechargeTransaction | null> {
    const pendingRecharge = await this.loadPendingRechargeTransaction();

    if (pendingRecharge) {
      this.refreshAccountAndTransactions(pendingRecharge.accountId);
    }
    return pendingRecharge;
  }

  private refreshAccountAndTransactions(accountId: string) {
    runAfterInteractions(async () => {
      await this.accountManager.refresh();
      await this.transactionsManager.refresh([accountId]);
    });
  }

  private async loadPendingRechargeTransaction(): Promise<PendingRechargeTransaction | null> {
    let storedValue;
    try {
      storedValue = await this.storage.read(PENDING_PSP_RECHARGE_KEYSTORE_KEY);
    } catch (e) {
      storedValue = null;
    }
    if (storedValue) {
      const parsed: PendingRechargeTransaction = JSON.parse(storedValue);
      return parsed;
    }
    return null;
  }

  private async savePendingRechargeTransaction(pendingTransaction: PendingRechargeTransaction) {
    await this.storage.store(JSON.stringify(pendingTransaction), PENDING_PSP_RECHARGE_KEYSTORE_KEY);
  }
}
