import React, { SVGProps } from "react";

export function SmallCrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 19 19" width="20" height="20" {...props}>
      <path
        fill="#000000"
        fillRule="nonzero"
        d="M13.623 0C16.843 0 19 2.26 19 5.624v7.761C19 16.74 16.843 19 13.623 19H5.386C2.166 19 0 16.739 0 13.385V5.624C0 2.26 2.166 0 5.386 0zm-6.99 6.626c-.324.324-.324.852 0 1.176l1.69 1.69-1.687 1.687c-.325.325-.325.852 0 1.175.163.163.375.244.587.244.214 0 .426-.08.588-.243L9.5 10.668l1.69 1.69c.163.162.375.243.588.243.213 0 .425-.082.588-.243.325-.325.325-.851 0-1.176l-1.69-1.69 1.689-1.688c.325-.324.325-.852 0-1.176-.325-.326-.85-.325-1.175 0l-1.69 1.689-1.69-1.691c-.325-.325-.852-.323-1.176 0z"
      />
    </svg>
  );
}
