import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { theme } from "../../styles/theme";

interface PincodeChangeExplanationProps {
  type: "skip-explanation" | "operation-requirement" | "after-authentication";
  startPincodeChange: () => void;
  abort: () => void;
}
export const PincodeChangeExplanation = (props: PincodeChangeExplanationProps) => {
  const { startPincodeChange, abort, type } = props;
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Title>{formatMessage("pincodeChangeScreen.title")}</Title>
      <Subtitle>{formatMessage("pincodeChangeScreen.subtitle")}</Subtitle>
      <Content>
        <ExplanationMessage>
          {type === "after-authentication"
            ? formatMessage("pincodeChangeScreen.messageAfterAuthentication")
            : formatMessage("pincodeChangeScreen.message")}
        </ExplanationMessage>
        <ActionButton size="S" onClick={startPincodeChange}>
          {formatMessage("pincodeChangeScreen.changePincodeButton")}
        </ActionButton>
        <ActionButton size="S" onClick={abort}>
          {formatMessage("pincodeChangeScreen.laterButton")}
        </ActionButton>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 30px 30px 30px;
`;

const Title = styled.span`
  ${theme.boldText};
  font-size: 1.375rem;
`;

const Subtitle = styled.span`
  color: #b1b1b1;
  font-size: 0.9375rem;
`;
const ExplanationMessage = styled.span`
  color: #b1b1b1;
  ${theme.boldText};
  text-align: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`;

const ActionButton = styled(PrimaryButton)`
  &:not(:last-of-type) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;
