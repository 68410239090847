import React, { SVGProps } from "react";

export function AttachmentIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="20" height="16" viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 3C19.1046 3 20 3.89543 20 5V14C20 15.1046 19.1046 16 18 16H2C0.89543 16 0 15.1046 0 14V5C0 3.89543 0.89543 3 2 3H3.17C3.70304 3.00042 4.2142 2.78804 4.59 2.41L6.12 0.88C6.68207 0.31723 7.44462 0.000700208 8.24 0H11.76C12.5554 0.000700208 13.3179 0.31723 13.88 0.88L15.41 2.41C15.7858 2.78804 16.297 3.00042 16.83 3H18ZM6 9C6 11.2091 7.79086 13 10 13C11.0609 13 12.0783 12.5786 12.8284 11.8284C13.5786 11.0783 14 10.0609 14 9C14 6.79086 12.2091 5 10 5C7.79086 5 6 6.79086 6 9Z"
      />
    </svg>
  );
}
