import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { Paginated } from "../../utils/pagination";
import { PincodeSubmission } from "../pincode/pincode";
import { StrongAuthenticationRequest } from "./strong-customer-authentication";

export type StrongAuthenticationSearchQuery = {
  search?: {
    filter?: {
      search?: string;
    };
    pager?: {
      from: number;
      size: number;
    };
  };
};

export class StrongAuthenticationService {
  public constructor(private apiService: ConnectedApiService) {}

  public async list(query?: StrongAuthenticationSearchQuery): Promise<Paginated<StrongAuthenticationRequest>> {
    try {
      const response = await this.apiService.instance.get("/authentications", {
        params: query,
      });
      return response.data;
    } catch (e) {
      logger.debug("StrongAuthenticationService", "Failed list authentications", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async get(id: number): Promise<StrongAuthenticationRequest> {
    try {
      const response = await this.apiService.instance.get(`/authentications/${id}`);
      return response.data;
    } catch (e) {
      logger.debug("StrongAuthenticationService", "Failed get authentication", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async accept(id: number, pincode: PincodeSubmission): Promise<StrongAuthenticationRequest> {
    try {
      const response = await this.apiService.instance.post(`/authentications/${id}/accept`, pincode);
      return response.data;
    } catch (e) {
      logger.debug("StrongAuthenticationService", "Failed accept authentication", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async refuse(id: number, pincode: PincodeSubmission): Promise<StrongAuthenticationRequest> {
    try {
      const response = await this.apiService.instance.post(`/authentications/${id}/refuse`, pincode);
      return response.data;
    } catch (e) {
      logger.debug("StrongAuthenticationService", "Failed refuse authentication", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }
}
