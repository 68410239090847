import styled, { css } from "styled-components";
import { DefaultButton } from "../buttons/default-button";

const commonStyle = (size: number) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: ${size}px;
  width: ${size}px;
  border-radius: 10px;
`;
export const RoundedSquare = styled.div<{ size: number }>`
  ${(props) => commonStyle(props.size)};
`;

export const RoundedSquareButton = styled(DefaultButton)<{ size: number }>`
  ${(props) => commonStyle(props.size)};
  :focus,
  :active,
  :hover {
    background-color: #e2e2e2;
  }
  :disabled {
    opacity: 0.5;
  }
`;
