import { Config } from "../../../shared/core/config/config";
import { I18NManager } from "../../../shared/core/i18n/i18n-manager";
import { logger } from "../../../shared/core/logging/logger";
import { AuthenticationManager } from "../../../shared/domains/authentication/authentication-manager";
import { ClientManager } from "../../../shared/domains/client/client-manager";
import { ConfigurationManager } from "../../../shared/domains/configuration/configuration-manager";
import { ServiceDomainManager } from "../../../shared/domains/service-domain/service-domain-manager";
import { Observable } from "../../../shared/utils/observable";
import { updateTheme } from "../../ui/styles/theme";
import { SessionStorage } from "../cache/session-storage";

const HAS_PROMPT_PINCODE_CHANGED_KEY = "has_prompt_pincode_changed";
export class WebAppStartManager {
  public constructor(
    private configurationManager: ConfigurationManager,
    private i18nManager: I18NManager,
    private authenticationManager: AuthenticationManager,
    private clientManager: ClientManager,
    private sessionStorage: SessionStorage<boolean>,
    private serviceDomainManager: ServiceDomainManager,
  ) {
    this.addLoginTasks();
    this.notifyWhenClientMustChangePincode();
    this.notifyLocaleChange();
  }

  shouldPromptPincodeChange = new Observable(false);

  async startApp() {
    await this.initServices();
  }

  public async loadServiceDomainToken() {
    if (Config.SERVICE_DOMAIN_API_ID && Config.SERVICE_DOMAIN_API_SECRET) {
      await this.serviceDomainManager.getToken();
    }
  }

  private async initServices() {
    try {
      await Promise.all([
        this.authenticationManager.initialize(),
        this.configurationManager.load(),
        this.loadServiceDomainToken(),
      ]);
    } catch (e) {
      await this.i18nManager.initialize(null);
      await this.authenticationManager.logout();
      logger.error("Error while initializing services", e);
      // window.location.reload();
    }
    updateTheme();
    await this.i18nManager.initialize(this.clientManager.client.get());
  }

  private addLoginTasks() {
    this.authenticationManager.isConnected.onChange.add(() => {
      this.clientManager.updateClient(true);
    });
  }

  private notifyWhenClientMustChangePincode() {
    this.clientManager.client.onChange.add(async (client) => {
      if (client?.mustChangePincode) {
        const hasAlreadyPromptPincodeChange = await this.sessionStorage.read(HAS_PROMPT_PINCODE_CHANGED_KEY);
        if (!hasAlreadyPromptPincodeChange) {
          this.shouldPromptPincodeChange.set(true);
        }
      }
    });
  }

  private notifyLocaleChange() {
    this.clientManager.client.onChange.add(async (client) => {
      const currentLocale = this.i18nManager.localeTag.get();
      if (client?.lang !== currentLocale) {
        this.i18nManager.initialize(client);
      }
    });
  }

  hasPromptPincodeChange() {
    this.shouldPromptPincodeChange.set(false);
    this.sessionStorage.store(true, HAS_PROMPT_PINCODE_CHANGED_KEY);
  }
}
