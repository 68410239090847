import React, { HTMLAttributes, useState } from "react";

import { ClipboardIcon } from "../../../common/svg/clipboard-icon";
import ReactTooltip from "react-tooltip";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import copy from "copy-to-clipboard";
import { getRandomID } from "../../../../../shared/utils/random";
import styled from "styled-components";
import { theme } from "../../../styles/theme";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { useTooltip } from "../../../../utils/use-tooltip";

interface ClipboardInformationRowProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  title: string;
  noCopy?: boolean;
}

export const ClipboardInformationRow = (props: ClipboardInformationRowProps) => {
  const { title, value, noCopy, ...rest } = props;
  const { elementRef, showTooltip } = useTooltip<HTMLButtonElement>();
  const { formatMessage } = useIntl();
  const [uid] = useState(getRandomID());
  const copyToClipboard = () => {
    copy(value);
    showTooltip();
  };
  return (
    <Row {...rest}>
      <Column>
        <Title>{title}</Title>
        <Value>{value}</Value>
      </Column>
      {!noCopy && (
        <GreyRoundedSquare
          data-for={uid}
          ref={elementRef}
          size={34}
          data-tip={formatMessage("accountDetails.clipboard", { name: title })}
          onClick={copyToClipboard}>
          <ClipboardIcon />
        </GreyRoundedSquare>
      )}
      <ReactTooltip id={uid} place="top" type="dark" effect="solid" event={"none"} />
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  ${theme.text};
  font-size: 0.9375rem;
  color: #b1b1b1;
`;

const Value = styled.span`
  ${theme.boldText};
  font-size: 0.9375rem;
`;

const GreyRoundedSquare = styled(RoundedSquareButton)`
  background-color: #f4f4f4;
`;
