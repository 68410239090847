import React from "react";
import { SVGProps } from "react";

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <mask id="ldqh4dtm5a" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20" fill="#000">
      <path fill="#fff" d="M2 2h20v20H2z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a.31.31 0 0 0-.31.31v8.38H3.31a.31.31 0 1 0 0 .62h8.38v8.38a.31.31 0 1 0 .62 0v-8.38h8.38a.31.31 0 0 0 0-.62h-8.38V3.31A.31.31 0 0 0 12 3z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3a.31.31 0 0 0-.31.31v8.38H3.31a.31.31 0 1 0 0 .62h8.38v8.38a.31.31 0 1 0 .62 0v-8.38h8.38a.31.31 0 0 0 0-.62h-8.38V3.31A.31.31 0 0 0 12 3z"
      fill="#000"
    />
    <path
      d="M11.69 11.69v.4h.4v-.4h-.4zm0 .62h.4v-.4h-.4v.4zm.62 0v-.4h-.4v.4h.4zm0-.62h-.4v.4h.4v-.4zm-.22-8.38c0 .05-.04.09-.09.09v-.8a.71.71 0 0 0-.71.71h.8zm0 8.38V3.31h-.8v8.38h.8zm-8.78.4h8.38v-.8H3.31v.8zM3.4 12c0 .05-.04.09-.09.09v-.8a.71.71 0 0 0-.71.71h.8zm-.09-.09c.05 0 .09.04.09.09h-.8c0 .392.318.71.71.71v-.8zm8.38 0H3.31v.8h8.38v-.8zm.4 8.78v-8.38h-.8v8.38h.8zM12 20.6c.05 0 .09.04.09.09h-.8c0 .392.318.71.71.71v-.8zm-.09.09c0-.05.04-.09.09-.09v.8a.71.71 0 0 0 .71-.71h-.8zm0-8.38v8.38h.8v-8.38h-.8zm8.78-.4h-8.38v.8h8.38v-.8zm-.09.09c0-.05.04-.09.09-.09v.8a.71.71 0 0 0 .71-.71h-.8zm.09.09a.09.09 0 0 1-.09-.09h.8a.71.71 0 0 0-.71-.71v.8zm-8.38 0h8.38v-.8h-8.38v.8zm-.4-8.78v8.38h.8V3.31h-.8zm.09.09a.09.09 0 0 1-.09-.09h.8A.71.71 0 0 0 12 2.6v.8z"
      fill="#000"
      mask="url(#ldqh4dtm5a)"
    />
  </svg>
);

export const PlusIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 8.25V3.75C8.25 3.33579 8.58579 3 9 3C9.41421 3 9.75 3.33579 9.75 3.75V8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H9.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V9.75H3.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25H8.25Z"
      fill="black"
    />
    <mask
      id="mask0_25_1582"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="3"
      y="3"
      width="12"
      height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 8.25V3.75C8.25 3.33579 8.58579 3 9 3C9.41421 3 9.75 3.33579 9.75 3.75V8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H9.75V14.25C9.75 14.6642 9.41421 15 9 15C8.58579 15 8.25 14.6642 8.25 14.25V9.75H3.75C3.33579 9.75 3 9.41421 3 9C3 8.58579 3.33579 8.25 3.75 8.25H8.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_25_1582)">
      <rect width="18" height="18" fill="black" />
    </g>
  </svg>
);
