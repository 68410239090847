import React, { useEffect } from "react";
import styled from "styled-components";
import { FormSelectWithAmountInput } from "../../../../shared/core/data-forms/form-input-types";
import { i18NManager } from "../../../../shared/core/service/services";
import { RadioSelector, RadioSelectorType } from "../forms/radio-selector";
import { SelectInput } from "../forms/select-input";
import { DisableValueText } from "./disable-value-text";

export const SelectWithAmountField: React.FC<{
  input: FormSelectWithAmountInput;
  onChange: (value: string) => void;
  disabled?: boolean;
  type: "radio" | "combobox";
}> = ({ input, onChange, type, disabled }) => {
  useEffect(() => {
    if (!input.value && input.values) {
      onChange?.(input.values?.[0].id);
    } else if (!input.values) {
      console.warn("SelectWithAmountField: input.values is empty", input.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return input.disabled || disabled ? (
    <DisableValueText>{getSelectedOptionLabel(input)}</DisableValueText>
  ) : type === "radio" ? (
    <StyledRadioSelector
      innerId="select_amount"
      values={
        new Map<string, string>(
          input.values.map(({ id, label, amount }) => [id, `${label} ${i18NManager.formatAmount(amount)}`]),
        )
      }
      value={input.value}
      onChange={(value) => onChange && onChange(value || "")}
    />
  ) : (
    // type === "combobox"
    <SelectInput
      style={{ width: "100%" }}
      innerId={input.id}
      key={input.id}
      required={input.required}
      options={input.values}
      value={input.values?.find((v) => v.id === input.value) || null}
      onChange={(value) => onChange && onChange(value.id)}
      itemRenderer={(v) => (v ? `${v.label} ${i18NManager.formatAmount(v.amount)}` : "")}
    />
  );
};

function getSelectedOptionLabel(input: FormSelectWithAmountInput) {
  const option = input.values?.find((v) => v.id === input.value);
  if (option) {
    return `${option.label} ${i18NManager.formatAmount(option.amount)}`;
  }
  return null;
}

const StyledRadioSelector = styled<RadioSelectorType<string | undefined>>(RadioSelector)`
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;
