import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { Client, useClientDisplayName } from "../../../../shared/domains/client/client";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { Circle } from "../shape/circle";
import { LogoutIcon } from "../svg/logout-icon";
import { UserIcon } from "../svg/user-icon";
import { sideBarColors } from "./component/colors";

interface BottomNavProps {
  client?: Client | null;
  onDisconnectClick: () => void;
  onProfileClick: () => void;
  profilePath: string;
}

export function BottomNav(props: BottomNavProps) {
  const { onDisconnectClick, onProfileClick, profilePath, client } = props;
  const { formatMessage } = useIntl();

  const displayName = useClientDisplayName(client, true);

  return (
    <BottomContainer>
      <Separator />
      <BottomWrapper>
        <ProfileContainer>
          {!!client && (
            <>
              <UserButton to={profilePath} onClick={onProfileClick}>
                <UserCircle size={36}>
                  <UserIcon />
                </UserCircle>
              </UserButton>
              <ProfileInfos>
                {displayName && <Name>{displayName}</Name>}
                <ProfileButton to={profilePath} onClick={onProfileClick}>
                  {formatMessage("navigationMenu.settingsLink")}
                </ProfileButton>
              </ProfileInfos>
            </>
          )}
        </ProfileContainer>
        <DisconnectCircle size={35} onClick={onDisconnectClick}>
          <DisconnectButton size={25}>
            <LogoutIcon />
          </DisconnectButton>
        </DisconnectCircle>
      </BottomWrapper>
    </BottomContainer>
  );
}

const BottomContainer = styled.div`
  position: relative;
  bottom: 0;
  background-color: ${sideBarColors.bottomBackground};
  border-radius: 0 0 11px 11px;
  display: flex;
  flex-direction: column;
  flex: 0;
  height: auto;
  align-items: stretch;
`;

const Separator = styled.div`
  margin: 0 16px;
  width: auto;
  height: 1px;
  self-align: center;
  background-color: ${sideBarColors.bottomSeparator};
`;

const BottomWrapper = styled.div`
  background-color: ${sideBarColors.bottomBackground};
  border-radius: 0 0 11px 11px;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: auto;
  padding: 12px;
  align-items: center;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const UserButton = styled(Link)`
  :hover {
    cursor: pointer;
  }
`;

const UserCircle = styled(Circle)`
  background-color: ${sideBarColors.userCircle};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const DisconnectCircle = styled(Circle)`
  border: solid 1px ${sideBarColors.disconnectCircle};
  margin: 10px;
  :hover {
    cursor: pointer;
    border: solid 2px ${sideBarColors.disconnectCircle};
  }
`;

const DisconnectButton = styled(Circle)`
  background-color: ${theme.mainColor};
  :hover {
    opacity: 0.8;
  }
`;

const ProfileInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 8px;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    display: none;
  }
  max-width: 140px;
`;

const Name = styled.span`
  ${theme.mediumText}
  font-size: 0.9375rem;
  color: ${sideBarColors.name};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProfileButton = styled(Link)`
  ${theme.text}
  font-size: 0.8125rem;
  color: ${sideBarColors.profile};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
