import { Observable } from "./observable";

export class ObservableTree<T extends { children?: T[] }, U extends T[]> extends Observable<U> {
  public constructor(val: U) {
    super(val);
  }

  public replace(newValue: T, comparator: (item: T) => boolean) {
    const newTree = replaceInTree<T, U>(this.get(), newValue, comparator);
    this.set(newTree);
  }
}

function replaceInTree<T extends { children?: T[] }, U extends T[]>(
  tree: U,
  newValue: T,
  comparator: (item: T) => boolean,
) {
  const t = [...tree] as U;
  const index = t.findIndex((item) => comparator(item));
  if (index >= 0) {
    t[index] = newValue;
  }
  tree.forEach((item) => {
    const children = item.children;
    if (children && children.length > 0) {
      item.children = replaceInTree(children, newValue, comparator);
    }
  });
  return t;
}
