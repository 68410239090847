export enum CardStatus {
  ALL = "ALL",
  NEW = "NEW",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REMOVED = "REMOVED",
  BLOCKED = "BLOCKED",
  OPPOSED = "OPPOSED",
  CANCELLED = "CANCELLED",
}

export interface CardsFilter {
  status: CardStatus;
}
