import React from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { TransactionCode } from "../../../../../shared/domains/transactions-codes/transaction-code";
import { AmountText } from "../../../common/amount-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { CheckIcon } from "../../../common/svg/check-icon";
import { shadows, theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

export interface TransactionCodeSummaryProps {
  className?: string;
  transactionCode: TransactionCode;
  onDone: () => void;
}

export const TransactionCodeSummary = (props: TransactionCodeSummaryProps) => {
  const { className, transactionCode, onDone } = props;
  const { formatMessage, formatDate } = useIntl();

  return (
    <Container className={className}>
      <SuccessCircle>
        <CheckIcon />
      </SuccessCircle>

      <Card>
        <Title>{formatMessage("transactionCode.summary.title")}</Title>
        <Row>
          <Label>{formatMessage("transactionCode.summary.transactionCode")}</Label>
          <Value>{transactionCode.code}</Value>
        </Row>
        <Row>
          <Label>{formatMessage("transactionCode.summary.transactionPoint")}</Label>
          <Value>{transactionCode.acceptor.name}</Value>
        </Row>
        <Row>
          <Label>{formatMessage("transactionCode.summary.maxAmount")}</Label>
          <AmountText amount={transactionCode.amount} />
        </Row>
        <Row>
          <Label>{formatMessage("transactionCode.summary.expiryDate")}</Label>
          <Value>
            {formatDate(transactionCode.date, {
              day: "numeric",
              month: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </Value>
        </Row>
      </Card>
      <DoneButton size="M" onClick={() => onDone()}>
        {formatMessage("transactionCode.summary.done")}
      </DoneButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
`;

const SuccessCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 146px;
  height: 146px;
  margin: 40px 0 56px 0;
  border-radius: 100px;
  box-shadow: 0 2px 44px 0 rgba(46, 215, 148, 0.87);
  background-color: #2ed794;
`;

const Card = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: column;
  padding: 33px 26px 20px 26px;
  background-color: #ffffff;
  border-radius: 30px;
  ${shadows.medium};
`;

const Title = styled.span`
  ${theme.boldText};
  font-size: 1.375rem;
  color: #000000;
  margin-bottom: 40px;
  align-self: center;
`;

const Row = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const Label = styled.div`
  font-size: 0.9375rem;
  ${theme.mediumText};
  color: #aaaaaa;
  align-self: center;
  text-align: center;
`;

const Value = styled.span`
  ${theme.mediumText}
  font-size: 0.9375rem;
  color: #000000;
  white-space: pre-line;
`;

const DoneButton = styled(PrimaryButton)`
  margin-top: 36px;
  margin-bottom: 16px;
`;
