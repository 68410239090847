export const billCategoryIcon: { [key: string]: string } = {
  BILL_ISSUER_ELECTRICITY: require("../../../../assets/images/bills/electricity.png"),
  Electricity: require("../../../../assets/images/bills/electricity.png"),
  BILL_ISSUER_WATER: require("../../../../assets/images/bills/water.png"),
  Water: require("../../../../assets/images/bills/water.png"),
  BILL_ISSUER_MOBILE: require("../../../../assets/images/bills/phone.png"),
  Mobile: require("../../../../assets/images/bills/phone.png"),
  BILL_ISSUER_DSTV: require("../../../../assets/images/bills/tv.png"),
  DStv: require("../../../../assets/images/bills/tv.png"),
  default: require("../../../../assets/images/bills/stack.png"),
};
