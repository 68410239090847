import { logger } from "../../core/logging/logger";
import { ConnectedApiService } from "../../core/net/connected-api-service";
import { ConnectedServiceDomainApiService } from "../../core/net/connected-service-domain-api-service";
import { Paginated, PaginationOptions } from "../../utils/pagination";
import { Recipient, RecipientStateEnum } from "./recipient";

export type RecipientData = { phone: string } | { iban: string } | { accountReference: string; bic: string };

export class BeneficiaryService {
  public constructor(
    private apiService: ConnectedApiService,
    private serviceDomainApiService: ConnectedServiceDomainApiService,
  ) {}

  public async fetchRecipients(pagination?: PaginationOptions) {
    try {
      const response = await this.apiService.instance.get<Paginated<Recipient>>("/beneficiaries", {
        params: pagination,
      });
      return {
        ...response.data,
        items: response.data.items.filter(
          (item) => item.state === RecipientStateEnum.OPENED || item.state === RecipientStateEnum.PENDING,
        ),
      };
    } catch (e) {
      logger.debug("BeneficiaryService", "Fetch beneficiaries failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async requestNewRecipient(clientId: string | null | undefined, name: string, identity: RecipientData) {
    if (clientId) {
      try {
        const response = await this.serviceDomainApiService.instance.post<Recipient>(
          `/identities/${clientId}/recipients`,
          {
            name,
            ...identity,
            // if phone convert it to number
            ...((identity as { phone: string }).phone && { phone: Number((identity as { phone: string }).phone) }),
          },
        );
        return response.data;
      } catch (e) {
        logger.debug("BeneficiaryService", "Request new recipient failed", e);
        throw e?.response?.data?.message || e.toString();
      }
    } else {
      throw "Client ID is missing";
    }
  }

  public async confirmNewRecipient(
    clientId: string | null | undefined,
    name: string,
    identity: RecipientData,
    otp: string,
  ) {
    if (clientId) {
      try {
        const response = await this.serviceDomainApiService.instance.post<Recipient>(
          `/identities/${clientId}/recipients`,
          {
            name,
            ...identity,
            otp,
          },
        );
        return response.data;
      } catch (e) {
        logger.debug("BeneficiaryService", "Confirm new recipient failed", e);
        throw e?.response?.data?.error?.message || e.toString();
      }
    } else {
      throw "Client ID is missing";
    }
  }

  public async updateRecipient(id: string, name: string) {
    try {
      const response = await this.apiService.instance.patch<Recipient>(`/beneficiaries/${id}`, {
        name,
      });
      return response.data;
    } catch (e) {
      logger.debug("BeneficiaryService", "Update recipient failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async deleteRecipient(id: string) {
    try {
      await this.apiService.instance.delete<void>(`/beneficiaries/${id}`);
    } catch (e) {
      logger.debug("BeneficiaryService", "Delete recipient failed", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }
}
