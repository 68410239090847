import { useIntl } from "../../core/i18n/use-intl";
import { AccountingEntry } from "../accounting-transaction/accounting-entry";

export const useSideEntryLabel = (entry: AccountingEntry, translationKeyPrefix: string, absolute = true) => {
  const { formatMessage, formatAmount } = useIntl();
  return formatMessage(
    entry.type === "FEE"
      ? `${translationKeyPrefix}.fees`
      : entry.type === "COMMISSION"
        ? `${translationKeyPrefix}.commission`
        : entry.type === "DISCOUNT"
          ? `${translationKeyPrefix}.discount`
          : entry.type === "TAX"
            ? `${translationKeyPrefix}.tax`
            : "",
    {
      amount: formatAmount(entry.amount, absolute),
    },
  );
};
