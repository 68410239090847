import React, { SVGProps } from "react";

export function RechargeBankToWalletIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" {...props}>
      <g fill={props.fill} fillRule="nonzero">
        <path d="M11.992 9.418h1.339v3.207h-1.34zM9.456 9.418h1.339v3.207h-1.34zM6.92 9.418h1.339v3.207h-1.34zM4.383 9.418h1.34v3.207h-1.34zM13.777 8.414v-.335h-9.84v.335c0 .185.15.335.335.335h9.17c.185 0 .335-.15.335-.335zM14.236 13.295H3.478a.335.335 0 0 0-.335.334v.893c0 .185.15.335.335.335h10.758c.185 0 .335-.15.335-.335v-.893a.335.335 0 0 0-.335-.334zM3.478 7.41h10.759a.335.335 0 0 0 .175-.62l-5.38-3.312a.335.335 0 0 0-.35 0L3.302 6.79a.335.335 0 0 0 .176.62zM8.63 5.397h.455a.335.335 0 1 1 0 .67H8.63a.335.335 0 1 1 0-.67z" />
      </g>
    </svg>
  );
}
