import { useState } from "react";

export function usePincode<T>(length: number) {
  const [pincode, setPincode] = useState<T[]>([]);
  return {
    pincode,
    enterDigit: (digit: T) => setPincode([...pincode, digit].slice(0, length)),
    resetCode: () => setPincode([]),
  };
}
