import { AxiosRequestConfig } from "axios";
import { AuthenticationManager } from "../../../domains/authentication/authentication-manager";
import { Interceptor } from "./interceptor";

export const isConnectedCheckInterceptor: (
  authenticationManager: AuthenticationManager,
) => Interceptor<AxiosRequestConfig> = (authenticationManager) => ({
  onFulfilled: async (config) => {
    if (!authenticationManager.isConnected.get()) {
      return Promise.reject(new Error("Request cancelled, not connected"));
    }
    return config;
  },
});
