import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Config } from "../../../../shared/core/config/config";
import { logger } from "../../../../shared/core/logging/logger";
import { authenticationManager } from "../../../../shared/core/service/services";

export const ExternalLoansScreen = () => {
  const [uri, setUri] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!uri) {
      authenticationManager
        .getAccessToken()
        .then((accessToken) => {
          const baseUri = Config.EXTERNAL_LOAN_URL;
          let loanUri = baseUri;
          if (baseUri.search("\\?") == -1) {
            loanUri += "?";
          } else {
            loanUri += "&";
          }
          loanUri += "noDecoration=1&";
          loanUri += "jwt=" + accessToken?.value;
          setUri(loanUri);
          logger.debug("URI set:", loanUri);
        })
        .catch((error) => {
          logger.error(error);
        });
    }
  }, []);

  return <>{uri ? <WebFrame src={uri} /> : <Text>{"No uri"}</Text>}</>;
};

const WebFrame = styled.iframe`
  width: 100%;
  height: 860px;
  border: 0;
  border-radius: 10px;
`;

const Text = styled.span``;
