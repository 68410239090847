import { AddDetailedSpending, SpendingsService, UpdateDetailedSpending } from "./spendings-service";

import { ObservableArray } from "../../../utils/observable-array";
import { Spending } from "./spending";
import { logger } from "../../../core/logging/logger";

export class SpendingsManager {
  public spendings = new ObservableArray<Spending>([]);

  public constructor(private spendingsService: SpendingsService) {}

  public clear() {
    this.spendings.set([]);
  }

  private removeSpending(spendingId: string) {
    this.spendings.set(this.spendings.get().filter((spending) => spending.id !== spendingId));
  }

  public async addSpendingDetails(
    transactionId: string,
    detailedSpending: AddDetailedSpending,
    url: string | undefined = undefined,
  ): Promise<Spending> {
    return await this.spendingsService.addDetailedSpending(transactionId, detailedSpending, url);
  }

  public async refresh(transactionId: string, url: string | undefined = undefined) {
    try {
      this.clear();
      const updatedSpendings = await this.spendingsService.getSpendingDetails(transactionId, url);
      this.spendings.set(updatedSpendings);
      return updatedSpendings;
    } catch (e) {
      logger.debug("SpendingsManager", "Refresh spending details failed", e);
      throw e;
    }
  }

  public async updateSpendingDetails(
    transactionId: string,
    spendingId: string,
    detailedSpending: UpdateDetailedSpending,
    url: string | undefined = undefined,
  ) {
    try {
      return await this.spendingsService.updateSpendingDetails(transactionId, spendingId, detailedSpending, url);
    } catch (e) {
      logger.debug("SpendingsManager", "Update spending details failed", e);
      throw e;
    }
  }

  public async deleteSpendingDetails(transactionId: string, spendingId: string, url: string | undefined = undefined) {
    try {
      await this.spendingsService.deleteSpendingDetails(transactionId, spendingId, url);
      this.removeSpending(spendingId);
    } catch (e) {
      logger.debug("SpendingsManager", "Delete spending details failed", e);
      throw e;
    }
  }
}
