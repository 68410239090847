import React, { SVGProps } from "react";

export function BinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 26" width="26" height="26" fill="#000" fillRule="nonzero" {...props}>
      <g>
        <path d="M14 5c1.19 0 2.166.925 2.245 2.096l.005.154V8h3c.38 0 .693.282.743.648L20 8.75c0 .38-.282.693-.648.743l-.102.007h-.75v9.75c0 1.19-.925 2.166-2.096 2.245l-.154.005h-7.5c-1.19 0-2.166-.925-2.245-2.096L6.5 19.25V9.5h-.75c-.38 0-.693-.282-.743-.648L5 8.75c0-.38.282-.693.648-.743L5.75 8h3v-.75c0-1.19.925-2.166 2.096-2.245L11 5h3zm-3 6.75c-.414 0-.75.336-.75.75V17c0 .414.336.75.75.75s.75-.336.75-.75v-4.5c0-.414-.336-.75-.75-.75zm3 0c-.414 0-.75.336-.75.75V17c0 .414.336.75.75.75s.75-.336.75-.75v-4.5c0-.414-.336-.75-.75-.75zm0-5.25h-3c-.38 0-.693.282-.743.648l-.007.102V8h4.5v-.75c0-.38-.282-.693-.648-.743L14 6.5z" />
      </g>
    </svg>
  );
}
