import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/theme";
import { PlusIconSmall } from "../svg/plus-icon";

type ButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
};

export default function AddButton(props: ButtonProps) {
  const { type = "button", onClick, children, disabled } = props;
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      <PlusIconSmall />
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  padding: 25px 80px;
  background-color: ${theme.colors.gray[100]};
  border-radius: 13px;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    background-color: ${theme.colors.gray[200]};
  }
`;
